import React, { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { baseURL } from "../../../network/API";
import Tags from "../../ui/tags";
import { withRouter } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import get from "lodash.get";
import Button from "../../ui/button";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { getCategories, getProductsBySearch } from "../../../network/API";
import MediaWrapper from "../../layout/mediaWrapper";
import { Tooltip } from "antd";

export const ProductCard = withRouter((props) => {
  const [isOwnBooth, setIsOwnBooth] = useState(false);
  const [titleTruncated, setTitleTruncated] = useState(false);

  const getBrandLogo = (img) => {
    if (img) {
      return `${baseURL}/storage/logo/${img}`;
    }
  };

  return (
    <div
      onClick={(e) => {
        if (
          (props.canView || isOwnBooth) &&
          e.target.className !== "tag" &&
          !e.target.className.includes("unfollow")
        ) {
          props.history.push({
            pathname: `/exhibit/${props.exhibitor.id}`,
            previous: props.previous
              ? props.previous
              : {
                  to: "/search" + props.searchQuery,
                  name: "Search",
                  scrollTo: "products",
                },
          });
        }
      }}
      className={`product-card${
        props.canView || isOwnBooth ? " product-clickable" : ""
      }`}
      style={{ textDecoration: "none", color: "black" }}
    >
      <div
        className="product-background"
        style={{
          backgroundImage: `url(${props.background})`,
        }}
      >
        <div className="product__brand-container">
          {get(props?.exhibitor, "logo.uri", "") !== "" && (
            <div className="brand-container__logo">
              <MediaWrapper
                square
                contain
                src={getBrandLogo(get(props.exhibitor, "logo.uri", ""))}
                onLoad={props.onLoad}
              />
            </div>
          )}
          <div className="brand-container__text">{props.exhibitor?.name}</div>
        </div>
      </div>
      <div className="product-upper">
        <div className="product-upper-info">
          <Tooltip title={titleTruncated ? props?.name : null}>
            <h3 className="product-upper-info__title">
              <TruncateMarkup
                lines={1}
                onTruncate={(e) => {
                  if (e) {
                    setTitleTruncated(e);
                  }
                }}
              >
                <div>
                  {titleTruncated}
                  {props.name}
                </div>
              </TruncateMarkup>
            </h3>
          </Tooltip>
          {get(props, "tags", []).length > 0 && (
            <div className="product-tag-list">
              <Tags tags={get(props, "tags", [])} />
            </div>
          )}
        </div>
      </div>
      <div className="product-lower">
        <TruncateMarkup lines={3}>
          <p className="product-text">{props.description}</p>
        </TruncateMarkup>
        <div className="product-lower__country">
          {props?.exhibitor?.countries &&
            props?.exhibitor?.countries.length > 0 && (
              <p className="products__available-in">
                Available in:{" "}
                <TruncateMarkup lines={2}>
                  <span className="products__country">
                    {props.exhibitor.countries
                      .map((c) => c.name)
                      .filter((c) => c !== undefined)
                      .join(", ")}
                  </span>
                </TruncateMarkup>
              </p>
            )}
        </div>
      </div>
    </div>
  );
});

const ProductList = React.memo(
  ({
    q,
    tags,
    category,
    subcategory,
    canView,
    redirectTo,
    onLoad,
    noButton,
    previous,
    text,
  }) => {
    const categories = useQuery(["getCategories"], getCategories, {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

    const products = useInfiniteQuery(
      ["getProductsBySearch", { q, tags, category, subcategory }],
      getProductsBySearch,
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) =>
          lastPage.length < 6 ? false : pages.length + 1,
        onSettled: () => (onLoad ? onLoad() : null),
      }
    );

    const getProductBackground = (img) => {
      if (img) {
        return `${baseURL}/storage/products/${img}`;
      }
      return "/assets/images/bg-default-book.png";
    };

    const getSearchQuery = `?${tags
      ?.map((tag) => `tags=${encodeURI(tag)}`)
      .join("&")}${category ? `&category=${category}` : ""}${
      subcategory ? `&subcategory=${subcategory}` : ""
    }${q ? `&q=${q}` : ""}`;

    // Auto load next page
    useEffect(() => {
      const handleScroll = () => {
        // Get visiable window height
        var domHight =
          window.screen.height ||
          window.innerHeight ||
          document.documentElement.clientHeight;
        // console.log('domHight', domHight);
        // Get dom scroll position
        var scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        // Get footer
        var footer;
        // Get footer height
        var scrollHeight;
        // Get footer offsetTop
        var offsetTop;
        // Get footer offsetTop - window height
        var top;
        // Get footer offsetBottom + footer height
        var bottom;

        footer = document.getElementById(`footer`);
        if (footer) {
          scrollHeight = footer.scrollHeight;
          offsetTop = footer.offsetTop;
          top = offsetTop - domHight > 0 ? offsetTop - domHight : 0;
          bottom = scrollHeight + offsetTop;
          if (scrollTop >= top && scrollTop <= bottom) {
            if (
              products.hasNextPage &&
              !products.isFetching &&
              !products.isFetchingNextPage
            ) {
              // console.log('fetch next page')
              products.fetchNextPage();
            }
            // console.log('footer appears !!!!!');
          } else {
            // console.log('footer not appears');
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, [products]);

    return (
      <div>
        <div className="product-list">
          {products.data?.pages?.flat()?.length === 0 && <p>{text}</p>}
          {products.data?.pages?.flat().map((e, i) => (
            <ProductCard
              key={e._id}
              name={e.name}
              searchQuery={getSearchQuery}
              description={e.description}
              exhibitor={e.exhibitor}
              tags={e.tags}
              countries={e.countriesAvailable}
              previous={previous}
              background={getProductBackground(
                e.product?.uri,
                categories.data,
                e.category,
                e.subcategory
              )}
              canView={canView}
              id={e.id || e._id}
              onLoad={() => {}}
            />
          ))}
        </div>
        {!noButton && (
          <Row className="justify-content-center">
            <Col xs="auto">
              {products.hasNextPage && !redirectTo ? (
                <Button
                  isDisabled={
                    products.isFetching || products.isFetchingNextPage
                  }
                  onClick={() => products.fetchNextPage()}
                  size="min"
                >
                  {products.isFetchingNextPage ? "Loading..." : "Load more"}
                </Button>
              ) : null}
              {redirectTo && (
                <Button onClick={() => redirectTo()} size="min">
                  {"Discover more"}
                </Button>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
);

export default withRouter(ProductList);
