import React from 'react'

const Reminder = (props) => {
	return (
		props.open ?
			<div
				className="reminder"
				dangerouslySetInnerHTML={{ __html: `Your subscription will expire in one month. Please contact <a href="mailto:perry.chung@hyatt.com">Hyatt-Asia-Pacific</a> to renew it.`}}>
				{props.text}
			</div> : 
			null
	)
}

export default Reminder