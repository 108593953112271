import React from "react";
import cn from "classnames";
import "./textarea.scss";

const Textarea = ({
  disabled,
  color,
  error,
  errorMessage,
  fullwidth,
  icon,
  flat,
  label,
  password,
  placeholder,
  size,
  value,
  ...otherProps
}) => {
  return (
    <div
      className={cn("input-item input-item--textarea", {
        "input-icon-left": !!icon,
      })}
    >
      {label ? <label className="label">{label}</label> : ""}
      {icon ? <div className={`icon-${icon}`} /> : null}
      <textarea
        className={cn(
          "input",
          {
            error: error,
            disabled: disabled,
            flat: flat,
            fullwidth: fullwidth,
          },
          { [`input-${color}`]: !!color },
          { [`input-${size}`]: !!size }
        )}
        placeholder={placeholder}
        disabled={disabled}
        {...otherProps}
        value={value}
      />
      {error ? <div className="input-error-message">{errorMessage}</div> : null}
    </div>
  );
};

export default Textarea;
