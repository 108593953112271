import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { withRouter } from "react-router-dom";
import { getNthItemFromPathname } from "../../../utils";
import Button from "../../ui/button";
import Title from "./Title";
import Contact from "./Contact";
import Downloads from "./Downloads";
import History from "./History";
import Lifestyle from "./Lifestyle";
import Files from "./Files";
import Wrapper from "../wrapper";
import { useAuthContext } from "../../../store/auth";
import get from "lodash.get";
import { ROLES } from "../../../constants/roles";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../../layout/loader";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useModal } from "../../../store/hooks/useModal";
import Modal from "../../ui/popup";
import tracking from "../../../utils/tracking";
import {
  baseURL,
  getBoothDetail,
  getCategories,
  followBooth,
  saveBooth,
  getSimilars,
  likeLifestyle,
  unlikeLifestyle,
  sendRequestMeeting,
} from "../../../network/API";
import { PageView } from "../../../network/Stats";
import LifestyleCardSlider from "../../ui/lifestyleCardSlider";
import LifestyleCard from "../../ui/lifestyleCardSlider/lifestyleCard";
import SimilarBrandCard from "./SimilarBrandCard";
import ExhibitorNav from "./exhibitorNav";
import Input from "../../ui/input";
import Textarea from "../../ui/textarea";
import { Formik, Form } from "formik";

const ViewExhibit = (props) => {
  const pathname = props.history.location.pathname;
  const previous = props.history.location.previous;
  const [isShowing, toggleModal] = useModal();
  const queryClient = useQueryClient();
  const { role } = useAuthContext();
  const id = getNthItemFromPathname(pathname, 2);
  const [editMode, setEditMode] = useState(false);
  const [editBooth, setEditBooth] = useState({});
  const [errors, setErrors] = useState();
  const [initMediaLink, setInitMediaLink] = useState();
  const productRef = useRef(null);
  const lifestyleRef = useRef(null);
  const catalogueRef = useRef(null);
  const [navFilter, setNavFilter] = useState('lifestyles');
  const headerHight = 146; //px
  const offset = 20;

  const [messageIsSent, setMessageIsSent] = useState(false);
  const [isRequestMeetingShowing, toggleRequestMeetingModal] = useModal();

  const closeRequestMeetingModal = () => {
    toggleRequestMeetingModal();
    setMessageIsSent(false);
  };

  const onSendMessage = useMutation(
    (values) => sendRequestMeeting(values, id),
    {
      onSuccess: () => setMessageIsSent(true),
    }
  );

  const similars = useQuery(["getSimilars", { id }], getSimilars, {
    refetchOnWindowFocus: false,
    enabled: role !== ROLES.exhibitor,
  });
  const categories = useQuery(["getCategories"], getCategories, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const booth = useQuery(
    [
      "getBoothDetail",
      { id, page: role !== ROLES.exhibitor ? "front" : "mine" },
    ],
    getBoothDetail,
    {
      onSuccess: (data) => {
        // if (!editMode) onBoothEdited(data);

        if (new Date() > new Date(data.subscription_end))
          props.history.push("/");
      },
      enabled: !editMode,
    }
  );
  const onFollowBooth = useMutation((value) => followBooth(value, id), {
    onMutate: (data, variables) =>
      queryClient.setQueryData(
        [
          "getBoothDetail",
          { id, page: role !== ROLES.exhibitor ? "front" : "mine" },
        ],
        (oldData) => ({
          ...oldData,
          isFollow: variables,
        })
      ),
  });
  const onLikeLifestyle = useMutation(
    (values) => likeLifestyle(values.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getBoothDetail",
          { id, page: role !== ROLES.exhibitor ? "front" : "mine" },
        ]);
      }
    }
  );

  const onUnlikeLifestyle = useMutation(
    (values) => unlikeLifestyle(values.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getBoothDetail",
          { id, page: role !== ROLES.exhibitor ? "front" : "mine" },
        ]);
      }
    }
  );
  const onSaveBooth = useMutation(
    (values) => saveBooth(cleanDataBeforeSending(values.data), values.id),
    {
      onSuccess: (data, variable) =>
        queryClient.setQueryData(
          [
            "getBoothDetail",
            { id, page: role !== ROLES.exhibitor ? "front" : "mine" },
          ],
          (oldData) => variable.old
        ),
      onSettled: () => setEditMode(false),
    }
  );

  useEffect(() => {
    tracking.pageView(`/${id}`);
    if (role !== ROLES.exhibitor) PageView(id);
  }, []);

  useEffect(() => {
    const mediaLink = previous?.mediaLink;
    if(mediaLink){
      setInitMediaLink(mediaLink)
    }
    return () => setInitMediaLink(undefined);
  },[previous])

  useLayoutEffect(() => {
    if (previous?.scrollTo === 'products') {
      onNavFilterChange('products', offset);
    } else if (previous?.scrollTo === 'lifestyles') {
      onNavFilterChange('lifestyles', offset);
    }
  }, [lifestyleRef, productRef, previous]);

  useEffect(() => {
    function changeNavFilter(){
      const productRectTop = productRef?.current?.getBoundingClientRect()?.top;
      const lifestyleRectTop = lifestyleRef?.current?.getBoundingClientRect()?.top;
      const cataRectTop = catalogueRef?.current?.getBoundingClientRect()?.top;
      if(productRectTop > 100 && productRectTop < 200){
        setNavFilter('products')
      }else if(lifestyleRectTop > 100 && lifestyleRectTop < 200){
        setNavFilter('lifestyles')
      }else if(cataRectTop > 100 && cataRectTop < 200){
        setNavFilter('catalogue')
      }
    }
    window.addEventListener("scroll", changeNavFilter)

    return () => window.removeEventListener("scroll", changeNavFilter)
  },[lifestyleRef, productRef, catalogueRef])

  const onNavFilterChange = (filter, offset = 0) => {
    if (filter === 'products') {
      const timer = setTimeout(() => {
        if (productRef.current) {
          const top = productRef.current.offsetTop;
          window.scrollTo(0, top - headerHight - offset);
        }
      }, 100);
      setNavFilter(filter);
      return () => clearTimeout(timer);
    } else if (filter === 'lifestyles') {
      const timer = setTimeout(() => {
        if (lifestyleRef.current) {
          const top = lifestyleRef.current.offsetTop;
          window.scrollTo(0, top - headerHight - offset);
        }
      }, 100);
      setNavFilter(filter);
      return () => clearTimeout(timer);
    } else if (filter === 'catalogue') {
      const timer = setTimeout(() => {
        if (catalogueRef.current) {
          const top = catalogueRef.current.offsetTop;
          window.scrollTo(0, top - headerHight - offset);
        }
      }, 100);
      setNavFilter(filter);
      return () => clearTimeout(timer);
    }
  };

  const cleanDataBeforeSending = (oldData) => {
    const tags = get(oldData, "tags", []);
    if (tags) {
      oldData.tags = tags.filter((tag) => tag !== null).map((tag) => tag.id);
    }
    oldData.category = get(oldData, "category.id", null);
    oldData.subcategory = get(oldData, "subcategory.id", null);
    if (oldData.products) {
      oldData.products.map( (product) => {
        const product_tags = get(product, "tags", []);
        if (product_tags) {
          product.tags = product_tags.filter((tag) => tag !== null).map((tag) => tag.id);
        }
        return product;
      })
    }
    return oldData;
  };

  const getCategoryBackground = (img, categories, category, subcategory) => {
    if (img) {
      return `${baseURL}/storage/background/${img}`;
    }

    if (categories) {
      for (const cat of categories) {
        if (category === cat.id) {
          if (subcategory && cat) {
            for (const sub of cat.subcategories) {
              if (sub.id === subcategory) {
                return sub.background?.url;
              }
            }
          }
          return `/assets/images/bg420-${get(cat, "slug", "")}.png`;
        }
      }
    }
    return "/assets/images/bg-default-book.png";
  };

  const onBoothEdited = (value) => {
    let values = { ...editBooth, ...value };

    // Fill category and subcategory for seach
    if (values.products && values.products.length) {
      let catid = '';
      let subcatid = '';
      if (values.category || values.subcategory) {
        catid = values?.category?.id;
        subcatid = values?.subcategory?.id;
      }
      if (values?.products) {
        values.products.map( product => {
          product.category = catid;
          product.subcategory = subcatid;
          return product;
        })
      }
    }

    setEditBooth(values);
    let error = {};

    if (!values.name) {
      error.name = "Name is required";
    }

    if (!values.description) {
      error.description = "Description is required";
    } else if (values.description?.length > 85) {
      error.description = "Description is too long";
    }

    if (!values.logo) {
      error.logo = "Logo is required";
    }
    if (!values.category) {
      error.category = "Category is required";
    }

    if (
      values.website_url?.length &&
      !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        values.website_url
      )
    ) {
      error.website_url = "Invalid website url. ex: https://...";
    }

    if (
      values.instagram_link?.length &&
      !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        values.instagram_link
      )
    ) {
      error.instagram_link = "Invalid website url. ex: https://...";
    }

    if (
      values.linkedin_link &&
      !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        values.linkedin_link
      )
    ) {
      error.linkedin_link = "Invalid website url";
    }

    if (
      values.facebook_link?.length &&
      !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        values.facebook_link
      )
    ) {
      error.facebook_link = "Invalid website url. ex: https://...";
    }

    if (
      values.tiktok_link?.length &&
      !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        values.tiktok_link
      )
    ) {
      error.tiktok_link = "Invalid website url. ex: https://...";
    }

    // if (
    //   values.kakao_link?.length &&
    //   !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
    //     values.kakao_link
    //   )
    // ) {
    //   error.kakao_link = "Invalid website url. ex: https://...";
    // }

    if (
      values.youtube_link?.length &&
      !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        values.youtube_link
      )
    ) {
      error.youtube_link = "Invalid website url. ex: https://...";
    }

    if (
      values.red_link?.length &&
      !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        values.red_link
      )
    ) {
      error.red_link = "Invalid website url. ex: https://...";
    }

    if (
      values.weibo_link?.length &&
      !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        values.weibo_link
      )
    ) {
      error.weibo_link = "Invalid website url. ex: https://...";
    }

    // if (
    //   values.wechat_link?.length &&
    //   !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
    //     values.wechat_link
    //   )
    // ) {
    //   error.wechat_link = "Invalid website url";
    // }

    if (!values.products || !values.products.length) {
      error.products_nb = "At least one picture is required";
    }

    let isMissingTitle = false;
    let missingTitles = [];
    for (const prod of values?.products) {
      if (!prod.name) {
        isMissingTitle = true;
        missingTitles.push("Title is required");
      } else {
        missingTitles.push(false);
      }
    }
    if (isMissingTitle) {
      error.products = missingTitles;
    }

    let isMissingVideosTitle = false;
    let missingVideoTitles = [];
    for (const prod of values?.videos) {
      if (!prod.name) {
        isMissingVideosTitle = true;
        missingVideoTitles.push("Video title is required");
      } else if (!prod?.video?.uri) {
        isMissingVideosTitle = true;
        missingVideoTitles.push("Video is required");
      } else {
        missingVideoTitles.push(false);
      }
    }
    if (isMissingVideosTitle) {
      error.videos = missingVideoTitles;
    }

    setErrors(error);
  };

  return (
    <>
      <Loader show={booth.isLoading} />
      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        showCloseButton={true}
        width={420}
      >
        <h2 className="h2 title text-center">Save your page</h2>
        <p className="modal-subtitle text-center">
          When you save, your page will be created. A notification will be sent
          to all Hyatt associates.
        </p>
        <div className="modal-buttons">
          <Button
            onClick={() => {
              toggleModal();
            }}
            theme="secondary-light"
          >
            Go back to edit
          </Button>
          <Button
            onClick={() => {
              onSaveBooth.mutate({ data: editBooth, id, old: editBooth });
              toggleModal();
            }}
            theme="primary"
          >
            {onSaveBooth.isLoading ? "Loading..." : "Save modifications"}
          </Button>
        </div>
      </Modal>
      <Modal
          isShowing={isRequestMeetingShowing}
          onClose={closeRequestMeetingModal}
          showCloseButton={true}
          >
          {!messageIsSent ? (
              <>
              <h2 className="h2 title text-center">Request a meeting</h2>
              <Formik
                  initialValues={{
                  hotel: "",
                  name: "",
                  position: "",
                  email: "",
                  message: "",
                  }}
                  validate={(values) => {
                  const errors = {};
                  if (!values.hotel) {
                      errors.hotel = "Required";
                  }
                  if (!values.name) {
                      errors.name = "Required";
                  }
                  if (!values.position) {
                      errors.position = "Required";
                  }
                  if (!values.email) {
                      errors.email = "Required";
                  } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                      )
                  ) {
                      errors.email = "Invalid email address";
                  }
                  if (!values.message) {
                      errors.message = "Required";
                  }
                  return errors;
                  }}
                  onSubmit={() => {}}
              >
                  {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                  }) => (
                  <Form>
                      <Input
                      label="Hotel"
                      placeholder="Hotel"
                      fullwidth
                      //
                      name="hotel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.hotel}
                      error={errors.hotel && touched.hotel}
                      errorMessage={errors.hotel}
                      />
                      <Input
                      label="Your Name"
                      placeholder="Your Name"
                      fullwidth
                      //
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={errors.name && touched.name}
                      errorMessage={errors.name}
                      />
                      <Input
                      label="Position"
                      placeholder="Position"
                      fullwidth
                      //
                      name="position"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.position}
                      error={errors.position && touched.position}
                      errorMessage={errors.position}
                      />
                      <Input
                      label="Your email"
                      placeholder="Enter your email"
                      type="email"
                      fullwidth
                      //
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email}
                      errorMessage={errors.email}
                      />
                      <Textarea
                      label="Message for the company"
                      placeholder="Type a message"
                      rows="5"
                      fullwidth
                      //
                      name="message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      error={errors.message && touched.message}
                      errorMessage={errors.message}
                      />
                      <div className="text-center">
                      <Button
                          onClick={() => {
                          onSendMessage.mutate(values);
                          }}
                          theme="primary"
                          isDisabled={
                          onSendMessage.isLoading ||
                          errors.hotel ||
                          !touched.hotel ||
                          errors.name ||
                          !touched.name ||
                          errors.position ||
                          !touched.position ||
                          errors.email ||
                          !touched.email ||
                          errors.message ||
                          !touched.message
                          }
                      >
                          {onSendMessage.isLoading
                          ? "Loading..."
                          : "Send request"}
                      </Button>
                      </div>
                  </Form>
                  )}
              </Formik>
              </>
          ) : (
              <>
              <h2 className="h2 title text-center">
                  Your message has been sent
              </h2>
              <div className="text-center">
                  <Button theme="primary" onClick={closeRequestMeetingModal}>
                  Back to brand page
                  </Button>
              </div>
              </>
          )}
      </Modal>
      <Wrapper>
        {!editMode && (
          <ExhibitorNav
            previous={previous}
            onNavFilterChange={(filter) => {onNavFilterChange(filter)}}
            navFilter={navFilter}
            boothId={id}
            onClickEdit={() => {
              setEditBooth(booth.data);
              setEditMode(true);
            }}
            onClickCancel={() => {
              setEditBooth(booth.data);
              setEditMode(false);
            }}
            isFollow={booth.data?.following}
            onClickFollow={(e) => {
              onFollowBooth.mutate(e);
              queryClient.setQueryData(
                [
                  "getBoothDetail",
                  { id, page: role !== ROLES.exhibitor ? "front" : "mine" },
                ],
                (oldData) => ({
                  ...oldData,
                  following: e,
                })
              );
            }}
            onClickSave={() => {
              if (!editBooth.published) {
                toggleModal();
              } else {
                onSaveBooth.mutate({ data: editBooth, id, old: editBooth });
              }
            }}
            onClickRequestMeeting={() => {toggleRequestMeetingModal()}}
          />
        )}
        <Title
          hideTag={editMode ? true : false}
          editMode={editMode}
          boothId={id}
          errors={errors}
          onBoothEdited={onBoothEdited}
          title={get(editMode ? editBooth : booth.data, "name", "")}
          category={get(editMode ? editBooth : booth.data, "category", "")}
          previous={previous}
          subcategory={get(
            editMode ? editBooth : booth.data,
            "subcategory",
            ""
          )}
          countries={get(booth.data, "countries", [])}
          background={get(
            editMode ? editBooth : booth.data,
            "background.uri",
            false
          )}
          logo={get(editMode ? editBooth : booth.data, "logo.uri", null)}
          website={get(editMode ? editBooth : booth.data, "website_url", "")}
          tags={get(editMode ? editBooth : booth.data, "tags", [])}
          partner={booth.data?.exhibitor}
          description={get(
            editMode ? editBooth : booth.data,
            "description",
            ""
          )}
          onClickEdit={() => {
            setEditBooth(booth.data);
            setEditMode(true);
          }}
          onClickCancel={() => {
            setEditBooth(booth.data);
            setEditMode(false);
          }}
          isFollow={booth.data?.following}
          onClickFollow={(e) => {
            onFollowBooth.mutate(e);
            queryClient.setQueryData(
              [
                "getBoothDetail",
                { id, page: role !== ROLES.exhibitor ? "front" : "mine" },
              ],
              (oldData) => ({
                ...oldData,
                following: e,
              })
            );
          }}
          onClickSave={() => {
            if (!editBooth.published) {
              toggleModal();
            } else {
              onSaveBooth.mutate({ data: editBooth, id, old: editBooth });
            }
          }}
          onClickRequestMeeting={() => {toggleRequestMeetingModal()}}
        />

        {!editMode &&
        !booth.data?.history_media &&
        !booth.data?.history ? null : (
          <section className="section about-section">
            <Container>
              <Row>
                <Col xl={{ span: 10, offset: 1 }}>
                  <History
                    id={id}
                    editMode={editMode}
                    text={get(editMode ? editBooth : booth.data, "history", "")}
                    media={get(
                      editMode ? editBooth : booth.data,
                      "history_media",
                      null
                    )}
                    onBoothEdited={onBoothEdited}
                  />
                </Col>
              </Row>
            </Container>
          </section>
        )}
        <Container>
          <Row>
            <Col xl={{ span: 10, offset: 1 }}>
              <div className="divider" />
            </Col>
          </Row>
        </Container>
        {!editMode &&
        (
          <Container>
          <Row>
            <Col xl={{ span: 10, offset: 1 }}>
              <div className="navigate-section">
                <ul className="filters">
                  <li>
                    <button
                      className={`filters__button nav-title ${navFilter === 'lifestyles' ? " active" : ""}`}
                      onClick={() => onNavFilterChange('lifestyles')}
                    >
                      NEWS feed
                    </button>
                  </li>
                  <li>
                    <button
                      className={`filters__button nav-title ${navFilter === 'products' ? " active" : ""}`}
                      onClick={() => onNavFilterChange('products')}
                    >
                      Products
                    </button>
                  </li>
                  <li>
                    <button
                      className={`filters__button nav-title ${navFilter === 'catalogue' ? " active" : ""}`}
                      onClick={() => onNavFilterChange('catalogue')}
                    >
                      Catalogue
                    </button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        )}
        
          <section className="section" ref={lifestyleRef}>
            <Container>
              <Row>
                <Col xxl={{ span: 10, offset: 1 }}>
                {editMode ? (
                  <Lifestyle
                    editMode={editMode}
                    lifestyles={get(
                      editMode ? editBooth : booth.data,
                      "lifestyles",
                      []
                    )}
                    onBoothEdited={onBoothEdited}
                  />) : 
                    <>
                      <h2 className="h2 title lifestyle__title">Check out our moments</h2>
                      {(!booth.data?.lifestyles || !booth.data?.lifestyles?.length) ? null : (
                        <LifestyleCardSlider
                          slidesData={
                            booth.data?.lifestyles
                              ? booth?.data?.lifestyles?.map((ex) => {
                                  return {
                                    name: ex?.name,
                                    uri: ex?.lifestyle?.uri,
                                    id: ex.id,
                                    description: ex?.description,
                                    uploadedAt: ex?.uploadedAt,
                                    likeCount: ex?.likeCount || 0,
                                    isLiked: ex?.likedByUser ? ex?.likedByUser : false,
                                    isVideo: ex?.lifestyle?.type.includes('video')
                                  };
                                })
                              : []
                          }
                          initMediaLink={initMediaLink}
                          slidesNumberDesktop={3}
                          SlideComponent={LifestyleCard}
                          noModalSlide={true}
                          onClickLike={(id) => onLikeLifestyle.mutate({id: id})}
                          onClickUnlike={(id) => onUnlikeLifestyle.mutate({id: id})}
                      />)}
                    </>
                }

                </Col>
              </Row>
            </Container>
          </section>
        {!editMode &&
        (!booth.data?.products || !booth.data?.products?.length) ? null : (
          <section id="products" className={`section product-section`} ref={productRef}>
            <Container>
              <Row>
                <Col xxl={{ span: 10, offset: 1 }}>
                  <div className="exhibit-downloads">
                    <Downloads
                      id={get(editMode ? editBooth : booth.data, "id", "")}
                      errors={errors}
                      type={booth.data?.type}
                      countries={get(booth.data, "countries", [])}
                      category={get(editMode ? editBooth : booth.data, "category", "")}
                      subcategory={get(
                        editMode ? editBooth : booth.data,
                        "subcategory",
                        ""
                      )}
                      products={get(
                        editMode ? editBooth : booth.data,
                        "products",
                        []
                      )}
                      imagesVideos={get(
                        editMode ? editBooth : booth.data,
                        "images_videos",
                        []
                      )}
                      thumbnails={get(
                        editMode ? editBooth : booth.data,
                        "thumbnails",
                        []
                      )}
                      videoThumbmnailLinks={get(
                        booth,
                        "video_thumbmnail_links",
                        []
                      )}
                      // downloadCatalog={
                      //   role !== ROLES.exhibitor ? downloadCatalog : () => {}
                      // }
                      spreadImages
                      editMode={editMode}
                      onBoothEdited={onBoothEdited}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {/* Deprecated videos section */}
        {/* {!editMode &&
        (!booth.data?.videos || !booth.data?.videos?.length) ? null : (
          <section className="section">
            <Container>
              <div className="exhibit-videos">
                <Videos
                  id={id}
                  editMode={editMode}
                  errors={errors}
                  videos={get(editMode ? editBooth : booth.data, "videos", [])}
                  onBoothEdited={onBoothEdited}
                  default={props.history.location?.defaultVideo}
                />
              </div>
            </Container>
          </section>
        )} */}
        <section className="section background-muted" ref={catalogueRef}>
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
               
                <Files
                  id={get(editMode ? editBooth : booth.data, "id", "")}
                  catalogs={get(
                    editMode ? editBooth : booth.data,
                    "catalogs",
                    []
                  )}
                  onBoothEdited={onBoothEdited}
                  editMode={editMode}
                />
                <Contact
                  id={get(editMode ? editBooth : booth.data, "id", "")}
                  errors={errors}
                  instagram={get(
                    editMode ? editBooth : booth.data,
                    "instagram_link",
                    ""
                  )}
                  linkedin={get(
                    editMode ? editBooth : booth.data,
                    "linkedin_link",
                    ""
                  )}
                  facebook={get(
                    editMode ? editBooth : booth.data,
                    "facebook_link",
                    ""
                  )}
                  tiktok={get(
                    editMode ? editBooth : booth.data,
                    "tiktok_link",
                    ""
                  )}
                  // kakao={get(
                  //   editMode ? editBooth : booth.data,
                  //   "kakao_link",
                  //   ""
                  // )}
                  kakaoQrCode={get(
                    editMode ? editBooth : booth.data,
                    "kakao_qr_code",
                    ""
                  )}
                  youtube={get(
                    editMode ? editBooth : booth.data,
                    "youtube_link",
                    ""
                  )}
                  red={get(
                    editMode ? editBooth : booth.data,
                    "red_link",
                    ""
                  )}
                  weibo={get(
                    editMode ? editBooth : booth.data,
                    "weibo_link",
                    ""
                  )}
                  // wechat={get(
                  //   editMode ? editBooth : booth.data,
                  //   "wechat",
                  //   ""
                  // )}
                  wechatQrCode={get(
                    editMode ? editBooth : booth.data,
                    "wechat_qr_code",
                    ""
                  )}
                  contact={get(
                    editMode ? editBooth : booth.data,
                    "contact",
                    {}
                  )}
                  contactText={get(
                    editMode ? editBooth : booth.data,
                    "contact_text",
                    ""
                  )}
                  websiteUrl={get(
                    editMode ? editBooth : booth.data,
                    "website_url",
                    ""
                  )}
                  onBoothEdited={onBoothEdited}
                  // downloadContact={
                  //   role !== ROLES.exhibitor ? downloadContact : () => {}
                  // }
                  editMode={editMode}
                />
              </Col>
            </Row>
          </Container>
        </section>
        {role !== ROLES.exhibitor && similars?.data ? (
          <section className="section-medium background-default">
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <div className="text-center">
                    <h2 className="h2 title">Similar brands</h2>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <div>
                    <div className="exhibitor-list">
                      {similars?.data.map((e, index) =>
                        index < 2 ? (
                          <SimilarBrandCard
                            key={e._id}
                            name={e.name}
                            logo={e.logo}
                            description={e.description}
                            categories={categories.data}
                            category={e.category}
                            subcategory={e.subcategory}
                            following={e.following}
                            tags={e.tags}
                            countries={e.countries}
                            previous={{
                              to: "/exhibit/" + id,
                              name: booth.data?.name || "Previous",
                            }}
                            background={getCategoryBackground(
                              e.background?.uri,
                              categories.data,
                              e.category,
                              e.subcategory
                            )}
                            canView={true}
                            id={e.id || e._id}
                            // onLoad={incrementLoaded}
                            onLoad={() => {}}
                          />
                        ) : null
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        ) : null}
      </Wrapper>
    </>
  );
};

export default withRouter(ViewExhibit);
