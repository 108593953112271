import React from "react";

export const SmallCenteredDivider = () => (
  <div className="small-line-centered" />
);

export const CenteredDivider = () => <div className="line-centered" />;

export const FullWidthDivider = () => (
  <div className="centered-section">
    <div className="line-full-width" />
  </div>
);

export const Ellipses = '<div className="ellipses">...</div>';
