import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Subheader from "../exhibit/Subheader";
import Title from "../exhibit/Title";
import SliderComponent from "../../ui/slider";
import { FullWidthDivider } from "../../layout/divider";
import Downloads from "../exhibit/Downloads";
import Contact from "../exhibit/Contact";
import Wrapper from "../wrapper";
import { useDataContext } from "../../../store/data";
// import { useAuthContext } from '../../../store/auth'
import get from "lodash.get";
import EditControls from "../edit/editControls";
import { getNthItemFromPathname } from "../../../utils";
// import moment from 'moment'
// import Modal from '../../ui/modal'

const Preview = (props) => {
  const pathname = props.history.location.pathname;
  const id = getNthItemFromPathname(pathname, 2);

  const {
    previewData,
    uploadedFiles,
    stashBoothData,
    uploadFiles,
    updateMyBooth,
    myBooth,
    setLoading,
    setPreviewData,
    setUsePreviewData,
  } = useDataContext();
  if (
    previewData &&
    !previewData.category &&
    !previewData.contact &&
    !previewData.description &&
    !previewData.facebook_link &&
    !previewData.instagram_link &&
    !previewData.linkedin_link &&
    !previewData.title &&
    !previewData.website &&
    !previewData.wechat_qr_code
    // previewData.carousel &&
    // previewData.carousel.length === 0 &&
    // previewData.catalogs &&
    // previewData.catalogs.length === 0 &&
    // previewData.countries &&
    // previewData.countries.length === 0
  ) {
    props.history.goBack();
  }
  const convertToData = (f) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = (file) => {
        resolve(file.target.result);
      };

      fileReader.onerror = (error) => reject(error);

      fileReader.readAsDataURL(f);
    });
  };

  const displayedData = {
    category: get(previewData, "category", {}),
    subcategory: get(previewData, "subcategory", {}),
    tags: get(previewData, "tags", []),
    catgoryLogo: `/assets/images/${get(
      previewData,
      "category.slug",
      ""
    )}-132.png`,
    title: get(previewData, "title", ""),
    logo: get(previewData, "logo", ""),
    website: get(previewData, "website", ""),
    carousel: get(previewData, "carousel", []),
    countries: get(previewData, "countries", []),
    instagram_link: get(previewData, "instagram_link", ""),
    linkedin_link: get(previewData, "linkedin_link", ""),
    facebook_link: get(previewData, "facebook_link", ""),
    wechat: get(previewData, "wechat_qr_code", ""),
    catalogs: get(previewData, "catalogs", []),
    images_videos: get(previewData, "images_videos", []),
    thumbnails: get(previewData, "thumbnails", []),
    video_thumbmnail_links: get(previewData, "video_thumbmnail_links", []),
    description: get(previewData, "description", ""),
    contact: get(previewData, "contact", ""),
  };
  let displayCopy = displayedData;
  const [display, setDisplay] = useState(displayedData);
  // loop through an array and replace File object with blob data
  const blobArray = async (field) => {
    const promises = [];
    displayCopy[field].forEach((item, index) => {
      if (item.path) {
        promises.push(
          convertToData(item).then((res) => ({
            url: res,
            title: item.name,
            type: item.type.split("/")[0],
          }))
        );
      } else {
        const p = new Promise((res) => res(item));
        promises.push(p);
      }
    });
    return Promise.all(promises);
  };

  const blobObject = async (field) => {
    return await convertToData(displayCopy[field]).then((res) => ({
      url: res,
      title: displayCopy[field].name,
      type: displayCopy[field].type.split("/")[0],
    }));
  };

  useEffect(() => {
    // setLoading(true)
    async function blobData() {
      await blobArray("carousel").then((result) => {
        setDisplay((prevState) => ({
          ...prevState,
          carousel: result.map((slide) => {
            if (typeof slide === "string") {
              return { url: slide };
            } else {
              return slide;
            }
          }),
        }));
      });

      await blobArray("images_videos").then((result) => {
        setDisplay((prevState) => ({
          ...prevState,
          images_videos: result.map((slide) => {
            if (typeof slide === "string") {
              return { url: slide };
            } else {
              return slide;
            }
          }),
        }));
      });

      await blobArray("thumbnails").then((result) => {
        setDisplay((prevState) => ({
          ...prevState,
          thumbnails: result.map((slide) => {
            if (typeof slide === "string") {
              return { url: slide };
            } else {
              return slide;
            }
          }),
        }));
      });

      if (displayCopy.wechat && displayCopy.wechat.path) {
        await blobObject("wechat").then((result) => {
          setDisplay((prevState) => ({
            ...prevState,
            wechat: result,
          }));
        });
      }

      if (displayCopy.logo && displayCopy.logo.path) {
        await blobObject("logo").then((result) => {
          setDisplay((prevState) => ({
            ...prevState,
            logo: result,
          }));
        });
      }
      setLoading(false);
    }
    blobData();
  }, []);

  const prepareFileUpload = (files = [], type) => {
    const formData = new FormData();
    let typeBoothData = get(stashBoothData, `${type}`, null);
    if (typeBoothData) {
      if (!Array.isArray(typeBoothData)) {
        typeBoothData = [typeBoothData];
      }
      const prevFiles = typeBoothData.map((d) => d.title);
      formData.append("previous_files", JSON.stringify(prevFiles));
    }

    if (!Array.isArray(files)) files = [files];
    if (files.forEach) {
      files.forEach((f) => {
        formData.append("files", f, f.name);
      });
    }
    if (type === "wechat_qr_code") {
      type = "wechat";
    }
    return uploadFiles(id, formData, type);
  };

  const previewSubmit = () => {
    setUsePreviewData(false);
    setPreviewData({});
    setLoading(true);
    Promise.all([
      prepareFileUpload(get(uploadedFiles, "logoFiles", []), "logo"),
      prepareFileUpload(get(uploadedFiles, "carouselFiles", []), "carousel"),
      prepareFileUpload(
        get(uploadedFiles, "imageVideoFiles", []),
        "images_videos"
      ),
      prepareFileUpload(get(uploadedFiles, "thumbnailFiles", []), "thumbnails"),
      prepareFileUpload(get(uploadedFiles, "catalogueFiles", []), "catalogs"),
      prepareFileUpload(get(uploadedFiles, "contactFiles", []), "contact"),
      prepareFileUpload(
        get(uploadedFiles, "weChatFiles", []),
        "wechat_qr_code"
      ),
    ]).then(() => {
      const newBooth = {
        name: displayedData.title,
        website_url: displayedData.website,
        category: get(displayedData, "category.id", null),
        subcategory: get(displayedData, "subcategory.id", null),
        description: displayedData.description,
        tags: displayedData.tags.map((t) => t.id),
        countries: displayedData.countries.map((c) => c.id),
        video_thumbmnail_links: displayedData.video_thumbmnail_links,
        facebook_link: displayedData.facebook_link.split(" ").join(""),
        instagram_link: displayedData.instagram_link.split(" ").join(""),
        linkedin_link: displayedData.linkedin_link.split(" ").join(""),
      };
      updateMyBooth(id, newBooth).then((res) => {
        setLoading(false);
        if (res) {
          props.history.push(`/exhibit/${myBooth.id}`);
        }
      });
    });
  };

  return (
    <Wrapper>
      <EditControls
        title="preview your booth"
        onCancel={() => props.history.goBack()}
        onPublish={previewSubmit}
      />
      <Subheader
        category={display.subcategory ? display.subcategory : display.category}
        tags={get(display, "tags", [])}
        logo={
          display.category
            ? `/assets/images/${display.category.slug}-132.png`
            : ""
        }
      />
      <Title
        title={get(display, "title", "")}
        logo={get(display, "logo.url", "")}
        website={get(display, "website", "")}
      />
      <SliderComponent
        content={get(display, "carousel", [])}
        caption={get(display, "description", "")}
        controls
        autoSlide
        sliderLoop
      />
      <FullWidthDivider />
      <Downloads
        countries={get(display, "countries", [])}
        catalogs={get(display, "catalogs", [])}
        imagesVideos={get(display, "images_videos", [])}
        thumbnails={get(display, "thumbnails", [])}
        videoThumbmnailLinks={get(displayedData, "video_thumbmnail_links", [])}
      />
      <Contact
        instagram={get(display, "instagram_link", "")}
        linkedin={get(display, "linkedin_link", "")}
        facebook={get(display, "facebook_link", "")}
        wechat={get(display, "wechat", {})}
        contact={get(display, "contact", {})}
      />
      <FullWidthDivider />
    </Wrapper>
  );
};

export default withRouter(Preview);
