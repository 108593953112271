import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FullWidthDivider } from "../../layout/divider";
import { ROLES } from "../../../constants/roles";
import { useAuthContext } from "../../../store/auth";

const MenuListItem = ({ name, slug, subcategories }) => {
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const { role } = useAuthContext();

  const handleSubmenuOpen = () => {
    setSubmenuOpen(true);
    const body = document.querySelectorAll("body")[0];
    body.classList.toggle("menu-submenu-open");
  };

  const handleBackClick = () => {
    setSubmenuOpen(false);
    const body = document.querySelectorAll("body")[0];
    body.classList.toggle("menu-submenu-open");
  };

  if (role === ROLES.exhibitor) {
    return null;
  }

  return (
    <li className={submenuOpen ? "menu-list__item open" : "menu-list__item"}>
      {subcategories.length ? (
        <>
          <button onClick={handleSubmenuOpen}>
            {name}
            <span className="menu-list__item-icon icon-chevron-right" />
          </button>
        </>
      ) : (
        <Link to={`/category/${slug}`}>{name}</Link>
      )}
      {subcategories.length && role !== ROLES.exhibitor ? (
        <>
          <ul className="menu-subcategory">
            {subcategories.map((subcategory) => {
              return (
                <li>
                  <Link to={`/category/${subcategory.slug}`}>
                    {subcategory.name}
                  </Link>
                </li>
              );
            })}
          </ul>
          {/* top category link of submenu */}
          <Link
            to={`/category/${slug}`}
            className="menu-list__item-subcategory"
          >
            {name}
          </Link>
          <button
            className="menu-list__item-back icon-arrow-left"
            onClick={handleBackClick}
          />
        </>
      ) : null}
    </li>
  );
};

const Menu = ({ categories, menuOpen, toggleMenu, role, logout }) => {
  let location = useLocation();
  const { unAuthUser } = useAuthContext();

  const hadleClose = () => {
    toggleMenu();
    const body = document.querySelectorAll("body")[0];
    body.classList.remove("menu-submenu-open");
  };

  useEffect(() => {
    if (menuOpen) {
      const body = document.querySelectorAll("body")[0];
      body.classList.remove("menu-submenu-open");
      toggleMenu();
    }
  }, [location]);

  return (
    <>
      {menuOpen && (
        <div className={`${menuOpen ? "menu menu--open" : "menu-closed"}`}>
          <div className="menu-controls">
            <img
              className="menu-controls-logo"
              src="/assets/images/logo-hde.svg"
              alt="logo HDE."
              width="50"
              height="50"
            />
            <button
              className="menu-controls__icon icon-x"
              onClick={hadleClose}
            ></button>
          </div>
          <ul className="menu-list">
            {categories.map((category) => (
              <MenuListItem
                key={`${category.slug}`}
                name={category.name}
                slug={category.slug}
                subcategories={category.subcategories}
              />
            ))}
          </ul>
          <FullWidthDivider />
          {role === ROLES.admin ||
          role === ROLES.superAdmin ||
          role === ROLES.hyatt ? (
            <ul className="menu-list">
              <li className="menu-list__item">
                <Link to="/search">Products</Link>
              </li>
              <li className="menu-list__item">
                <Link to="/favorites">Following</Link>
              </li>
              <li className="menu-list__item">
                <Link to="/partners">Partners</Link>
              </li>
              <li className="menu-list__item">
                <Link to="/terms">Terms & conditions</Link>
              </li>
              <li className="menu-list__item">
                <button onClick={logout}>Logout</button>
              </li>
            </ul>
          ) : null}
          {role === ROLES.exhibitor ? (
            <ul className="menu-list">
              <li className="menu-list__item">
                <Link to="/myaccount/brands">Account</Link>
              </li>
              <li className="menu-list__item">
                <Link to="/search">Products</Link>
              </li>
              {/* <li className="menu-list__item">
                <Link to="/myaccount/stats">Statistics</Link>
              </li> */}
              <li className="menu-list__item">
                <Link to="/hotels">Hotel list</Link>
              </li>
              {/* <li className="menu-list__item">
                <Link to="/search">Search</Link>
              </li> */}
              <li className="menu-list__item">
                <Link to="/terms">Terms & conditions</Link>
              </li>
              <li className="menu-list__item">
                <button onClick={logout}>Logout</button>
              </li>
            </ul>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Menu;
