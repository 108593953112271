import React from "react";
import { Link } from "react-router-dom";

const MenuListItem = ({ name, subcategories, slug, toggleMenu }) => (
  <li className="menu-list__item" onClick={toggleMenu}>
    {subcategories.length ? (
      <Link to={`/category/${slug}`}>
        {name}
        <span className="menu-list__item-icon icon-chevron-right" />

        {subcategories.length ? (
          <ul className="menu-subcategory">
            {subcategories.map((subcategory, index) => {
              return (
                <li className="menu-list__item" key={index}>
                  <Link to={`/category/${subcategory.slug}`}>
                    {subcategory.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : null}
      </Link>
    ) : (
      <Link to={`/category/${slug}`}>{name}</Link>
    )}
  </li>
);

export const DropdownMenu = (props) => {
  const { toggleMenu, categories } = props;

  return (
    <ul className="menu-list">
      {categories?.map((category) => (
        <MenuListItem
          key={category.slug}
          name={category.name}
          slug={category.slug}
          toggleMenu={toggleMenu}
          subcategories={category.subcategories}
        />
      ))}
    </ul>
  );
};

export default DropdownMenu;
