import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Label, ResponsiveContainer } from 'recharts';


const BarChartContainer = (props) => {
	return (
		<div className="statistics__bar-chart">
			<ResponsiveContainer width="100%" height="100%">
				<BarChart 
					data={props.data}
					margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
				>
					<XAxis dataKey="date" height={50}>
						<Label value="Months" offset={0} position="insideBottom" />
					</XAxis>
					<YAxis width={90}>
						<Label value="No of views" offset={20} position="insideBottomLeft" angle={-90} dy="1em"/>
					</YAxis>
					<Tooltip />
					<Bar dataKey="pv" barSize={30} fill="#151f6d" name="Page views"/>
				</BarChart>
			</ResponsiveContainer>
		</div>
	)
}

export default BarChartContainer