import React from "react";
import cn from "classnames";
import "./input.scss";

const Input = ({
  disabled,
  color,
  error,
  errorMessage,
  fullwidth,
  icon,
  id,
  flat,
  label,
  password,
  placeholder,
  search,
  size,
  type,
  onSearch,
  maxLength,
  ...otherProps
}) => {
  return (
    <div
      className={cn("input-item", {
        "input-icon-left": !!icon,
        "input-search": !!search,
      })}
    >
      {label ? (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      ) : (
        ""
      )}
      {icon ? <div className={`icon-${icon}`} /> : null}
      <input
        className={cn(
          "input",
          {
            error: error,
            disabled: disabled,
            flat: flat,
            fullwidth: fullwidth,
          },
          { [`input-${color}`]: !!color },
          { [`input-${size}`]: !!size }
        )}
        maxLength={maxLength}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        id={id}
        {...otherProps}
      />
      {search ? (
        <button
          onClick={onSearch}
          className="input-search-submit icon-search"
        />
      ) : null}
      {error ? <div className="input-error-message">{errorMessage}</div> : null}
    </div>
  );
};

export default Input;
