import React from "react";

export const SectionHead = (props) => (
  <div className="section-head">
    <h2 className="section-header text-center">{props.text}</h2>
  </div>
);

export const SectionHeader = (props) => (
  <h2 className="section-header">{props.text}</h2>
);

export const BoldHeader = (props) => (
  <h2 className="bold-header">{props.text}</h2>
);

export const LightHeader = (props) => (
  <h2 className={`light-header ${props.marginSides ? "margin-sides" : ""}`}>
    {props.text}
  </h2>
);
