import React, { useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Wrapper from "../../pages/wrapper";
// import NotAvailable from '../404/notAvailable'
import Button from "../../ui/button";
// import get from 'lodash.get'
import { useDataContext } from "../../../store/data";
import { Container } from "react-bootstrap";

const NoAccess = (props) => {
  const { loading, setLoading } = useDataContext();
  // const accessDenied = get(props.location, 'state.accessDenied', false)
  // const accessDeniedMessage = 'this page only visible for hyatt associates'
  // const notFound = 'Page not found'
  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);
  const goBack = useCallback(() => {
    props.history.go(-2);
  }, [props]);

  return (
    <Wrapper>
      <div className="not-available-page background-muted">
        <Container>
          <h2>Not Available</h2>
          <Button onClick={goBack}>Go Back</Button>
        </Container>
      </div>
    </Wrapper>
  );
};

export default withRouter(NoAccess);
