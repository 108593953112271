import React from "react";
import { useDataContext } from "../../store/data";

const MediaWrapper = React.memo((props) => {
  const { loading } = useDataContext();
  const Img = () => {
    if (props.onLoad) {
      const img = new Image();
      img.onload = props.onLoad;
      img.src = props.src;
    }
    return (
      <>
        <div
          className={`
            image-wrapper__image
            ${props.bgSize || "contain"}
            ${props.additionalClasses ? props.additionalClasses.join(" ") : ""}
          `}
          style={{ backgroundImage: "url(" + props.src + ")" }}
          onClick={props.onClick}
          id={props.mediaIndex}
          // ref={ref}
        />
        {props.title ? (
          <div className="image-wrapper__title">{props.title}</div>
        ) : null}
        {props.text ? (
          <div className="image-wrapper__text">{props.text}</div>
        ) : null}
      </>
    );
  };

  const Video = (p) => {
    return (
      <video
        id={props.mediaIndex}
        onPause={
          props.handlePausePlay
            ? (event) => {
                props.handlePausePlay(event);
              }
            : null
        }
        onPlay={
          props.handlePausePlay
            ? (event) => {
                props.handlePausePlay(event);
              }
            : null
        }
        onPlaying={
          props.handlePausePlay
            ? (event) => {
                props.handlePausePlay(event);
              }
            : null
        }
        className={`image-wrapper__image video ${
          props.additionalClasses ? props.additionalClasses.join(" ") : ""
        } ${props.bgSize || ""}`}
        muted
        autoPlay={props.autoPlay}
        playsInline={props.playsInline}
        loop={props.loop}
        controls={props.controls}
        onClick={props.onClick}
        onCanPlayThrough={loading ? props.onLoad : null}
        // ref={ref}
        onEnded={props.onEnd}
      >
        <source src={props.src} />
      </video>
    );
  };

  return (
    <>
      {props.sixteenByNine && (
        <div className="image-wrapper sixteen-nine">
          {props.video ? (
            <Video
              autoPlay={props.autoPlay}
              playsInline={props.playsInline}
              loop={props.loop}
              handlePausePlay={(event) => {
                props.handlePausePlay(event);
              }}
              muted={props.muted}
              bgSize={`${props.bgSize || ""}`}
            />
          ) : (
            <Img />
          )}
        </div>
      )}
      {props.fourByThree && (
        <div className="image-wrapper four-three">
          {props.video ? (
            <Video
              autoPlay={props.autoPlay}
              playsInline={props.playsInline}
              loop={props.loop}
              handlePausePlay={(event) => {
                props.handlePausePlay(event);
              }}
              muted={props.muted}
              bgSize={`${props.bgSize || ""}`}
            />
          ) : (
            <Img />
          )}
        </div>
      )}
      {props.square && (
        <div className="image-wrapper square">
          {props.video ? (
            <Video
              autoPlay={props.autoPlay}
              playsInline={props.playsInline}
              loop={props.loop}
              handlePausePlay={(event) => {
                props.handlePausePlay(event);
              }}
              muted={props.muted}
              bgSize={`${props.bgSize || ""}`}
            />
          ) : (
            <Img />
          )}
        </div>
      )}
    </>
  );
});

export default MediaWrapper;
