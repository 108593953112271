import React, { useEffect, useState } from "react";
import { useDataContext } from "../../../store/data";
import Wrapper from "../wrapper";
import ExhibitorList from "../../pages/category/exhibitorList";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../../layout/loader";

const FavoritesPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Loader show={isLoading} />
      <Wrapper>
        <section className="section background-muted">
          <div className="section-head">
            <h2 className="h2 title text-center">Following</h2>
          </div>
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                {/* <ExhibitorList exhibitors={favorites} /> */}
                <ExhibitorList
                  follow={true}
                  // exhibitors={searchResults}
                  canView={true}
                  // myBooth={myBooth}
                  // role={role}
                  onLoad={() => setIsLoading(false)}
                  text="You can start to follow your favorite brands and suppliers, they will be listed on this page!"
                />
              </Col>
            </Row>
          </Container>
        </section>
      </Wrapper>
    </>
  );
};

export default FavoritesPage;
