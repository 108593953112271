import React, { useState } from "react";
import get from "lodash.get";
import { baseURL } from "../../../network/API";
import moment from "moment";
import { withRouter } from "react-router-dom";
import MediaDisplay from "../../ui/MediaDisplay";

const NewsFeedCard = withRouter((props) => {
  const videoLink = get(props, "lifestyle.uri", "");

  const getBrandLogo = (img) => {
    if (img) {
      return `${baseURL}/storage/logo/${img}`;
    }
  };

  const getBackground = (img) => {
    if (img) {
      return `${baseURL}/storage/lifestyle/${img}`;
    }
  };

  return (
    <div className="home-lifestyle-list-card-outer">
      <div
        className="home-lifestyle-list-card-wrapper"
        style={{ pointerEvents: props.canView ? "" : "none" }}
        onClick={() => {
          if (props.onSelected) {
            props.onSelected({
              type: 'lifestyle',
              exhibitor: props.exhibitor,
              media: {
                type: props.isVideo ? 'video' : 'image',
                uri: props.isVideo ? baseURL + "/storage/lifestyle/" + videoLink + "#t=0.01" : getBackground(get(props, "lifestyle.uri", ""))
              }
            })
          }
        }}
      >
        <div className="home-lifestyle-list-holder">
          <div className="home-lifestyle-list-card">
            {props.isVideo ? (
              <div className="home-lifestyle-list-card__video">
                <div
                  // onClick={(e) => props.onVideoClick(videoLink)}
                  className="home-lifestyle-list-card__play icon-play"
                />
                <video>
                  <source
                    src={
                      baseURL + "/storage/lifestyle/" + videoLink + "#t=0.01"
                    }
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            ) : (
              <div
                className="home-lifestyle-list-card__background"
                // onClick={e => props.onImageClick(getBackground(get(props, "lifestyle.uri", "")))}
                style={{
                  backgroundImage:
                    "url(" +
                    getBackground(get(props, "lifestyle.uri", "")) +
                    ")",
                }}
              ></div>
            )}
            <div className="hover-bg"></div>
            <div className="home-lifestyle-list-card__container">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  props.history.push({
                    pathname: `/exhibit/${props.exhibitor}`,
                    previous: props.previous
                      ? props.previous
                      : { to: "/", name: "Home", scrollTo: "lifestyles" },
                  });
                }}
                className="home-lifestyle-list-card__brand"
              >
                <div className="home-lifestyle-list-card__icon">
                  <img
                    className="home-lifestyle-list-card__icon-image"
                    src={getBrandLogo(get(props, "brandLogo.uri", ""))}
                    alt={`${props.slug}-icon`}
                    onLoad={props.onLoad}
                  />
                </div>
                <div className="home-lifestyle-list-card__brand_name">
                  {props.brandName}
                </div>
              </div>
              <div className="lifestyle-container">
                <div className="lifestyle-inner">
                  <div className="lifestyle-infos">
                    <div className="home-lifestyle-list-card__name">
                      {props.name}
                    </div>
                    <div className="home-lifestyle-list-card__date">
                      {props?.uploadedAt
                        ? moment(props?.uploadedAt).format("D MMMM, YYYY")
                        : moment().format("D MMMM, YYYY")}
                    </div>
                  </div>
                  <div className="lifestyle-likes">
                    <button
                      onClick={(e) => {
                        if (props.isLiked) {
                          props.onUnlikeLifestyle();
                        } else {
                          props.onLikeLifestyle();
                        }
                        e.stopPropagation();
                      }}
                      className="lifestyle-like"
                    >
                      {props?.isLiked ? (
                        <span
                          className="icon-like-fill"
                          style={{ color: "#E43373" }}
                        />
                      ) : (
                        <span className="icon-like" />
                      )}
                    </button>
                    <div className="lifestyle-like-count">
                      {!!props?.likeCount ? (
                        <span>
                          {props?.likeCount > 1
                            ? `${props.likeCount} likes`
                            : `1 like`}
                        </span>
                      ) : (
                        <span> </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const NewsFeedList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();

  return (
    <>
      <div
        className={`home-lifestyle-list ${
          props.subcategories ? "sublifestyle-list" : ""
        }`}
      >
        {props.lifestyles &&
          props.lifestyles.map((c, index) => (
            <NewsFeedCard
              key={index}
              canView={props.canView}
              lifestyle={c.lifestyle}
              brandName={c.exhibitorName}
              brandLogo={c.exhibitorLogo}
              description={c.description}
              isVideo={c.lifestyle.type.includes("video")}
              name={c.name}
              likeCount={c.likeCount}
              uploadedAt={c.uploadedAt}
              exhibitor={c.exhibitor}
              onSelected={(item) => {
                setSelected({
                  ...item,
                  id: c.id,
                  brandLogo: c.exhibitorLogo,
                  brandName: c.exhibitorName,
                  onLikeLifestyle: () => props.onLikeLifestyle(c.id),
                  onUnlikeLifestyle: () => props.onUnlikeLifestyle(c.id)
                });
                setShowModal(true);
              }}
              isLiked={c.isLiked}
              onLikeLifestyle={() => props.onLikeLifestyle(c.id)}
              onUnlikeLifestyle={() => props.onUnlikeLifestyle(c.id)}
            />
          ))}
        <MediaDisplay
          show={showModal}
          item={selected}
          list={props.lifestyles}
          onClose={() => setShowModal(false)}
        />
      </div>
    </>
  );
};

export default withRouter(NewsFeedList);
