import React, { useEffect, useState } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { baseURL, getBrandsBySearch, followBooth } from "../../../network/API";
import { withRouter } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import get from "lodash.get";
import MediaWrapper from "../../layout/mediaWrapper";
import Button from "../../ui/button";
import { Col, Row } from "react-bootstrap";
import { useAuthContext } from "../../../store/auth";
import { useQuery } from "react-query";
import { getMyBooths, getCategories, getPartnersBySearch } from "../../../network/API";
import { ROLES } from "../../../constants/roles";
import Tags from "../../ui/tags";


export const ExhibitorCard = withRouter((props) => {
  const { role } = useAuthContext();
  const [isOwnBooth, setIsOwnBooth] = useState(false);
  const pathname = props.history.location.pathname;

  const booths = useQuery(["getMyBooths"], getMyBooths, {
    refetchOnWindowFocus: false,
    enabled: role === ROLES.exhibitor && !!role,
    onSuccess: (data) => {
      for (const booth of data) {
        if (booth.id === props.id) {
          setIsOwnBooth(true);
          return;
        }
      }
    },
  });

  const getPartnerLogo = (img) => {
    if (img) {
      return `${baseURL}/storage/partner_logo/${img}`;
    }
    return "/assets/images/bg-default-partner.png";
  };

  return (
    <div
      onClick={(e) => {
        if (
          (props.canView || isOwnBooth) &&
          e.target.className !== "tag" &&
          !e.target.className.includes("unfollow")
        ) {
          props.history.push({
            pathname: `/exhibit/${props.id}`,
            previous: pathname
              ? { to: pathname }
              : { to: "/search" + props.searchQuery, name: "Search" },
          });
        }
      }}
      className={`exhibitor-card${
        props.canView || isOwnBooth ? " exhibitor-clickable" : ""
      }`}
      style={{ textDecoration: "none", color: "black" }}
    >
      <div
        className="exhibitor-background"
        style={{
          backgroundImage: `url(${props.background})`,
        }}
      >
        {props.following && (
          <button
          onClick={(e) => {
            // props.onFollow();
            // e.stopPropagation();
            return false;
          }} 
          className="exhibitor-upper-favorite">
            {props.following ? (
              <span className="icon-star-fill" style={{ color: "#FFBF00" }} />
            ) : (
              <span className="icon-star" />
            )}
          </button>
        )}
        {(pathname === "/favorites") ? (
          <Button
            onClick={props.onUnfollow}
            className="exhibitor-card__unfollow"
            theme="default"
            text="Unfollow"
            clickRefClass="unfollow"
          />
        ) : null}
      </div>
      <div className="exhibitor-upper">
        {get(props, "logo.uri", "") !== "" && (
            <div className="exhibitor-upper__logo">
              <MediaWrapper
                square
                src={get(props, "logo.uri", "")}
                onLoad={props.onLoad}
              />
            </div>
          )}
        <div className="exhibitor-upper-info">
          <h3 className="exhibitor-upper-info__title">{props.name}</h3>
          {get(props, "tags", []).length > 0 && (
            <div className="tag-list">
              <Tags tags={get(props, "tags", [])} />
            </div>
          )}
            <div className="exhibitor-upper-info__description">
              {props.countries && props.countries.length > 0 && (
                <p className="products__available-in">
                  Available in:{" "}
                   <TruncateMarkup lines={1}>
                    <span className="products__country">
                      {props.countries.map((c) => c.name).filter((c) => c !== undefined).join(", ")}
                    </span>
                  </TruncateMarkup>
                </p>
              )}
            </div>
        </div>
      </div>
      <div className="exhibitor-lower">
        <div>
          <TruncateMarkup lines={3}>
            <p className="exhibitor-text">{props.description}</p>
          </TruncateMarkup>
        </div>
        
        {props?.partner &&
        <div
          className="exhibitor-partner"
          onClick={(e) => {
            if (
              (props.canView || isOwnBooth) &&
              e.target.className !== "tag" &&
              !e.target.className.includes("unfollow")
            ) {
              e.stopPropagation();
              props.history.push({
                pathname: `/partner/${props?.partner._id}`,
                previous: props.previous
                  ? props.previous
                  : { to: "/search" + props.searchQuery, name: "Search" },
              });
            }
          }}
        >
          <div className="partner__background" style={{ backgroundImage: "url(" + getPartnerLogo(get(props, "partner.logo.uri", "")) + ")" }} />
          <div className="partner__name">{get(props, "partner.name", "")}</div>
        </div>}
      </div>
    </div>
  );
});

const ExhibitorList = React.memo(
  ({
    q,
    tags,
    follow,
    category,
    subcategory,
    hyattTags,
    canView,
    redirectTo,
    onLoad,
    noButton,
    previous,
    text,
    partner
  }) => {
    const queryClient = useQueryClient();
    const partners = useQuery(['partners', {}], getPartnersBySearch, {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    })
    const categories = useQuery(["getCategories"], getCategories, {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });
    const exhibitors = useInfiniteQuery(
      ["getBrandsBySearch", { tags, follow, category, subcategory, hyattTags, partner, q }],
      getBrandsBySearch,
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) =>
          lastPage.length < 4 ? false : pages.length + 1,
        onSettled: (e) => (onLoad ? onLoad(e) : null),
      }
    );
    const onFollowBooth = useMutation(
      (values) => followBooth(values.value, values.id),
      {
        onMutate: (variables) => {
          queryClient.setQueryData(
            ["getBrandsBySearch", { tags, follow, category, subcategory, hyattTags, partner, q }],
            (oldData) => {
              for (let pageI = 0; pageI < oldData.pages.length; pageI++) {
                const exist = oldData.pages[pageI].findIndex(
                  (e) => e._id === variables.id
                );

                if (exist !== -1) {
                  oldData.pages[pageI].splice(exist, 1);
                  return oldData;
                }
              }

              return oldData;
              // isFollow: variables,
            }
          );
          queryClient.invalidateQueries([
            "getBoothDetail",
            { id: variables.id },
          ]);
        },
      }
    );
    const getCategoryBackground = (img, categories, category, subcategory) => {
      if (img) {
        return `${baseURL}/storage/background/${img}`;
      }

      if (categories) {
        for (const cat of categories) {
          if (category?._id === cat.id) {
            if (subcategory && cat) {
              for (const sub of cat.subcategories) {
                if (sub.id === subcategory._id) {
                  return sub.background?.url;
                }
              }
            }
            return `/assets/images/bg420-${get(cat, "slug", "")}.jpg`;
          }
        }
      }
      return "/assets/images/bg-default-book.png";
    };

    const getSearchQuery = `?${tags
      ?.map((tag) => `tags=${encodeURI(tag)}`)
      .join("&")}&follows=${follow}${category ? `&category=${category}` : ""}${
      subcategory ? `&subcategory=${subcategory}` : ""
    }${hyattTags ? "&hyatt_tags=" + hyattTags : ""}`;

    // Auto load next page
    useEffect(() => {
      const handleScroll = () => {
        // Get visiable window height
        var domHight = window.screen.height || window.innerHeight ||
          document.documentElement.clientHeight;
        // console.log('domHight', domHight);
        // Get dom scroll position
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // Get footer
        var footer;
        // Get footer height
        var scrollHeight;
        // Get footer offsetTop
        var offsetTop;
        // Get footer offsetTop - window height 
        var top;
        // Get footer offsetBottom + footer height
        var bottom;
    
        footer = document.getElementById(`footer`);
        if (footer) {
          scrollHeight = footer.scrollHeight;
          offsetTop = footer.offsetTop;
          top = offsetTop - domHight > 0 ? offsetTop - domHight : 0;
          bottom = scrollHeight + offsetTop;
          if (scrollTop >= top && scrollTop <= bottom) {
            if (exhibitors.hasNextPage && !exhibitors.isFetching && !exhibitors.isFetchingNextPage) {
              // console.log('fetch next page')
              exhibitors.fetchNextPage();
            }
            // console.log('footer appears !!!!!');
          } else {
            // console.log('footer not appears');
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, [exhibitors]);

    // const [loadNumber, setLoadNumber] = useState(0);
    // // const [finishedLoading, setFinishedLoading] = useState(0)
    // const [done, setDone] = useState(false);
    // const { setLoading, loading } = useDataContext();

    // let finishedLoading = 0;

    // const incrementLoaded = () => {
    //   finishedLoading++;
    //   checkFinishedLoading();
    // };

    // const checkFinishedLoading = () => {
    //   if (!done) {
    //     if (loading && loadNumber > 0 && finishedLoading >= loadNumber) {
    //       setDone(true);
    //       setTimeout(() => {
    //         setLoading(false);
    //       }, 2000);
    //     }
    //   }
    // };

    // useEffect(() => {
    //   setDone(false);
    //   setLoading(true);
    //   const boothsLength = get(props, "exhibitors", []).length;
    //   if (boothsLength > 0 && loadNumber !== boothsLength) {
    //     let actualNumberOfAssets = 0;
    //     props.exhibitors.forEach((e) => {
    //       if (e.logo) {
    //         actualNumberOfAssets++;
    //       }
    //     });
    //     if (actualNumberOfAssets > 0) {
    //       setLoadNumber(actualNumberOfAssets);
    //     } else {
    //       setTimeout(() => {
    //         setLoading(false);
    //       }, 1000);
    //     }
    //   } else {
    //     setTimeout(() => {
    //       setLoading(false);
    //     }, 1000);
    //   }
    // }, [props.exhibitors, props.location.pathname]);

    // useEffect(() => {
    //   if (!done) {
    //     if (loadNumber > 0 && finishedLoading >= loadNumber) {
    //       setDone(true);
    //       setTimeout(() => {
    //         setLoading(false);
    //       }, 2000);
    //     }
    //   }
    // }, [finishedLoading]);

    return (
      <div>
        {/* <ul className="category-taglist category-taglist--mobile">
        <li className="category-tag">
          <span>Christmas</span>
          <button className="icon-x" />
        </li>
        <li className="category-tag">
          <span>Christmas</span>
          <button className="icon-x" />
        </li>
        <li className="category-tag">
          <span>Christmas</span>
          <button className="icon-x" />
        </li>
        <li className="category-tag">
          <span>Christmas</span>
          <button className="icon-x" />
        </li>
      </ul> */}
        <div className="exhibitor-list">
          {exhibitors.data?.pages?.flat()?.length === 0 && <p className="text-center" style={{flex:1}}>{text}</p>}
          {exhibitors.data?.pages?.flat().map((e, i) => {
            return (
            <ExhibitorCard
              key={e._id + '' + i}
              name={e.name}
              logo={e.logo}
              searchQuery={getSearchQuery}
              description={e.description}
              categories={categories.data}
              category={e.category}
              subcategory={e.subcategory}
              following={e.following}
              tags={e.tags}
              countries={e.countries}
              previous={previous}
              background={getCategoryBackground(
                e.background?.uri,
                categories.data,
                e.category,
                e.subcategory
              )}
              partner={partners?.data?.find(p => p?.id === e.partner[0]?._id)}
              // myId={get(props, 'myBoothList[0].name', '')}
              canView={canView}
              id={e.id || e._id}
              onUnfollow={() =>
                onFollowBooth.mutate({ value: false, id: e._id, pos: i })
              }
              onFollow={() => 
                  onFollowBooth.mutate({ value: true, id: e._id, pos: i })                
              }
              // onLoad={incrementLoaded}
              onLoad={() => {}}
            />
          )})}
        </div>
        {!noButton && (
          <Row className="justify-content-center">
            <Col xs="auto">
              {exhibitors.hasNextPage && !redirectTo ? (
                <Button
                  isDisabled={
                    exhibitors.isFetching || exhibitors.isFetchingNextPage
                  }
                  onClick={() => exhibitors.fetchNextPage()}
                  size="min"
                >
                  {exhibitors.isFetchingNextPage ? "Loading..." : "Load more"}
                </Button>
              ) : null}
              {redirectTo && exhibitors.data?.pages?.flat()?.length !== 0 && (
                <Button onClick={() => redirectTo()} size="min">
                  {"Discover more"}
                </Button>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
);

export default withRouter(ExhibitorList);
