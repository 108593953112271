import React
, {
  // useRef, 
  useState
} 
from "react";
// import { Link } from "react-router-dom";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, A11y, Pagination } from "swiper";
// import Modal from "../popup";
// import ImageSlider from "../imageSlider";
// import { useModal } from "../../../store/hooks/useModal";
// import { baseURL } from "../../../network/API";


import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "./productCardSlider.scss";
import MediaDisplay from "../MediaDisplay";

SwiperCore.use([Navigation, A11y, Pagination]);

const ProductCardSlider = ({
  handleSlideClick,
  slidesData,
  slidesNumberDesktop,
  SlideComponent,
  noModalSlide
}) => {
  // const [isShowing, toggleModal] = useModal(false);
  // const [selectedIndex, setSelectedIndex] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();

  return (<div className="swiper-box">
    <Swiper
      className={cn("product-card-slider", {
        [`card-slider-centered`]: slidesData.length < 4,
      })}
      modules={[Navigation, A11y]}
      slidesPerView={1}
      spaceBetween={0}
      centeredSlides={false}
      navigation={{
        nextEl: ".products-swiper-button-next",
        prevEl: ".products-swiper-button-prev",
      }}
      pagination={{
        el: '.products-swiper-pagination',
        type: 'custom',
        renderCustom: function (swiper, current, total) {
          let customPaginationHtml = "";
          for(let i = 0; i < total; i++) {
              if(i === (current - 1)) {
                  customPaginationHtml += '<span class="pagination-progress active"></span>';
              } else {
                  customPaginationHtml += '<span class="pagination-progress"></span>';
              }
          }
          return customPaginationHtml;
        }
      }}
      breakpoints={{
        576: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1200: {
          slidesPerView: slidesNumberDesktop,
          spaceBetween: 0
        },
        1440: {
          slidesPerView: slidesNumberDesktop,
          spaceBetween: 0
        },
      }}
    >
      <button className="products-swiper-button-prev" />
      <button className="products-swiper-button-next" />
      {slidesData.map((slide, index) => (
        <SwiperSlide key={index}>
          <SlideComponent
            {...slide}
            handleSlideClick={info => {
              setSelected({
                ...info,
              })
              setShowModal(true);
            }}
            // handleSlideClick={(id, videoId) => {
            //   if (handleSlideClick) handleSlideClick(id, videoId);
            //   if (setSelectedIndex) setSelectedIndex(index);
            // }}
            // onImageClick={() => {
            //   toggleModal(true);
            //   setSelectedIndex(index);
            // }}
          />
        </SwiperSlide>
      ))}

      {/* <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        width={656}
        className="modal-image-slider"
      >
        <ImageSlider
          slidesData={slidesData}
          noModalSlide={noModalSlide}
          type="products"
          selectedIndex={selectedIndex}
        />
      </Modal> */}

      <MediaDisplay
        show={showModal}
        item={selected}
        list={slidesData}
        onClose={() => setShowModal(false)}
      />

    </Swiper>
    <div class="products-swiper-pagination"></div>
</div>
  );
};

export default ProductCardSlider;
