import React from "react";
import createContextStore from "./hooks/createContextStore";
import http from "../utils/axios";
import axios from "axios";
import queryString from "query-string";
// import { useDataContext } from "./data";

const initialState = {
  role: null,
  userAuthed: true,
  modalOpen: false,
  canCloseModal: true,
  ModalContent: "",
  reminderOpen: false,
  name: "",
  email: "",
};

const [AuthProvider, useAuthContext] = createContextStore(
  ({ state, setState }) => {
    // const { setLoading } = useDataContext();
    const checkToken = () => {
      const role = localStorage.getItem("role");
      const token = localStorage.getItem("token");
      if (role && token) {
        setState({
          role,
          userAuthed: true,
        });
      } else {
        setState({
          role,
          userAuthed: false,
        });
      }
    };

    const authUser = (role, name, email) => {
      setState({
        role,
        userAuthed: true,
        name,
        email,
      });
    };

    const unAuthUser = (role) => {
      localStorage.removeItem("role");
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      setState({
        role: null,
        userAuthed: null,
      });
      setTimeout(() => {
        // setLoading(false);
      }, 1000);
    };

    const updateProfile = (name, email) => {
      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      setState({
        name,
        email,
      });
    };

    const ExpirationText = () => {
      return (
        <div>
          Your account and virtual booth has been temporarily disabled.
          <br /> Please contact{" "}
          <a href="mailto:perry.chung@hyatt.com" className="link-default">
            Hyatt Asia-Pacific
          </a>{" "}
          to renew it.
        </div>
      );
    };
    const loginExhibitor = async (account_name, password) => {
      try {
        return await http.post("/auth/login2", {
          email: account_name,
          password,
        });
      } catch (e) {
        // setLoading(false);
        const message = JSON.stringify(e.toJSON());
        if (message.indexOf("401") > -1) {
          openModal("Your subscription has expired", ExpirationText, true);
        }
        if (message.indexOf("403") > -1) {
          openModal("Username or password incorrect", () => <></>, true);
        }
        console.error(e);
      }
    };

    const openModal = (title, ModalContent, canClose = true) => {
      document.body.classList.add("noscroll");
      setState({
        modalOpen: true,
        modalTitle: title,
        ModalContent: ModalContent,
        canCloseModal: canClose,
      });
    };

    const closeModal = () => {
      document.body.classList.remove("noscroll");
      setState({
        modalOpen: false,
        ModalContent: "",
        canCloseModal: true,
      });
    };

    const updateMyself = async (data) => {
      const userToken = localStorage.getItem("token");
      try {
        return await http.post("users/mine", data, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } catch (e) {
        // setLoading(false);
        console.error(e);
      }
    };

    const getSessionCookie = async (data) => {
      try {
        await axios.post(
          "http://47.75.215.13/login/",
          queryString.stringify(data),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
      } catch (e) {
        console.error(e);
      }
    };

    const resetPassword = async (token, password) => {
      try {
        return await http.post(`auth/password/${token}`, { password }, {});
      } catch (e) {
        console.error(e);
      }
    };

    const requestResetPassword = async (email) => {
      try {
        return await http.post(`auth/password`, { email }, {});
      } catch (e) {
        console.error(e);
      }
    };

    const setNewPassword = async (old, n) => {
      const userToken = localStorage.getItem("token");
      try {
        return await http.post(
          `users/password`,
          { old, new: n },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
      } catch (e) {
        console.error(e);
      }
    };

    const exchangeCodeForToken = async (code) => {
      try {
        return await http.post(
          "auth/ideas",
          {
            code,
          },
          {}
        );
      } catch (e) {
        console.error(e);
      }
    };

    return {
      role: state.role,
      userAuthed: state.userAuthed,
      updateProfile,
      checkToken,
      authUser,
      loginExhibitor,
      unAuthUser,
      openModal,
      closeModal,
      modalOpen: state.modalOpen,
      ModalContent: state.ModalContent,
      canCloseModal: state.canCloseModal,
      name: state.name,
      email: state.email,
      updateMyself,
      getSessionCookie,
      resetPassword,
      requestResetPassword,
      setNewPassword,
      exchangeCodeForToken,
    };
  },
  initialState
);

export { AuthProvider, useAuthContext };
