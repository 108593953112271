import { INSTANCE } from "./API";

export const PageView = (id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: `/exhibitors/${id}/stats/pageView`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const DownloadContact = (id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: `/exhibitors/${id}/stats/downloadContact`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const DownloadCatalog = (id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: `/exhibitors/${id}/stats/downloadCatalog`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const StartVideo = (id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: `/exhibitors/${id}/stats/videoStart`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const FinishVideo = (id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: `/exhibitors/${id}/stats/videoFinish`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });
