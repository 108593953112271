export const SEARCH_TYPE = {
    ALL: "All",
    BRANDS: "Brands",
    PRODUCTS: "Products",
};

export const COUNTRY_LIST = {
    all_aspac: "All ASPAC",
    australia: "Australia",
    cambodia: "Cambodia",
    china: "Mainland China",
    hong_kong: "Hong Kong SAR",
    macau: "Macau SAR",
    taiwan: "Taiwan",
    micronesia: "Micronesia",
    indonesia: "Indonesia",
    malaysia: "Malaysia",
    japan: "Japan",
    maldives: "Maldives",
    new_zealand: "New Zealand",
    philippines: "Philippines",
    singapore: "Singapore",
    south_korea: "South Korea",
    thailand: "Thailand",
    vietnam: "Vietnam",
    india: "India",
    nepal: "Nepal",
    // Removed
    // laos: "Laos",
    // mongolia: "Mongolia",
    // myanmar: "Myanmar",
    // south_pacific: "South Pacific",
}