import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import "./button.scss";

export const Button = ({
  id,
  text,
  name,
  className,
  theme, // primary, secondary, secondary-light, primary-dark, default
  size, //min
  isLink,
  to, // url
  type,
  isExt,
  href,
  margin, // array = 'margin-r', 'margin-b'
  isDisabled,
  onClick,
  children,
  icon,
  iconColor,
  isFullWidth,
  clickRefClass,
  tag,
  ...otherProps
}) => {
  const classThemeList = [
    "default",
    "primary",
    "secondary",
    "secondary-light",
    "primary-dark",
  ];
  const classTheme =
    !!theme && classThemeList.includes(theme)
      ? `button-${theme}`
      : "button-primary";
  const classMargin = margin ? margin.toString().split(",").join("") : "";

  const Tag = tag ? tag : "button";

  if (isExt) {
    return (
      <a
        href={href}
        className={cn(
          "button",
          [`${classTheme}`],
          { [`${className}`]: !!className },
          { [`${classMargin}`]: !!margin },
          { [`button-min`]: !!size && size === "min" },
          { [`full`]: !!isFullWidth },
          { [`disabled`]: !!isDisabled }
        )}
      >
        {icon && <span className={`icon-${icon}`}></span>}
        {children && <span>{children}</span>}
        {text && <span>{text}</span>}
      </a>
    );
  }

  return isLink ? (
    <Link
      to={to}
      className={cn(
        "button",
        [`${classTheme}`],
        { [`${className}`]: !!className },
        { [`${classMargin}`]: !!margin },
        { [`button-min`]: !!size && size === "min" },
        { [`full`]: !!isFullWidth },
        { [`disabled`]: !!isDisabled }
      )}
    >
      {icon && <span className={`icon-${icon}`}></span>}
      {children && <span>{children}</span>}
      {text && <span>{text}</span>}
    </Link>
  ) : (
    <Tag
      id={id}
      name={name}
      value={text}
      type={type}
      className={cn(
        "button",
        [`${classTheme}`],
        { [`${className}`]: !!className },
        { [`button-min`]: !!size && size === "min" },
        { [`full`]: !!isFullWidth },
        { [`${classMargin}`]: !!margin }
      )}
      disabled={isDisabled}
      onClick={onClick}
      {...otherProps}
    >
      {icon && (
        <span
          className={`icon-${icon}`}
          style={{ color: iconColor ? iconColor : "inherit" }}
        ></span>
      )}
      {children && <span>{children}</span>}
      {text && (
        <span className={clickRefClass ? " " + clickRefClass : ""}>{text}</span>
      )}
    </Tag>
  );
};

export default Button;
