import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { FullWidthDivider } from "../../layout/divider";
import { BlueButton } from "../../ui/buttons";
import { TextInput } from "../../ui/form";
import { useAuthContext } from "../../../store/auth";
import Wrapper from "../wrapper";
import Subheader from "../exhibit/Subheader";

const Password = (props) => {
  const { setNewPassword } = useAuthContext();

  const handleSetNewPassword = () => {
    const old = document.getElementById("old").value;
    const n = document.getElementById("new").value;
    const confirm = document.getElementById("confirm").value;

    if (n === confirm) {
      setNewPassword(old, n);
      props.history.push("/myaccount");
    }
  };

  return (
    <Wrapper>
      <Subheader
        logo="/assets/images/icon-myaccount.png"
        logoNotClickable
        titleNotClickable
      />
      <div className="edit-profile">
        <div>
          <TextInput id="old" placeholder="old password" />
        </div>
        <div>
          <TextInput id="new" placeholder="new password" type="password" />
        </div>
        <div>
          <TextInput
            id="confirm"
            placeholder="confirm password"
            type="password"
          />
        </div>
      </div>
      <FullWidthDivider />
      <div className="edit-profile-controls">
        <BlueButton text="save new password" onClick={handleSetNewPassword} />
        <div className="edit-profile__back-button">
          <Link
            to="/myaccount"
            style={{ textDecoration: "none", color: "#151f6d" }}
          >
            {"< back"}
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(Password);
