import React from "react";
import { baseURL } from "../../../network/API";
import moment from "moment";
import get from "lodash.get";


const NewsFeedCard = (props) => {

  const videoLink = get(props, "uri", "");

  const getBrandLogo = (img) => {
    if (img) {
      return `${baseURL}/storage/logo/${img}`;
    }
  };

  const getBackground = (img) => {
    if (img) {
      return `${baseURL}/storage/lifestyle/${img}`;
    }
  };

  return (
  <div
    onClick={() => {props.handleSlideClick(props.exhibitor)}}
  >
    <div className="home-lifestyle-list-card">
      { props.isVideo ? (
        <div
          className="home-lifestyle-list-card__video"
        >
          <div onClick={() => props.onVideoClick(videoLink)} className="home-lifestyle-list-card__play icon-play" />
          <video src={baseURL + "/storage/lifestyle/" + videoLink + "#t=0.01"} />
        </div>
        ) : (
        <div
          className="home-lifestyle-list-card__background"
          style={{ backgroundImage: "url(" + getBackground(get(props, "uri", "")) + ")" }}
        >
        </div>
      )}
      <div className="hover-bg"></div>
      <div className="home-lifestyle-list-card__container">
        <div className="home-lifestyle-list-card__brand">
          <div className="home-lifestyle-list-card__icon">
            <img
              className="home-lifestyle-list-card__icon-image"
              src={getBrandLogo(get(props, "brandLogo.uri", ""))}
              alt={`${props.slug}-icon`}
              onLoad={props.onLoad}
            />
          </div>
          <div className="home-lifestyle-list-card__brand_name">{props.brandName}</div>
        </div>
        <div className="lifestyle-container">
          <div className="lifestyle-infos">
            <div className="home-lifestyle-list-card__name">{props.name}</div>
            <div className="home-lifestyle-list-card__date">
              { props?.uploadedAt ? moment(props?.uploadedAt).format("D MMMM, YYYY") : moment().format("D MMMM, YYYY") }
            </div>
          </div>
          <div className="lifestyle-likes">
            <button
              onClick={(e) => {
                if (props.isLiked) {
                props.onClickUnlike(props.id);
              } else {
                props.onClickLike(props.id);
              }
                e.stopPropagation();
                return false;
              }} 
              className="lifestyle-like">
                {props?.isLiked ? (
                  <span className="icon-like-fill" style={{ color: "#E43373" }} />
                ) : (
                  <span className="icon-like"/>
                )}
            </button>
            <div className="lifestyle-like-count">
              {props?.likeCount ? props.likeCount : 0} likes
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>)
};

export default NewsFeedCard;
