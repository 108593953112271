import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Wrapper from "../wrapper";
import Button from "../../ui/button";
import Input from "../../ui/input";
import Textarea from "../../ui/textarea";
import { useDataContext } from "../../../store/data";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { useMutation } from "react-query";
import { brandsApply } from "../../../network/API";

const PartnerApplyPage = () => {
  const { setLoading } = useDataContext();
  const [messageIsSent, setMessageIsSent] = useState(false);
  const onSendMessage = useMutation((values) => brandsApply(values), {
    onSettled: () => setMessageIsSent(true),
  });

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Wrapper hideFooter>
      <section className="section section-partner-apply d-flex full-height background-muted">
        {!messageIsSent ? (
          <Container>
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <h2 className="h2 text-center">
                  APPLY TO JOIN THE HYATT ASIA - PACIFIC F&B + HOSPITALITY
                  DIGITAL EXHIBITION
                </h2>
              </Col>
              <Col lg={{ span: 4, offset: 4 }}>
                {/* <form>
                <Input label="Your company *" fullwidth required />
                <Input label="Your company website *" fullwidth required />
                <Input label="Your full name *" fullwidth required />
                <Input
                  label="Your email address *"
                  type="email"
                  fullwidth
                  required
                />
                <Input
                  label="Are you recommended by another company?"
                  fullwidth
                />
                <Textarea label="Your message" rows="5" fullwidth />
                <Button theme="primary" isFullWidth>
                  Next
                </Button>
              </form> */}

                <Formik
                  initialValues={{
                    company: "",
                    website: "",
                    name: "",
                    email: "",
                    recommend: "",
                    message: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.company) {
                      errors.company = "Required";
                    }
                    if (!values.website) {
                      errors.website = "Required";
                    } else if (
                      !/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
                        values.website
                      )
                    ) {
                      errors.website = "Invalid website address.";
                    }
                    if (!values.name) {
                      errors.name = "Required";
                    }
                    if (!values.email) {
                      errors.email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }
                    // if (!values.message) {
                    //   errors.message = "Required";
                    // }
                    return errors;
                  }}
                  onSubmit={() => {}}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form>
                      <Input
                        label="Your company *"
                        fullwidth
                        name="company"
                        //
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company}
                        error={errors.company && touched.company}
                        errorMessage={errors.company}
                      />
                      <Input
                        label="Your company website *"
                        fullwidth
                        name="website"
                        //
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.website}
                        error={errors.website && touched.website}
                        errorMessage={errors.website}
                      />
                      <Input
                        label="Your full name *"
                        fullwidth
                        name="name"
                        //
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        error={errors.name && touched.name}
                        errorMessage={errors.name}
                      />
                      <Input
                        label="Your email address *"
                        fullwidth
                        type="email"
                        name="email"
                        //
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={errors.email && touched.email}
                        errorMessage={errors.email}
                      />
                      <Input
                        label="Are you recommended by another company?"
                        fullwidth
                        name="recommend"
                        //
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.recommend}
                        // error={errors.recommend && touched.recommend}
                        // errorMessage={errors.recommend}
                      />
                      <Textarea
                        label="Your message"
                        rows="5"
                        fullwidth
                        name="message"
                        //
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        // error={errors.message && touched.message}
                        // errorMessage={errors.message}
                      />
                      <Button
                        theme="primary"
                        isFullWidth
                        onClick={() => {
                          onSendMessage.mutate(values);
                        }}
                        isDisabled={
                          onSendMessage.isLoading ||
                          errors.company ||
                          !touched.company ||
                          errors.website ||
                          !touched.website ||
                          errors.name ||
                          !touched.name ||
                          errors.email ||
                          !touched.email
                          // ||
                          // errors.recommend ||
                          // !touched.recommend ||
                          // errors.message ||
                          // !touched.message
                        }
                      >
                        {onSendMessage.isLoading ? "Sending..." : "Next"}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container className="m-auto">
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <div className="text-center">
                  <h2 className="h2">Thank you for your application!</h2>
                  <p>
                    Hyatt ASPAC team will come back to you regarding your
                    application.
                  </p>
                  <Button theme="primary" isLink to="/public">
                    Back to public page
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </Wrapper>
  );
};

export default withRouter(PartnerApplyPage);
