import React, { useRef, useEffect, useReducer } from "react";
import { withRouter } from "react-router-dom";

const Tags = withRouter((props) => {
  const ref = useRef(null);
  const [MAX_LENGTH, dispatch] = useReducer((state, action) => {
    if (action?.payload) return action.payload;
    const w = ref?.current?.clientWidth;

    if (w) {
      return Math.round(w / 9);
    }
    return state;
  }, Math.round(ref?.current?.clientWidth / 9));
  let letter = 0;
  let word = 0;
  let lastTag = "";
  let lines = 0;
  const theSize = () => lines * MAX_LENGTH + letter + (word - 1) * 3;
  const theSizeWithoutLast = () => letter - lastTag.length + (word - 1) * 3;

  useEffect(() => {
    function localDispatch(){
      dispatch()
    }
    window.addEventListener("resize", localDispatch);
    return () => window.removeEventListener("resize", localDispatch);
  }, []);

  useEffect(() => {
    const w = ref?.current?.clientWidth;
    if (w) {
      dispatch(w);
    }
  }, [ref]);

  return (
    <div ref={ref} style={{ width: "100%", minHeight:  24 * 2}}>
      {props.tags.map((t, index) => {
        if (lastTag) {
          return null;
        }
        if(letter + t.name.length > MAX_LENGTH - 3){
          lines++;
          letter = 0;
        }
        letter += t.name.length;
        word++;

        if (theSize() >= MAX_LENGTH * 2 - 3) {
          if (!lastTag) {
            lastTag = t.name;
          }
          return null;
        }

        return (
          <>
            {t?.immutable ? (
              <div className="immutable-tag-container">
                <div className="organic-tag-container" onClick={(e) =>{
                  e.stopPropagation();
                  props.history.push({
                    pathname: "/search",
                    search: "?q=" + t.name,
                    needUpdate: true,
                  })
                }}>
                  <img className="organic-icon" src="/assets/images/organic-icon.png" alt="organic" />
                  <div className="organic-tag">{t.name}</div>
                </div>
              </div>
            ) : (
              <div className="tag" key={t.name} onClick={(e) =>{
                e.stopPropagation();
                props.history.push({
                  pathname: "/search",
                  search: "?q=" + t.name,
                  needUpdate: true,
                })
              }}>
                {t.name}
                </div>
            )}
          </>
        );
      })}
      {theSize() >= MAX_LENGTH * 2 - 3 ? (
        <>
          {MAX_LENGTH * 2 - theSizeWithoutLast() - 3 > 0 && (
            <span
              className="tag"
              onClick={() => {
                if (MAX_LENGTH - theSizeWithoutLast() - 3 > 0) {
                  props.history.push({
                    pathname: "/search",
                    search: "?q=" + lastTag,
                    needUpdate: true,
                  });
                }
              }}
            >
              {lastTag.substring(0, MAX_LENGTH * 2 - theSizeWithoutLast() - 3)}
            </span>
          )}
          ...
        </>
      ) : null}
    </div>
  );
});

export default Tags;
