import React from 'react'
import Checkbox from 'react-simple-checkbox'

const CountryList = (props) => {
	const { toggleCountry, checkedCountries } = props
	
	return (
		<div className="country-list">
			{props.countries.map(c => (
				<div className="country-list__item" key={c.name}>
					<Checkbox
						color={{
							color: 'black',
							tickColor: 'black'
						}}
						size={2}
						tickSize={2}
						tickAnimationDuration={100}
						backAnimationDuration={100}
						borderThickness={2}
						checked={checkedCountries.indexOf(c.name) !== -1}
						onChange={c.name === 'All ASPAC' ? () => props.aspac() : () => toggleCountry(c.name)}
					/>
					<div className="country-list-label">{c.name}</div>
				</div>
			))}
		</div>
	)
}

export default CountryList