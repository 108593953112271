import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const FooterSmall = (props) => {
  return (
    <>
      {!props.hide && (
        <div
          className={
            props.role
              ? "footer footer--small"
              : "footer footer--small background-muted"
          }
        >
          <img
            className="footer__logo"
            src="/assets/images/logo-hyatt.png"
            srcSet="/assets/images/logo-hyatt.png, /assets/images/logo-hyatt@2x.png 2x"
            alt="hyatt logo."
            width="141"
            height="34"
          />
          <p className="footer__copyright">
            &copy;{`${moment().year()} Hyatt Asia-Pacific - `}
            <Link
              to="/terms"
              style={{ textDecoration: "none", color: "#151F6D" }}
            >
              Terms & Conditions
            </Link>
          </p>
        </div>
      )}
    </>
  );
};

export default FooterSmall;
