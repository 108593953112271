import React, { useState } from "react";
import MediaWrapper from "../../layout/mediaWrapper";
import { ROLES } from "../../../constants/roles";
import AddMore from "../../ui/addMore";
import Input from "../../ui/input";
import Textarea from "../../ui/textarea";
import InputFile from "../../ui/inputFile";
import Modal from "../../ui/popup";
import { useModal } from "../../../store/hooks/useModal";
import Checkbox from "../../ui/checkbox";
import Button from "../../ui/button";
import ImageSlider from "../../ui/imageSlider";
import { useQuery } from "react-query";
import { baseURL, getProductTags } from "../../../network/API";
import { uploadProduct } from "../../../network/Upload";
import get from "lodash.get";
import Select from "react-select";
import { COUNTRY_LIST } from "../../../constants/commons";
import ProductCardSlider from "../../ui/productCardSlider";
import ProductCard from "../../ui/productCardSlider/productCard";

const Downloads = (props) => {
  const [popup, setPopup] = useState({});
  const [savedCountries, setSavedCountries] = useState(props.countries);
  // const [isShowing, toggleModal] = useModal();
  const [isShowingModalAvailability, toggleModalAvailability] = useModal();
  // const [isShowingModalImages, toggleModalImages] = useModal();
  // const [selectedIndex, setSelectedIndex] = useState();

  // const countries = useQuery(["getCountries"], getCountries);

  const countryList = [];
  for (const key in COUNTRY_LIST) {
    countryList.push({
      id: key,
      name: COUNTRY_LIST[key],
    });
  }

  // const handleDownload = () => {
  //   if (localStorage.getItem("role") !== ROLES.exhibitor) {
  //     props.downloadCatalog(props.id);
  //   }
  // };
  
  const tags = useQuery(
    [
      "getProductTags",
      {
        category: props.category?.id || undefined,
        subcategory: props.subcategory?.id || undefined,
      },
    ],
    getProductTags,
    {
      refetchOnWindowFocus: false,
    }
  );

  const Popup = () => {
    return (
      <>
        {/* <div className="modal-overlay popup-overlay" /> */}
        <div className="popup-window">
          <div className="popup-window__close" onClick={() => setPopup({})}>
            X
          </div>
          {popup.type === "img" && (
            <MediaWrapper
              src={popup.src}
              cover
              fourByThree
              additionalClasses={["popup-window__media"]}
            />
          )}
          {popup.type === "video" && (
            <MediaWrapper
              src={popup.src}
              video
              fourByThree
              controls
              additionalClasses={["popup-window__media"]}
            />
          )}
        </div>
      </>
    );
  };

  return props.editMode ? (
    <>
      <section className="products">
        <div className="text-center">
          <h2 className="h2 title products__title margin-bottom-40">
            Discover our products
          </h2>
          {/* {props.countries && props.countries.length > 0 && (
            <p className="products__available-in-edit">
              Available in:
              <p className="products__country">
                {props.countries
                  .map((country) => {
                    return country.name;
                  })
                  .join(", ")}
              </p>
            </p>
          )} */}
          <Modal
            isShowing={isShowingModalAvailability}
            onClose={() => {
              toggleModalAvailability();
              setSavedCountries(props.countries);
            }}
            width={680}
          >
            <div className="modal-title">Availability</div>
            <div className="modal-subtitle">Country / region availability</div>
            <div className="modal-checkboxes">
              <Checkbox
                name={"checkboxvalueASPAC"}
                checked={
                  savedCountries.findIndex((e) => e === "all_aspac") !== -1
                }
                onChange={(e) => {
                  let all = savedCountries.find((e) => e === "all_aspac");
                  if (!all) {
                    setSavedCountries([
                      countryList.find((e) => e.id === "all_aspac")?.id,
                    ]);
                  } else {
                    setSavedCountries([]);
                  }
                }}
              >
                All ASPAC
              </Checkbox>
              {countryList
                .filter((e) => e.id !== "all_aspac")
                .map((country, index) => (
                  <Checkbox
                    value={country.id}
                    name={"checkboxvalue" + index}
                    key={index}
                    checked={
                      savedCountries.findIndex((e) => e === country.id) !== -1
                    }
                    onChange={(e) => {
                      let ind =
                        savedCountries.findIndex((e) => e === "all_aspac") !==
                        -1;
                      console.log("kkkk", ind, savedCountries);

                      let ctr = ind ? [] : [...savedCountries];
                      const ctrId = savedCountries.findIndex(
                        (e) => e === country.id
                      );
                      if (ctrId !== -1) {
                        ctr.splice(ctrId, 1);
                      } else {
                        ctr.push(country.id);
                      }
                      setSavedCountries(ctr);
                    }}
                  >
                    {country.name}
                  </Checkbox>
                ))}
            </div>
            <Button
              onClick={() => {
                toggleModalAvailability();
                props.onBoothEdited({ countriesAvailable: savedCountries });
              }}
              text="Save"
              name="Save"
              margin={["margin-b, margin-r"]}
            />
          </Modal>
          <p className="products__country">
            {props.countries
              .map((country) => {
                return countryList.find((e) => e.id === country);
              })
              .filter((country) => country !== undefined)
              .map((country) => {
                return country.name;
              })
              .join(", ")}
          </p>
        </div>
        {props.errors?.products_nb && (
          <div className="text-center text-error">
            {props.errors?.products_nb}
          </div>
        )}

        {popup.src && <Popup />}

        <div className="products-images">
          <div className="products-image-item" key="addmore">
              <div className="products-image">
                <AddMore
                  id="addNewProduct"
                  request={uploadProduct}
                  onFileUploaded={(file) =>
                    props.onBoothEdited({
                      products: [].concat({ product: file }, props.products),
                      // products: [...props.products, { product: file }],
                    })
                  }
                ></AddMore>
              </div>
            </div>
          {props.products.map((product, i) => {
            // if (iv.type.split("/")[0] === "video") {
            //   let thumbnailForVideo = null;
            //   for (const i in props.videoThumbmnailLinks) {
            //     if (props.videoThumbmnailLinks[i].video === iv.title) {
            //       for (const j in props.thumbnails) {
            //         if (
            //           props.videoThumbmnailLinks[i].thumbnail ===
            //             props.thumbnails[j].title ||
            //           props.videoThumbmnailLinks[i].thumbnail ===
            //             props.thumbnails[j].name
            //         ) {
            //           thumbnailForVideo = props.thumbnails[j].url;
            //           break;
            //         }
            //       }
            //       break;
            //     }
            //   }

            //   if (thumbnailForVideo) {
            //     return (
            //       <MediaWrapper
            //         key={`${iv.name}${i}`}
            //         fourByThree
            //         src={thumbnailForVideo}
            //         bgSize="cover"
            //         additionalClasses={["grey-border cursor-pointer"]}
            //         onClick={() => setPopup({ src: iv.url, type: "video" })}
            //         title="Product name"
            //         text="If 3/4 of your drink is the mixer, mix with the best. No. 1 Best Selling and No. 1 Most Trending tonic water by the worlds 50 best bars for 6 years running."
            //       />
            //     );
            //   } else {
            //     return (
            //       <MediaWrapper
            //         key={`${iv.name}${i}`}
            //         fourByThree
            //         video
            //         src={iv.url}
            //         // controls
            //         bgSize="video-cover"
            //         additionalClasses={["grey-border cursor-pointer"]}
            //         onClick={() => setPopup({ src: iv.url, type: "video" })}
            //         title="Product name"
            //         text="If 3/4 of your drink is the mixer, mix with the best. No. 1 Best Selling and No. 1 Most Trending tonic water by the worlds 50 best bars for 6 years running."
            //       />
            //     );
            //   }
            // } else {
            return (
              <div className="products-image-item" key={i}>
                <div
                  className="products-image"
                  style={{
                    backgroundImage: `url(${
                      baseURL +
                      "/storage/products/" +
                      get(product, "product.uri", [])
                    })`,
                  }}
                >
                  <button
                    onClick={() => {
                      let newProducts = [...props.products];
                      newProducts.splice(i, 1);
                      props.onBoothEdited({
                        products: newProducts,
                      });
                    }}
                    className="exhibit-delete"
                  >
                    <div className="exhibit-delete-icon icon-trash" />
                  </button>
                </div>
                <InputFile
                  id="inputFile1"
                  placeholder="Drag and drop a new file"
                  fullwidth
                  request={uploadProduct}
                  onFileUploaded={(data) => {
                    let newProducts = [...props.products];
                    newProducts[i].product = data;
                    props.onBoothEdited({
                      products: newProducts,
                    });
                  }}
                />
                <Input
                  value={get(product, "name", "")}
                  onChange={(e) => {
                    let newProducts = [...props.products];
                    newProducts[i].name = e.target.value;
                    props.onBoothEdited({
                      products: newProducts,
                    });
                  }}
                  maxLength={85}
                  fullwidth
                  error={props.errors?.products && props.errors?.products[i]}
                  errorMessage={
                    props.errors?.products ? props.errors?.products[i] : ""
                  }
                />
                <Textarea
                  value={get(product, "description", "")}
                  rows="7"
                  fullwidth
                  onChange={(e) => {
                    let newProducts = [...props.products];
                    newProducts[i].description = e.target.value;
                    props.onBoothEdited({
                      products: newProducts,
                    });
                  }}
                  placeholder="Tell us more about your product/service"
                />
                <Select
                  value={get(product, "tags", []).map((e) => ({
                    value: e?.id,
                    label: e?.name,
                  }))}
                  onChange={(e) => {
                    let newProducts = [...props.products];
                    newProducts[i].tags = e.map((t) => ({ id: t.value, name: t.label }));
                    props.onBoothEdited({
                      products: newProducts,
                    });
                  }}
                  isMulti
                  name="product_tags"
                  options={get(tags, "data", []).map((e) => ({
                    value: e?.id,
                    label: e?.name,
                  }))}
                  className="multi-select"
                  classNamePrefix="select"
                  placeholder="Choose tags"
                />
              </div>
            );
          })}
        </div>
      </section>
    </>
  ) : (
    <>
      <section className="products">
        <div className="text-center">
          <h2 className="h2 title products__title margin-bottom-40">
            {props.type === "product" ? "Discover our products" : "Discover our services"}
          </h2>
          {/* {props.countries && props.countries.length > 0 && (
            <p className="products__available-in-edit">Available in:</p>
          )}
          <p className="products__country">
            {props.countries
              .map((country) => {
                return country.name;
              })
              .join(", ")}
          </p> */}
        </div>
        <ProductCardSlider
          slidesData={
            props?.products
              ? props?.products?.map((ex) => {
                  return {
                    name: ex?.name,
                    uri: ex?.product?.uri,
                    id: ex._id,
                    description: ex?.description,
                    isVideo: false
                  };
                })
              : []
          }
          slidesNumberDesktop={4}
          SlideComponent={ProductCard}
          noModalSlide={true}
        />
        {/* <div className="products-images">
          {props.products.map((image, index) => {
            return (
              <div className="products-image-item" key={index}>
                <div
                  className="products-image"
                  style={{
                    backgroundImage: `url(${
                      baseURL +
                      "/storage/products/" +
                      get(image, "product.uri", [])
                    })`,
                  }}
                  onClick={() => {
                    toggleModalImages(true);
                    setSelectedIndex(index);
                  }}
                />
                <div className="products-image__name">
                  {get(image, "name", [])}
                </div>
                <div className="products-image__desc">
                  {get(image, "description", [])}
                </div>
              </div>
            );
          })}
        </div> */}
        {/* <Modal
          isShowing={isShowingModalImages}
          onClose={toggleModalImages}
          width={656}
          className="modal-image-slider"
        >
          <ImageSlider
            slidesData={props.products}
            type="products"
            selectedIndex={selectedIndex}
          />
        </Modal> */}
      </section>
    </>
  );
};

export default Downloads;
