import React from "react";
import TruncateMarkup from "react-truncate-markup";
import InputFile from "../../ui/inputFile";
import { FullWidthDivider } from "../../layout/divider";
import { baseURL } from "../../../network/API";
import { uploadCatalog } from "../../../network/Upload";
import { DownloadCatalog } from "../../../network/Stats";
import { useAuthContext } from "../../../store/auth";

const Files = (props) => {
  const { role } = useAuthContext();
  const handleDownload = (url, name, type) => {
    DownloadCatalog(props.id);

    const axios = require("axios");
    axios({
      method: "get",
      url: url,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  return props.editMode ? (
    <>
      <h2 className="h2 catalog__title title">Download our catalogues</h2>
      <div class="catalog__subtitle">Catalogue</div>
      {props.catalogs.map((catalog, i) => (
        <InputFile
          id={`inputCatalog${i}`}
          placeholder={catalog.name}
          fullwidth
          large
          uploaded
          request={uploadCatalog}
          formats="pdf (100mb max)"
          fileSize={100}
          accept=".pdf"
          onDelete={() => {
            let newVideos = [...props.catalogs];
            newVideos.splice(i, 1);
            props.onBoothEdited({
              catalogs: newVideos,
            });
          }}
          onFileUploaded={(data) => {
            let newCatalog = [...props.catalogs];
            newCatalog[i] = data;
            props.onBoothEdited({
              catalogs: newCatalog,
            });
          }}
          noupload
        />
      ))}
      <InputFile
        id="inputCatalogNew"
        placeholder="Drag and drop a new file"
        formats="pdf (100mb max)"
        fileSize={100}
        accept=".pdf"
        request={uploadCatalog}
        onFileUploaded={(data) =>
          props.onBoothEdited({
            catalogs: [...props.catalogs, data],
          })
        }
      />
      <FullWidthDivider />
    </>
  ) : (
    <>
      <h2 className="h2 catalog__title title">Download our catalogues</h2>
      {props.catalogs.map((c, index) => (
        <TruncateMarkup lines={2} key={index}>
          <a
            className="button-download"
            // href={`${baseURL}/storage/catalog/${c.uri}`}
            // target="_blank"
            // download
            rel="noopener noreferrer"
            onClick={() =>
              handleDownload(
                `${baseURL}/storage/catalog/${c?.uri}`,
                c.title || c.name,
                c.type
              )
            }
          >
            <span>{c.title || c.name}</span>
            <div className="button-download-icon icon-download"></div>
          </a>
        </TruncateMarkup>
      ))}
      <FullWidthDivider />
    </>
  );
};

export default Files;
