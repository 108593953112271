const axios = require("axios");

export const baseURL =
  process.env.REACT_APP_API_URL || "https://mansan-api.dev.mediasia.cn";

export const INSTANCE = axios.create({
  baseURL,
});

INSTANCE.interceptors.response.use(async (response) => {
  console.log("Response:", response);

  return response.data;
});

export const login = (account_name, password) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: "/auth/login2",
      data: {
        // account_name,
        email: account_name,
        password,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getCategories = () =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: "/categories",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getPopularLifestyles = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { category } = queryKey[1];

    if (!category) {
      resolve([]);
      return;
    }

    INSTANCE({
      method: "get",
      url: "/lifestyles/popular",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        category: category ? category : undefined,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getCountries = () =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: "/countries",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        range: "[0,1000]",
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getTags = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { category, subcategory } = queryKey[1];

    if (!category && !subcategory) {
      resolve([]);
      return;
    }

    INSTANCE({
      method: "get",
      url: "/tags",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        category: subcategory ? undefined : category || undefined,
        subcategory: subcategory || undefined,
      },
    })
      .then(resolve)
      .catch(reject);
  });


export const getProductTags = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { category, subcategory } = queryKey[1];

    if (!category && !subcategory) {
      resolve([]);
      return;
    }

    INSTANCE({
      method: "get",
      url: "/product-tags",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        category: subcategory ? undefined : category || undefined,
        subcategory: subcategory || undefined,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getHyattTags = () =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: "/hyatt-tags",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getBrandsBySearch = ({ queryKey, pageParam = 1 }) =>
  new Promise((resolve, reject) => {
    const { category, subcategory, tags, follow, hyattTags, partner, q } = queryKey[1];
    INSTANCE({
      method: "get",
      url: "/exhibitors/search",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        category,
        subcategory,
        tags,
        follow,
        hyatt_tags: hyattTags,
        exhibitor: partner,
        q,
        page: pageParam,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getProductsBySearch = ({ queryKey, pageParam = 1 }) =>
  new Promise((resolve, reject) => {
    const { category, subcategory, tags, q } = queryKey[1];
    INSTANCE({
      method: "get",
      url: "/products/search",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        tags,
        category,
        subcategory,
        q,
        page: pageParam,
      },
    })
      .then(resolve)
      .catch(reject);
  });

  export const getPartnersBySearch = ({ queryKey, pageParam = 1 }) =>
  new Promise((resolve, reject) => {
    const { q } = queryKey[1];
    INSTANCE({
      method: "get",
      url: "/users/search",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        q,
        page: pageParam,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getPartnerDetail = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1];

    INSTANCE({
      method: "get",
      url: `/users/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getMyBooths = () =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: "/exhibitors/mine",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getPopularProducts = ({ queryKey }) =>
  new Promise((resolve, reject) => {

    const { user } = queryKey[1];

    INSTANCE({
      method: "get",
      url: "/products/popular",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        user,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const changeInfos = (name, email) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: "/users/mine",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        name,
        email,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const changePassword = (oldPass, newPass) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: "/users/password",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        old: oldPass,
        new: newPass,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getBoothDetail = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id, page } = queryKey[1];

    INSTANCE({
      method: "get",
      url: `/exhibitors/${id}/${page}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getBoothInfo = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1];

    INSTANCE({
      method: "get",
      url: `/exhibitors/${id}/front`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const saveBooth = (data, boothId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/exhibitors/${boothId}/mine`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const getBrands = () =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: "/hotel-brands",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getContacts = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { brand, country } = queryKey[1];

    INSTANCE({
      method: "get",
      url: "/hotel-contacts",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        hotel_brand: brand,
        country,
        range: "[0,1000]",
      },
    })
      .then(resolve)
      .catch(reject);
  });

  export const getContactsByFilter = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { brand, country, isUpComing, q } = queryKey[1];

    INSTANCE({
      method: "get",
      url: "/hotel-contacts/listByFilter",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        hotel_brand: brand,
        country,
        q,
        isUpComing: isUpComing,
        range: "[0,1000]",
      },
    })
      .then(resolve)
      .catch(reject);
  });

  export const getBrandsByFilter = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { isUpComing } = queryKey[1];
    const params = {
      range: "[0,1000]",

    };
    // if (!isUpComing) {
    //   params.isUpComing = false;
    // } else {
    //   params.isUpComing = true;
    // }

    INSTANCE({
      method: "get",
      url: "/hotel-brands",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: params,
    })
      .then(resolve)
      .catch(reject);
  });

  export const getMyBoothsCountries = () =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: "/exhibitors/countries/mine",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const sendRequestMeeting = (data, boothId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/exhibitors/${boothId}/meeting`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const sendMessageToHotel = (data, boothId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `hotel-contacts/${boothId}/contact`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const sendMessageToHotelFromStat = (data, userId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `users/${userId}/contact`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const getMyNotification = ({ queryKey, pageParam = 1 }) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: "/notifications/mine",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        range: "[0,5]",
        page: pageParam,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const readAllNotifications = () =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: "/notifications/mine",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const readANotifications = (id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/notifications/${id}/mine`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const followBooth = (follow, exId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/exhibitors/${exId}/follow`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        following: follow,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const likeLifestyle = (lifestyleId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/lifestyles/${lifestyleId}/like`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const unlikeLifestyle = (lifestyleId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "delete",
      url: `/lifestyles/${lifestyleId}/like`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getSimilars = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1];

    INSTANCE({
      method: "get",
      url: `/exhibitors/${id}/similar`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getHomeInfos = () =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: "/homepages/front",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const brandsApply = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: "/exhibitors/apply",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const resetPassword = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: "/auth/password",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const getMyStats = () =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: "/exhibitors/stats/mine",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getSimilarVideos = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { category, subcategory } = queryKey[1];
    INSTANCE({
      method: "get",
      url: `/exhibitors/similarvideo/${category}${
        subcategory ? `/${subcategory}` : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getBrandsSuggest = (q) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: `/exhibitors/suggest?q=${q}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getHotelContactsSuggest = (q) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: `/hotel-contacts/suggest?q=${q}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getPartnersSuggest = (q) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "get",
      url: `/users/suggest?q=${q}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });