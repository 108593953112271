import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-query";
import { getCategories } from "../../../network/API";
import { useAuthContext } from "../../../store/auth";
import Loader from "../../layout/loader";
import queryString from "query-string";
import trim from "lodash.trim";
import SearchHeader from "./searchHeader";
import ExhibitorList from "../category/exhibitorList";
import tracking from "../../../utils/tracking";
import { ROLES } from "../../../constants/roles";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { useFilterMenu } from "../../../store/hooks/useFilterMenu";
import ProductList from "../category/productList";
import SvgRenderer from "../../ui/svgRender";
import { useDebounceFn } from "ahooks";
import get from "lodash.get";

const SearchPage = (props) => {
  const query = queryString.parse(props.history.location.search);
  const search = get(query, "q", "");
  const { role } = useAuthContext();
  const categories = useQuery(["getCategories"], getCategories, {
    refetchOnWindowFocus: false,
  });

  const [searchValue, setSearchValue] = useState(search); // only for input 
  const [queryQ, setQueryQ] = useState(search); // for real search
  const [filterTags, setFilterTags] = useState([]);
  const [filterCategory, setFilterCategory] = useState();
  const [filterSubcategory, setFilterSubcategory] = useState();
  const [followsOnly, setFollowsOnly] = useState(false);
  const [hyattTags, setHyattTags] = useState();
  const { isShowingMenu, toggleFilterMenu } = useFilterMenu();
  const [searchType, setSearchType] = useState("Products");
  const [filterExhibitor, setFilterExhibitor] = useState();
  const [openCollapse, setOpenCollapse] = useState(false);

  useEffect(() => {
    const query = queryString.parse(props.history.location.search);
    if (props.history.location.needUpdate) {
      if (query.hyatt_tags) setHyattTags(query.hyatt_tags);
      if (query.category) setFilterCategory(query.category);
      if (query.subcategory) {
        setFilterSubcategory(query.subcategory);
        setOpenCollapse(true);
      }
      if (query.tags)
        setFilterTags(Array.isArray(query.tags) ? query.tags : [query.tags]);
      if (query.follows)
        setFollowsOnly(query.follows === "true" ? true : false);
      if (query.searchType)
        setSearchType(query.searchType);
      if (query.q) {
        setSearchValue(query.q);
        setQueryQ(query.q);
      }
    }
  }, [props.history.location]);

  useEffect(() => {
    const query = queryString.parse(props.history.location.search);
    if (query.hyatt_tags) setHyattTags(query.hyatt_tags);
    if (query.category) setFilterCategory(query.category);
    if (query.subcategory) {
        if(categories?.data){
          const _ = categories?.data?.forEach(category => {
            const res = category?.subcategories?.find(subcategory => subcategory.id === query.subcategory);
            if(res){
              setFilterCategory(category.id)
            }
          })
        }
      setFilterSubcategory(query.subcategory);
      setOpenCollapse(true);
    }
    if (query.tags)
      setFilterTags(Array.isArray(query.tags) ? query.tags : [query.tags]);
    if (query.follows) setFollowsOnly(query.follows === "true" ? true : false);
    if (query.searchType) setSearchType(query.searchType);
    if (query.q) {
      setQueryQ(query.q);
    }
    tracking.pageView("/search");
  }, [categories, props.history.location.search]);

  const setQueryParams = (
    search,
    tags,
    follow,
    category,
    subcategory,
    hyattT,
    searchType
  ) => {
    props.history.push({
      pathname: "/search",
      search: `?${tags
        .map((tag) => `tags=${encodeURI(tag)}`)
        .join("&")}&follows=${follow}${
        category ? `&category=${category}` : ""
      }${subcategory ? `&subcategory=${subcategory}` : ""}${
        hyattT ? "&hyatt_tags=" + hyattT : ""}${searchType ? "&searchType=" + searchType : ""}${search ? "&q=" + search : ""}`,
      noResetScroll: true,
    });
  };

  const isAllActive = () =>
    !filterCategory && !filterSubcategory ? " active" : "";
    
  const isCategorySelected = (id) =>
    filterCategory === id || filterSubcategory === id ? " active" : "";

  const isCategoryIconSelected = (id) => {
    return filterCategory === id || filterSubcategory === id ? " category-active" : "";
  }

  const isFollowActive = () => {
    return followsOnly ? " active" : "";
  }

  const cleanCategories = () => {
    setFilterCategory("")
    setFilterSubcategory("")
    setQueryParams(
      searchValue,
      filterTags,
      followsOnly,
      "",
      "",
      hyattTags,
      searchType
    );
  }

  const handleFollowChange = (showFollow) => {
    if (showFollow) {
      // Remove all filter
      setSearchValue("");
      setFilterTags([]);
      setFilterCategory("");
      setFilterSubcategory("");
      setHyattTags("");
      setQueryQ("");
      setQueryParams(
        "",
        [],
        showFollow,
        "",
        "",
        "",
        searchType
      );
    } else {
      setQueryParams(
        searchValue,
        filterTags,
        showFollow,
        filterCategory,
        filterSubcategory,
        hyattTags,
        searchType
      );
    }
    setFollowsOnly(showFollow);
  };

  const handleAddTag = (tag) => {
    const listTags = [...filterTags];
    const tagIndex = listTags.findIndex((e) => e === tag);

    if (tagIndex === -1) {
      listTags.push(tag);
      tracking.event("searchpage", "dosearch", listTags);
      setQueryParams(
        searchValue,
        listTags,
        followsOnly,
        filterCategory,
        filterSubcategory,
        hyattTags,
        searchType
      );
      setFilterTags(listTags);
    }
  };

  const handleSearch = (v) => {
    setQueryParams(
      v,
      filterTags,
      false,
      filterCategory,
      filterSubcategory,
      hyattTags,
      searchType
    );
    setQueryQ(v);
    setSearchValue(v);
    setFollowsOnly(false);
  };

  const handelClearSearch = (event) => {
    setSearchValue("");
    setQueryQ("");
  };

  const handleDeleteTag = (tag) => {
    const listTags = [...filterTags];
    const tagIndex = listTags.findIndex((e) => e === tag);

    if (tagIndex !== -1) {
      listTags.splice(tagIndex, 1);
      setQueryParams(
        searchValue,
        listTags,
        followsOnly,
        filterCategory,
        filterSubcategory,
        hyattTags,
        searchType
      );
      setFilterTags(listTags);
    }
  };

  const handleClearTag = () => {
      setQueryParams(
        searchValue,
        [],
        followsOnly,
        filterCategory,
        filterSubcategory,
        hyattTags,
        searchType
      );
      setFilterTags([]);
  };

  const handleCategory = (id) => {
    setQueryParams(
      searchValue,
      filterTags,
      false,
      id ? id : "",
      "",
      hyattTags,
      searchType
    );
    if (filterCategory === id) {
      setOpenCollapse(!openCollapse)
    } else {
      setOpenCollapse(true)
    }
    setFilterCategory(id ? id : undefined);
    setFilterSubcategory(undefined);
    setFollowsOnly(false);
  };

  const handleSubcategory = (id) => {
    setQueryParams(
      searchValue,
      filterTags,
      false,
      "",
      id ? id : "",
      hyattTags,
      searchType
    );
    setFilterSubcategory(id ? id : undefined);
    setFollowsOnly(false);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchTypeChange = (type) => {
    setQueryParams(
      searchValue,
      filterTags,
      followsOnly,
      filterCategory,
      filterSubcategory,
      hyattTags,
      type
    );
    setSearchType(type);
  };

  const clearCategory = () => {
    setQueryParams(searchValue, filterTags, followsOnly, "", "", hyattTags, searchType);
    setFilterSubcategory();
    setFilterCategory();
  };

  const handelExhibitorListLoad = (exhibitors) => {
    let exhibitor_ids = [];
    if (exhibitors?.pages) {
      exhibitors.pages.forEach( page => {
        if (page && page.length > 0) {
          page.forEach( exhibitor => {
            exhibitor_ids.push(exhibitor._id);
          })
        }
      });
      if (exhibitor_ids.length > 0) {
        setFilterExhibitor(exhibitor_ids);
      }
    }
  };

  return (
    <>
      <Loader show={categories.isLoading} />
      <SearchHeader
        inputValue={searchValue}
        onInputChange={handleInputChange}
        hyattTags={hyattTags}
        onDeletehyattTag={() => {
          setHyattTags();
          setQueryParams(
            searchValue,
            filterTags,
            followsOnly,
            filterCategory,
            filterSubcategory,
            "",
            searchType
          );
        }}
        tagList={filterTags}
        onDeleteTag={handleDeleteTag}
        onEnterPressed={(v) => {
          v = trim(v);
          handleSearch(v);
          // handleAddTag(v);
        }}
        image="/assets/images/search-header.jpg"
        name="Our selected brands & products"
        isShowingMenu={isShowingMenu}
        toggleFilterMenu={toggleFilterMenu}
        onSearchTypeChange={handleSearchTypeChange}
        initialSearchType={searchType}
        onClearTag={handleClearTag}
        onClearSearch={handelClearSearch}
        onClearSelectedCategory={cleanCategories}
      />
      <section className="section background-grey">
        <Container>
          <Row>
            <Col lg={{ span: 2, offset: 1 }}>
              <div
                className={
                  isShowingMenu ? "category-filters show" : "category-filters"
                }
              >
                <div className="category-filters-content">
                  <div className="category-filters-wrap">
                    <div className="category-filters-title">Categories</div>
                    {role !== ROLES.exhibitor && (<>
                      <div className={"category-filters-item" + isFollowActive()}>
                        <div className="category-filters-btn category-filter-follow" onClick={() => handleFollowChange(!followsOnly)}>
                            <SvgRenderer width={'28px'} svgUrl={"/assets/images/star-icon.svg"} />
                          <div>
                            Following
                          </div>
                        </div>
                      </div>

                      
                      <div className="catogory-filters-follow-border"></div>
                    </>)}
                    <ul className="category-filters-list">
                      

                      <li className={"category-filters-item" + isAllActive()}>
                        <div className="category-filters-btn" onClick={() => clearCategory()}>
                          <SvgRenderer width={'28px'} svgUrl={"/assets/images/all-icon.svg"} />
                          <p>All</p>
                        </div>
                      </li>
                      {categories.data?.map((category) => (
                        <li
                          key={category.id}
                          className={
                            "category-filters-item" +
                            isCategorySelected(category.id)
                          }
                        >
                          <div className="category-filters-btn" onClick={() => handleCategory(category.id)}>
                            {category.small_icon ? (
                              <SvgRenderer width={'28px'} svgUrl={category.small_icon.url} />
                            ) : (
                              <div className="category-filters-img-empty"></div>
                            )}
                            <p>
                              {category.name}
                            </p>
                            <div className={`
                              category-filters-icon 
                              ${isCategoryIconSelected(category.id) && openCollapse ? 'icon-chevron-up' : 'icon-chevron-down'}
                              ${category.subcategories?.length > 0 ? '': 'invisible'} 
                            `}
                            ></div>
                          </div>
                          {category.subcategories?.length ? (
                            <Collapse in={filterCategory === category.id && openCollapse}>
                              <ul id={categories.name} className="category-filters-inner">
                                {category.subcategories?.map((subcategory) => (
                                  <div className={"subcategory-filters-btn" + isCategoryIconSelected(subcategory.id)} onClick={() => handleSubcategory(subcategory.id)}>
                                    {subcategory.small_icon ? (
                                      <SvgRenderer width={'24px'} svgUrl={subcategory.small_icon.url} />
                                    ) : (
                                      <div className="subcategory-filters-img-empty"></div>
                                    )}
                                    <li
                                      key={subcategory.id}
                                      className={
                                        "subcategory-filters-text" +
                                        isCategorySelected(subcategory.id)
                                      }
                                    >
                                      {subcategory.name}
                                    </li>
                                  </div>
                                ))}
                              </ul>
                            </Collapse>
                          ) : null}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button
                    className="category-filters-close icon-x"
                    onClick={() => toggleFilterMenu(!isShowingMenu)}
                  />
                </div>
              </div>
            </Col>
            <div style={{ width: "19px" }}></div>
            <Col lg="8">
              <ul className="search-type-filters">
                <li>
                  <button
                    className={`filters__button ${searchType === 'Products' ? " active" : ""}`}
                    onClick={() => handleSearchTypeChange('Products')}
                  >
                    Products
                  </button>
                </li>
                  <li>
                    <button
                      className={`filters__button ${searchType === 'Brands' ? " active" : ""}`}
                      onClick={() => handleSearchTypeChange('Brands')}
                    >
                      Brands
                    </button>
                  </li>
              </ul>
              {((searchType === 'All' || searchType === 'Products')) && (
                <>
                  <ProductList
                    tags={filterTags}
                    exhibitors={filterExhibitor}
                    category={filterCategory}
                    subcategory={filterSubcategory}
                    q={queryQ}
                    canView={
                      role !== ROLES.exhibitor
                    }
                    text="Sorry, no results found..."
                    noButton={true}
                  />
                </>
              )}

              {(searchType === 'All' || searchType === 'Brands') && (
                <>
                  
                  <ExhibitorList
                    tags={filterTags}
                    follow={followsOnly}
                    category={filterCategory}
                    subcategory={filterSubcategory}
                    hyattTags={hyattTags}
                    onLoad={handelExhibitorListLoad}
                    q={queryQ}
                    // exhibitors={searchResults}
                    canView={
                      role !== ROLES.exhibitor
                    }
                    // myBooth={myBooth}
                    // role={role}
                    noButton={true}
                    text="More exhibitors and brands are coming, stay tuned !"
                  />
                </>
              )} 
              
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default withRouter(SearchPage);
