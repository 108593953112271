import React from 'react'
import TruncateMarkup from 'react-truncate-markup'

export const TextInput = (props) => (
	<input
		id={props.id}
		ref={props.refObj}
		className="text-input"
		type={props.password ? 'password' : 'text'}
		placeholder={props.placeholder}
		defaultValue={props.defaultValue}
		autoFocus={props.focus}
		onChange={props.onChange ? (e) => props.onChange(e) : null} 
	/>
)

export const Select = (props) => (
	<div id={props.id} className="select">
		<select onChange={props.onChange}>
			{props.emptyDefaultText &&
				<option>{props.emptyDefaultText}</option>
			}
			{props.values.length > 0 && props.values.map((v, i) => (
				<option 
					key={`${v}${i}`}
					value={v}
					selected={v === props.defaultValue}
					>
						{props.texts[i]}
				</option>
			))}
		</select>
		<img className="select__arrow" src="/assets/images/arrow-down.png" alt="down-arrow" />
	</div>
)

export const OptGroup = (props) => {
	return (
		<div id={props.id} className="select">
			<select onChange={props.onChange}>
				{props.emptyDefaultText &&
					<option>{props.emptyDefaultText}</option>
				}
				{props.values.length > 0 && props.values.map((v, i) => {
					if (v.subcategories.length > 0) {
						return (
							<optgroup label={v.name} key={v.id}>
								{v.subcategories.map(sc => (
									<option
										key={`${sc.slug}${i}`}
										value={`subcategory-${sc.id}-${v.id}` }
										selected={props.subcategory ? sc.slug === props.subcategory : sc.slug === props.defaultValue}
									>
										{sc.name}
									</option>
								))}
							</optgroup>
						)
					} else {
						return (
							<option				
								key={`${v.id}${i}`}
								value={`category-${v.id}` }
								selected={props.subcategory ? v.slug === props.subcategory : v.slug === props.defaultValue}
							>
								{v.name}
							</option>)
					}
				}
					// <option 
					// 	key={`${v}${i}`}
					// 	value={v}
					// 	selected={v === props.defaultValue}
					// 	>
					// 		{props.texts[i]}
					// </option>
				)}
			</select>
			<img className="select__arrow" src="/assets/images/arrow-down.png" alt="down-arrow" />
		</div>
	)
}


export const Label = (props) => (
	<div className="form-label">{props.text}</div>
)

export const TagDisplay = (props) => {
	const {type, text, onClick} = props

	return (
		<div className="tag-display">
				<div className="tag-display__text">
					<>
						<TruncateMarkup lines={1}>
							<div>{props.text}</div>
							{/* <DotDotDot clamp={1}>{props.text}</DotDotDot> */}
						</TruncateMarkup>
					</>
				</div>
			<img
				className="tag-display__icon"
				src="/assets/images/icon-trash.png"
				alt="icon-close-white"
				onClick={() => onClick(type, text)}
			/>
		</div>
	)
}

export const SocialTag = (props) => {

	// const [badUrl, setBadUrl] = useState(false)

	// const validateUrl = (e) => {
	// 	if (e.target.value) {
	// 		const value = e.target.value
	// 		const hasHttp = value.indexOf('http://')
	// 		const hasHttps = value.indexOf('https://')
	// 		if (hasHttp === 0 || hasHttps === 0) {
	// 			setBadUrl(false)
	// 		} else {
	// 			setBadUrl(true)
	// 		}
	// 	} else {
	// 		setBadUrl(false)
	// 	}
	// }
	
	return (
		<>
			{/* <div className={`social-display__error-text ${badUrl ? '' : 'invisible'}`}>Url must start with http:// or https://</div> */}
			<div className="social-display">
				<img className="social-display__icon" src={`/assets/images/icon-${props.social}.png`} alt="icon-close-white" />
				<TextInput id={props.id} className="text-input" placeholder={props.placeholder} defaultValue={props.defaultValue} onChange={props.validator}/>
			</div>
			{props.helpText &&
				<div className={`help-text social-display__help-text ${props.bad ? 'red' : ''}`}>{props.helpText}</div>
			}
		</>
	)
}

export const TextArea = (props) => (
	<textarea
		className="text-area"
		id={props.id}
		placeholder={props.placeholder}
		maxLength={props.maxLength}
		defaultValue={props.defaultValue}
		>{}</textarea>
)

export const HelpText = (props) => (
<div className="help-text">{props.text}</div>
)

