import React from 'react'

export const BlueButton = (props) => (
	<button className="blue-button" onClick={props.onClick || null} disabled={props.disabled}>
		{props.text}
	</button>
)

export const BigBlackButton = (props) => (
	<button className={`black-button `} onClick={props.onClick || null} disabled={props.disabled}>
		{props.text}
	</button>
)