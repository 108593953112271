import React, { useState } from "react";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, A11y, Pagination } from "swiper";


import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "./lifestyleCardSlider.scss";
import MediaDisplay from "../MediaDisplay";

SwiperCore.use([Navigation, A11y, Pagination]);

const LifestyleCardSlider = ({
  handleSlideClick,
  slidesData,
  slidesNumberDesktop,
  SlideComponent,
  noModalSlide,
  onClickLike,
  onClickUnlike,
  initMediaLink
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();
  // const [isShowing, toggleModal] = useModal(false);
  // const [selectedIndex, setSelectedIndex] = useState();
  // const [isVideoShowing, toggleVideoModal] = useModal(false);
  // const [init, setInit] = useState(false);
  // const vidRef = useRef(null);
  // const [selectedVideoUri, setSelectedVideoUri] = useState();

  // useEffect(() => {
  //   if(init) return;
  //   if(initMediaLink && slidesData && Array.isArray(slidesData) && slidesData.length > 0){
  //     setInit(true);
  //     setTimeout(() => {
  //       if(slidesData?.find((item, index) => {
  //         if(item.uri === initMediaLink){
  //           if(item.isVideo){
  //             toggleVideoModal();
  //             setSelectedVideoUri(initMediaLink);
  //           }else{
  //             toggleModal(true);
  //             setSelectedIndex(index);
  //           }
  //             return true
  //           }else{
  //           return false
  //         }
  //       })){
  //         return;
  //       }
  //     },500)  
  //   }
  // },[initMediaLink, slidesData, init])

  // const handleVideoClose = () => {
  //   if (vidRef?.current) {
  //     vidRef.current.currentTime = 0;
  //     vidRef.current.pause();
  //     toggleVideoModal();
  //   }
  // };

  return (<div className="swiper-box">
    <Swiper
      className={cn("lifestyle-card-slider", {
        [`card-slider-centered`]: slidesData.length < 3,
      })}
      modules={[Navigation, A11y]}
      slidesPerView={1}
      spaceBetween={0}
      centeredSlides={false}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      pagination={{
        el: '.swiper-pagination',
        type: 'custom',
        renderCustom: function (swiper, current, total) {
          let customPaginationHtml = "";
          for(let i = 0; i < total; i++) {
              if(i === (current - 1)) {
                  customPaginationHtml += '<span class="pagination-progress active"></span>';
              } else {
                  customPaginationHtml += '<span class="pagination-progress"></span>';
              }
          }
          return customPaginationHtml;
        }
      }}
      breakpoints={{
        576: {
          slidesPerView: 1,
          centeredSlides: false,
          spaceBetween: 0
        },
        1200: {
          slidesPerView: slidesNumberDesktop,
          spaceBetween: 0
        },
        1440: {
          slidesPerView: slidesNumberDesktop,
          allowTouchMove: false,
          spaceBetween: 0
        },
      }}
    >
      <button className="swiper-button-prev" />
      <button className="swiper-button-next" />
      {slidesData.filter((_, index) => index <= 8).map((slide, index) => (
        <SwiperSlide key={index}>
          <SlideComponent
            {...slide}
            handleSlideClick={(info) => {
              setSelected({
                ...info,
                id: slide.id,
                onLikeLifestyle: () => onClickLike(slide.id),
                onUnlikeLifestyle: () => onClickUnlike(slide.id)
              })
              setShowModal(true);
            }}
            onClickLike={onClickLike}
            onClickUnlike={onClickUnlike}
          />
        </SwiperSlide>
      ))}

      {/* <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        width={656}
        className="modal-image-slider"
      >
        <ImageSlider
          slidesData={slidesData}
          noModalSlide={noModalSlide}
          type="lifestyle"
          selectedIndex={selectedIndex}
        />
      </Modal>

      <Modal
        isShowing={isVideoShowing}
        onClose={handleVideoClose}
        className="modal-video"
        video
      >
       <video
          ref={vidRef}
          src={baseURL + "/storage/lifestyle/" + selectedVideoUri + "#t=0.01"}
          // autoPlay={isShowing}
          autoPlay={true}
          controls
          preload="auto"
        />
      </Modal> */}
    </Swiper>
    <MediaDisplay
      show={showModal}
      item={selected}
      list={slidesData}
      onClose={() => setShowModal(false)}
    />
    <div class="swiper-pagination"></div>
</div>
  );
};

export default LifestyleCardSlider;
