import React from "react";
import cn from "classnames";
import "./inputSearchAgolia.scss";
import { Input } from "antd";
import EndAutoComplete from "../endAutoComplete/endAutoComplete";

const InputSearchAgolia = ({
  disabled,
  color,
  error,
  errorMessage,
  fullwidth,
  id,
  flat,
  password,
  placeholder,
  searchType,
  onSearchTypeChange,
  size,
  type,
  onSearch,
  fetchData,
  onClear,
  onFocus,
  value,
  ...otherProps
}) => {
 
  return (
  <>
   

    <EndAutoComplete
      className="input-agolia-search input-item"
      allowClear={{ clearIcon: <span className="input-agolia-clear icon-x" /> }}
      fetchData={fetchData}
      popupClassName="agolia-dropdown"
      getPopupContainer={triggerNode => {
        // triggerNode.parentNode.parentNode.style.overflow = 'visible';
        return triggerNode.parentNode || document.body;
      }}
      value={value}
      dropdownMatchSelectWidth={578}
      onAgoliaSearch={onSearch}
      onClear={onClear}
    >
      <Input
        className={cn(
          "input",
          "input-search__text",
          {
            error: error,
            disabled: disabled,
            flat: flat,
            fullwidth: fullwidth,
          },
        )}
        onFocus={() => {
          if(onFocus){
            onFocus();
          }
        }}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        id={id}
        value={value}
        prefix={<span className="input-agolia-submit icon-search" />}
        size="large"
        {...otherProps}
      />
    </EndAutoComplete>
    
  </>
  );
};

export default InputSearchAgolia;
