export default {
	yourBoothList: {
		name: 'my booth',
		header: 'my booth',
		menu: true
	},
	statistics: {
		name: 'statistics',
		header: 'my booth - statistics',
		menu: true
	}
}