import React, {useEffect, useState} from 'react'
import BarChartContainer from './barChart'
import {useDataContext} from '../../../store/data'
import moment from 'moment'
import Wrapper from "../wrapper";

const Statistics = () => {
    const {getMyStatisticsList, myStatisticsList} = useDataContext()
    const [formattedStats, setFormattedStats] = useState([])
    const [totalCatalog, setTotalCatalog] = useState(0)
    const [totalContacts, setTotalContacts] = useState(0)
    const reducer = (a, b) => a + b

    var stats = []

    useEffect(() => {
        getMyStatisticsList()
    }, [])

    useEffect(() => {
        myStatisticsList.forEach(list => {
            const totalCatalogDownloads = list.stats.map(s => s.catalogDownload)
            const totalContactDownloads = list.stats.map(s => s.contactDownload)
            let pushStatsIntoObject = {}

            if (totalCatalogDownloads.length) {
                setTotalCatalog(totalCatalogDownloads.reduce(reducer))
                pushStatsIntoObject.totalCatalog = totalCatalogDownloads.reduce(reducer)
            }
            if (totalContactDownloads.length) {
                setTotalContacts(totalContactDownloads.reduce(reducer))
                pushStatsIntoObject.totalContacts = totalContactDownloads.reduce(reducer)
            }

            pushStatsIntoObject.exhibitorName = list.exhibitorName
            pushStatsIntoObject.chartInfo = []
            list.stats.forEach(d => ([
                pushStatsIntoObject.chartInfo.push({
                    date: moment(d.period).format('MMM').toString(),
                    pv: d.pageView
                })
            ]))

            stats.push(pushStatsIntoObject)
        })
        // formatting statistics to display as text
        setFormattedStats(stats)
    }, [myStatisticsList])

    return (
        <Wrapper>
            {formattedStats.map((list) =>
                <div className="centered-section">
                    <div className="statistics-name">{list.exhibitorName}</div>
                    <div className="statistics">
                        <div className="statistics__bar-chart">
                            {list.chartInfo.length > 0 &&
                            <BarChartContainer data={list.chartInfo}/>
                            }
                            {(!list.chartInfo || !list.chartInfo.length || !list.chartInfo.length === 0) &&
                            <div className="statistics-stat">
                                <div className="statistics-stat__data">
                                    No graph data
                                </div>
                                <div className="statistics-stat__label">
                                    available for this user
                                </div>
                            </div>
                            }
                        </div>
                        <div className="statistics-stat__wrap">
                            <div className="statistics-stat">
                                <div className="statistics-stat__data">
                                    {list.totalCatalog}
                                </div>
                                <div className="statistics-stat__label">
                                    Catalog downloads
                                </div>
                            </div>
                            <div className="statistics-stat">
                                <div className="statistics-stat__data">
                                    {list.totalContacts}
                                </div>
                                <div className="statistics-stat__label">
                                    Contact information downloads
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Wrapper>
    )
}

export default Statistics