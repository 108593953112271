import React from "react";
import cn from "classnames";
import "./radio.scss";

const Radio = ({ color, disabled, name, value, children }) => {
  const [checked, setChecked] = React.useState(false);

  const toggle = () => setChecked(!checked);

  return (
    <label
      className={cn(
        "radio",
        { [`radio-${color}`]: !!color },
        {
          [`radio-disabled`]: !!disabled,
        }
      )}
    >
      <input
        className={cn("radio-input")}
        name={name}
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={toggle}
        value={value}
      />
      <span className="radio-box" />
      <span className="radio-label">{children}</span>
    </label>
  );
};

export default Radio;
