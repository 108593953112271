import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { getNthItemFromPathname } from "../../../utils/";
import Wrapper from "../../pages/wrapper";
import SignIn from "./signIn";
import Reset from "./reset";
import tracking from "../../../utils/tracking";
import { useQueryClient } from "react-query";

const Auth = (props) => {
  const pathname = props.history.location.pathname;
  const mode = getNthItemFromPathname(pathname, 2);
  const queryClient = useQueryClient();

  const redirectToReset = () => {
    this.props.history.push("/auth/reset");
    tracking.pageView("/auth");
  };

  useEffect(() => {
    queryClient.invalidateQueries();
  }, []);

  return (
    <>
      {!mode && (
        <Wrapper>
          <SignIn onClick={redirectToReset} />
        </Wrapper>
      )}
      {mode === "reset" && <Reset />}
    </>
  );
};

export default withRouter(Auth);
