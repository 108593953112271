import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import CategoryList from "./categoryList";
import Wrapper from "../wrapper";
import tracking from "../../../utils/tracking";
import { Container, Row, Col } from "react-bootstrap";
import {
  getHomeInfos,
  unlikeLifestyle,
  likeLifestyle,
  getBrandsSuggest,
} from "../../../network/API";
import { ROLES } from "../../../constants/roles";
import { useAuthContext } from "../../../store/auth";
import OfferSlider from "../../ui/offerSlider";
import OfferCard from "../../ui/offerSlider/offerCard";
import LogoGroup from "../../ui/LogoGroup";
import NewsFeedList from "./newsFeedList";
import InputSearchAgolia from "../../ui/inputSearchAgolia";
import { useQuery, useMutation, useQueryClient } from "react-query";


const Home = React.memo((props) => {
  const { role } = useAuthContext();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("Products");
  const queryClient = useQueryClient();
  const ref = useRef(null);


  useEffect(() => {
    if(ref.current && ref?.current?.play && typeof ref?.current?.play === 'function'){
      // eslint-disable-next-line no-unused-expressions
      ref?.current?.play()
    }
  },[ref])

  const homeInfos = useQuery(["getHomeInfos"], getHomeInfos, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    tracking.pageView("/");
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      props.history.push(
        `/search?q=${encodeURI(
          e.target.value
        )}&follows=false&searchType=${searchType}`
      );
    }
  };

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
  };

  const onLikeLifestyle = useMutation((values) => likeLifestyle(values.id), {
    onSuccess: () => {
      queryClient.invalidateQueries("getHomeInfos");
    },
  });

  const onUnlikeLifestyle = useMutation(
    (values) => unlikeLifestyle(values.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getHomeInfos");
      },
    }
  );

  const fetchData = async (value) => {
    const data = await getBrandsSuggest(value);
    let res = [];
    data.forEach((value, index) => {
      res.push({
        label: value,
        value: value,
      });
    });
    return res;
  };

  return (
    <Wrapper>
      <section className="section-hero background-primary has-overlay">
        <div className="section-hero__background-video">
          <video
            ref={ref}
            src="/assets/video/home-page-video.mp4"
            autoPlay
            playsInline
            muted
            loop
            controls={false}
            type="video/mp4"
          />
        </div>
        <div className="section-hero__content">
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                <h2 className="section-hero__title">Welcome to</h2>
                <img
                  className="section-hero-image-text"
                  src="/assets/images/hero-text.svg"
                  alt="Hyatt Asia-Pacific F&B + Hospitality Digital Exhibition."
                />
                <form>
                  <InputSearchAgolia
                    placeholder="Find the best supplier and products for your hotel"
                    size="lg"
                    searchType={searchType}
                    onSearchTypeChange={handleSearchTypeChange}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onSearch={(e) =>
                      props.history.push(
                        `/search?q=${encodeURI(
                          e
                        )}&follows=false&searchType=${searchType}`
                      )
                    }
                    onKeyDown={handleKeyDown}
                    fetchData={fetchData}
                  />
                  <p className="input-search-note">
                    Over 100 quality brands selected by Hyatt
                  </p>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* Deprecated */}
      {/* {homeInfos?.data && homeInfos.data[0]?.banner_displayed ? (
        <section className="section-promo background-muted">
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                <Promo
                  image={
                    homeInfos?.data
                      ? baseURL +
                        "/storage/announcement_banner/" +
                        homeInfos?.data[0]?.announcement_banner?.uri
                      : ""
                  }
                  title={
                    homeInfos.data
                      ? homeInfos.data[0][
                          role !== ROLES.exhibitor
                            ? "announcement_title_hyatt"
                            : "announcement_title_exhibitor"
                        ]
                      : ""
                  }
                  text={
                    homeInfos.data
                      ? homeInfos.data[0][
                          role !== ROLES.exhibitor
                            ? "announcement_hyatt"
                            : "announcement_exhibitor"
                        ]
                      : ""
                  }
                  linkText={
                    homeInfos.data
                      ? homeInfos.data[0].announcement_button_text
                      : ""
                  }
                  link={
                    homeInfos.data
                      ?
                        `/search?hyatt_tags=${homeInfos.data[0].announcement_tag}`
                      : ""
                  }
                />
              </Col>
            </Row>
          </Container>
        </section>
      ) : null} */}

      {role === ROLES.exhibitor && (
        <>
          <section className="section-small-medium">
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <h2 className="h2 title">Offers</h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <div className="offer-slider-container">
                    <OfferSlider
                      slidesData={
                        homeInfos.data ? homeInfos.data[0].bestProductList : []
                      }
                      autoplay={true}
                      slidesNumberDesktop={4}
                      SlideComponent={OfferCard}
                      canView={false}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section-small">
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <h2 className="h2 title">Browse by category</h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <CategoryList />
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}

      {role !== ROLES.exhibitor && (
        <>
          <section className="section-small-medium">
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <h2 className="h2 title">Offers</h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <div className="offer-slider-container">
                    <OfferSlider
                      slidesData={
                        homeInfos.data ? homeInfos.data[0].bestProductList : []
                      }
                      autoplay={true}
                      slidesNumberDesktop={4}
                      SlideComponent={OfferCard}
                      canView={true}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="news-feed-section">
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <h2 className="h2 title">NEWS Feed</h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <NewsFeedList
                    hideLogo={true}
                    lifestyles={
                      homeInfos.data ? homeInfos.data[0].bestProducts : []
                    }
                    canView={true}
                    onLikeLifestyle={(id) => onLikeLifestyle.mutate({ id: id })}
                    onUnlikeLifestyle={(id) =>
                      onUnlikeLifestyle.mutate({ id: id })
                    }
                  />
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}

      {role === ROLES.exhibitor &&
      <section className="home-logo-list">
        <Container>
          <Row>
            <Col lg={{span: 10, offset: 1}}>
              <LogoGroup />
            </Col>
          </Row>
        </Container>
      </section>}
    </Wrapper>
  );
});

export default withRouter(Home);
