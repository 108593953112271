import React, { useEffect, useState } from "react";
import CategoryHeader from "./categoryHeader";
import { withRouter } from "react-router-dom";
import ExhibitorList from "./exhibitorList";
import Wrapper from "../wrapper";
import { useAuthContext } from "../../../store/auth";
import NewsFeedCardSlider from "../../ui/newsFeedCardSlider";
import get from "lodash.get";
import tracking from "../../../utils/tracking";
import CategoryList from "../home/categoryList";
import { ROLES } from "../../../constants/roles";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../../layout/loader";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getCategories, getPopularLifestyles, likeLifestyle, unlikeLifestyle } from "../../../network/API";
import NewsFeedCard from "../../ui/newsFeedCardSlider/newsFeedCard";

const CategoryPage = (props) => {

  const queryClient = useQueryClient();

  const [category, setCategory] = useState();
  const { role, userAuthed } = useAuthContext();

  const pathname = props.history.location.pathname;

  const lifestyles = useQuery(["getLifestyles", {category: category?.id}], getPopularLifestyles, {
    enabled: !!category,
    refetchOnWindowFocus: false,
  });

  const categories = useQuery(["getCategories"], getCategories, {
    enabled: !!userAuthed,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const slug = pathname.split("/")[2];
      for (const cat of data) {
        if (cat.slug === slug) {
          setCategory(cat);
          return;
        }
        if (cat.subcategories?.length) {
          for (const subCat of cat.subcategories) {
            if (subCat.slug === slug) {
              setCategory(subCat);
              return;
            }
          }
        }
      }
      props.history.push("/notfound");
    },
  });

  const onLikeLifestyle = useMutation(
    (values) => likeLifestyle(values.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getLifestyles");
      }
    }
  );

  const onUnlikeLifestyle = useMutation(
    (values) => unlikeLifestyle(values.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getLifestyles");
      }
    }
  );

  useEffect(() => {
    if (!categories.data) return;
    const slug = pathname.split("/")[2];
    tracking.pageView(`/${slug}`);
    for (const cat of categories.data) {
      if (cat.slug === slug) {
        setCategory(cat);
        return;
      }
      if (cat.subcategories?.length) {
        for (const subCat of cat.subcategories) {
          if (subCat.slug === slug) {
            setCategory(subCat);
            return;
          }
        }
      }
    }
    props.history.push("/notfound");
  }, [pathname]);

  // const exhibitors = useQuery(
  //   ["getBrandsBySearch", { category: category?.id }],
  //   getBrandsBySearch,
  //   {
  //     enabled: !!category,
  //   }
  // );

  // const categories = useQuery(["getCategories"], getCategories, {
  //   onSuccess: (data) => {},
  // });

  // useEffect(() => {
  //   setLoading(true);
  //   let slugs = [];
  //   const currentSlug = getNthItemFromPathname(pathname, 2);
  //   tracking.pageView(props.location.pathname);
  //   if (categories && categories.length > 0) {
  //     slugs = categories.map((c) => c.slug);
  //   }
  //   if (slugs && slugs.length > 0 && slugs.indexOf(currentSlug) < 0) {
  //     // if the url does not contain a valid category redirect to 404 page
  //     props.history.push("/notfound");
  //   } else {
  //     if (categoryData) {
  //       if (subcategory) {
  //         // if category does not contain the subcategory redirect to 404
  //         const subcategoryIndex = categoryData.subcategories
  //           .map((s) => s.slug)
  //           .indexOf(subcategory);
  //         if (subcategoryIndex === -1) {
  //           props.history.push("/notfound");
  //         } else {
  //           getBrandsByCategory(
  //             categoryData.subcategories[subcategoryIndex].id,
  //             1,
  //             "subcategory"
  //           );
  //         }
  //       } else {
  //         getBrandsByCategory(categoryData.id, 1);
  //       }
  //     }
  //   }
  // }, [categories, pathname, props.history]);

  // const getNameOfSubcategory = (slug) => {
  //   if (!categoryData) return "";

  //   const subcategoryIndex = categoryData.subcategories
  //     .map((s) => s.slug)
  //     .indexOf(slug);
  //   return categoryData.subcategories[subcategoryIndex];
  // };
  // let image;
  // let icon;
  // let description;
  // if (subcategory) {
  //   const sc = getNameOfSubcategory(subcategory);
  //   image = get(sc, "background.url", "");
  //   icon = get(sc, "icon.url", "");
  //   description = get(sc, "description", "");
  // }
  return (
    <>
      <Loader show={lifestyles.isLoading} />
      <Wrapper>
        <CategoryHeader
          name={get(category, "name", "")}
          back={
            category?.category
              ? categories?.data.find((e) => e.id === category?.category)
              : null
          }
          image={get(
            category,
            "background.url",
            `/assets/images/bg420-${get(category, "slug", "")}.jpg`
          )}
          description={get(category, "description", "")}
        />
        <section className="section background-grey">
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                <h2 className="h2 title category-title">Hyatt preferred brands</h2>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                {/* {subcategory && (
                  <ExhibitorList
                    exhibitors={displayedBrands}
                    canView={role !== ROLES.exhibitor}
                  />
                )}
                {!subcategory &&
                  categoryData &&
                  categoryData.subcategories.length === 0 && (
                    <ExhibitorList
                      exhibitors={displayedBrands}
                      canView={true}
                    />
                  )}
                {!subcategory &&
                  categoryData &&
                  categoryData.subcategories.length > 0 && (
                    <CategoryList
                      subcategories={categoryData.subcategories}
                      parentCategory={category}
                    />
                  )} */}
                {category?.subcategories &&
                category?.subcategories?.length > 0 ? (
                  <CategoryList
                    subcategories={category.subcategories}
                    parentCategory={category.id}
                  />
                ) : (
                  <ExhibitorList
                    category={category?.category ? null : category?.id}
                    subcategory={category?.category ? category?.id : null}
                    canView={role !== ROLES.exhibitor}
                    redirectTo={() =>
                      props.history.push({
                        pathname: "/search",
                        search: `?${category?.category ? "sub" : ""}category=${
                          category?.id
                        }`,
                      })
                    }
                    previous={{
                      to: "/category/" + get(category, "slug", ""),
                      name: get(category, "name", ""),
                    }}
                    text="More exhibitors and brands are coming, stay tuned !"
                  />
                )}
              </Col>
            </Row>
          </Container>
        </section>
     
        {/* {role !== ROLES.exhibitor &&
          !(category?.subcategories && category?.subcategories?.length > 0) && (
            <section className="section-medium background-default">
              <Container>
                <Row>
                  <Col lg={{ span: 10, offset: 1 }}>
                    <h2 className="h2 title">Latest videos from exhibitors</h2>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col lg={{ span: 10, offset: 1 }}>
                    <CardSlider
                      slidesData={
                        similarVideos.data
                          ? similarVideos?.data?.map((ex) => {
                              const rnd = Math.floor(
                                Math.random() * (ex?.videos?.length - 0) + 0
                              );

                              const vid = ex.videos[rnd];

                              return {
                                thumbnail: vid?.thumbnail,
                                videoId: vid.video.uri,
                                id: ex._id,
                                name: vid?.name
                                  ? vid?.name
                                  : `${ex.name} video`,
                                subtitle: ex.name,
                              };
                            })
                          : []
                      }
                      slidesNumberDesktop={4}
                      handleSlideClick={(id, videoId) =>
                        props.history.push({
                          pathname: `/exhibit/${id}`,
                          previous: {
                            to: "/category/" + category?.slug,
                            name: category?.name,
                          },
                          defaultVideo: videoId,
                        })
                      }
                      SlideComponent={VideoCard}
                      video
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )} */}

          {role !== ROLES.exhibitor &&
          !(category?.subcategories && category?.subcategories?.length > 0) && (
            <section className="news-feed-section background-default">
              <Container>
                <Row>
                  <Col lg={{ span: 10, offset: 1 }}>
                    <h2 className="h2 title news-feed-title">NEWS Feed</h2>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col lg={{ span: 10, offset: 1 }}>
                    <NewsFeedCardSlider
                      slidesData={
                        lifestyles?.data
                          ? lifestyles.data?.map((ex) => {
                              return {
                                name: ex?.name,
                                uri: ex?.lifestyle?.uri,
                                id: ex._id,
                                exhibitor: ex.exhibitor,
                                uploadedAt: ex?.uploadedAt,
                                likeCount: ex?.likeCount || 0,
                                isLiked: ex?.likedByUser ? ex?.likedByUser : false,
                                brandName: ex?.exhibitorData.name,
                                brandLogo: ex?.exhibitorData.logo,
                                isVideo: ex?.lifestyle?.type.includes('video')
                              };
                            })
                          : []
                      }
                      slidesNumberDesktop={3}
                      handleSlideClick={(id, videoId) =>
                        props.history.push({
                          pathname: `/exhibit/${id}`,
                          previous: {
                            to: "/category/" + category?.slug,
                            name: category?.name,
                          },
                          defaultVideo: videoId,
                        })
                      }
                      onClickLike={(id) => onLikeLifestyle.mutate({id: id})}
                      onClickUnlike={(id) => onUnlikeLifestyle.mutate({id: id})}
                      SlideComponent={NewsFeedCard}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
      </Wrapper>
    </>
  );
};

export default withRouter(CategoryPage);
