import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../ui/button";
import Cropper from "react-easy-crop";
import Input from "../../ui/input";
import TagSelect from "../../ui/select";
import Textarea from "../../ui/textarea";
import MediaWrapper from "../../layout/mediaWrapper";
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import get from "lodash.get";
import { useQuery, useMutation } from "react-query";
import { ROLES } from "../../../constants/roles";
import { useAuthContext } from "../../../store/auth";
import { baseURL, getCategories, getTags } from "../../../network/API";
import { uploadBackground, uploadLogo } from "../../../network/Upload";
import { getCroppedImg, base64ToFile } from "../../../utils";
import { withRouter } from "react-router";

const Title = withRouter((props) => {
  const { role } = useAuthContext();
  const [hoverFollow, setHoverFollow] = useState(false);

  const categories = useQuery(["getCategories"], getCategories, {
    refetchOnWindowFocus: false,
  });
  const tags = useQuery(
    [
      "getTags",
      {
        category: props.category?.id || undefined,
        subcategory: props.subcategory?.id || undefined,
      },
    ],
    getTags,
    {
      refetchOnWindowFocus: false,
    }
  );
  const onUploadIconFile = useMutation((values) => uploadLogo(values), {
    onSuccess: (data) => onEdit({ logo: data }),
  });

  const onEdit = (value) => {
    props.onBoothEdited(value);
  };

  const handleFiles = async (event, type) => {
    const formData = new FormData();
    formData.append("files", event.target.files[0]);

    onUploadIconFile.mutate(formData);
  };

  const getCategoryBackground = (img, categories) => {
    if (img) {
      return `${baseURL}/storage/background/${img}`;
    }

    if (categories) {
      for (const cat of categories) {
        if (props.category?.id === cat.id) {
          if (props.subcategory?.id && cat) {
            for (const sub of cat.subcategories) {
              if (sub.id === props.subcategory?.id) {
                return sub.background?.url;
              }
            }
          }
          return `/assets/images/bg420-${get(cat, "slug", "")}.jpg`;
        }
      }
    }
    return "/assets/images/bg-default-book.png";
  };

  const getPartnerLogo = (img) => {
    if (img) {
      return `${baseURL}/storage/partner_logo/${img}`;
    }
    return "/assets/images/bg-default-partner.png";
  };

  return props.editMode ? (
    <>
      <div className="exhibit-title">
        <Container>
          <Row>
            <Col xl={{ span: 10, offset: 1 }}>
              <ModalCrop
                onEdit={onEdit}
                url={getCategoryBackground(props.background, categories.data)}
              />

              <div className="exhibit-title__infos">
                <div>
                  <div className="exhibit-title__logo">
                    <MediaWrapper
                      square
                      src={
                        props.logo
                          ? `${baseURL}/storage/logo/${props.logo}`
                          : "/assets/images/placeholderLogo.png"
                      }
                    />
                    <label className="exhibit-title__logo-edit icon-edit">
                      <input
                        type="file"
                        id="uploadBackground"
                        onChange={(e) => handleFiles(e, "logo")}
                        formats="jpg, jpeg, png (50mb max)"
                        fileSize={50}
                        accept=".jpg,.jpeg,.png"
                      />
                    </label>
                    {props.errors?.logo && (
                      <p className="text-error">{props.errors.logo}</p>
                    )}
                  </div>
                  <div className="exhibit-title__wrap">
                    <Input
                      value={props.title}
                      fullwidth
                      onChange={(e) => onEdit({ name: e.target.value })}
                      error={props.errors?.name}
                      errorMessage={props.errors?.name}
                    />
                    <TagSelect
                      options={[
                        ...(!props.category
                          ? [{ name: "Choose a category..." }]
                          : []),
                        ...get(categories, "data", []).map((cat) => ({
                          name: cat.name,
                          value: cat.id,
                        })),
                      ]}
                      value={get(props, "category.id", "")}
                      onChange={(e) =>
                        onEdit({
                          category: { id: e.target.value },
                          subcategory: null,
                          tags: [],
                        })
                      }
                      error={props.errors?.category}
                      errorMessage={props.errors?.category}
                    />
                    <TagSelect
                      options={[
                        { name: "None", value: undefined },
                        ...get(
                          get(categories, "data", []).find(
                            (e) => e.id === get(props, "category.id", "")
                          ),
                          "subcategories",
                          []
                        ).map((e) => ({
                          name: e.name,
                          value: e.id,
                        })),
                      ]}
                      value={get(props, "subcategory.id", "")}
                      onChange={(e) => {
                        const subid =
                          e.target.value === "None" ? null : e.target.value;
                        onEdit({ subcategory: { id: subid }, tags: [] });
                      }}
                    />
                    <div className="exhibit-title__desc">
                      <Textarea
                        value={props.description}
                        rows="3"
                        fullwidth
                        onChange={(e) =>
                          onEdit({ description: e.target.value })
                        }
                        error={props.errors?.description}
                        errorMessage={props.errors?.description}
                      />
                      <div
                        className={
                          props.description?.length > 85
                            ? "exhibit-title__desc-error text-error"
                            : "exhibit-title__desc-error"
                        }
                      >
                        {props.description ? props.description?.length : "0"}
                        /85
                      </div>
                    </div>
                    <Input
                      placeholder="Company website URL"
                      fullwidth
                      value={props.website}
                      onChange={(e) => {
                        onEdit({ website_url: e.target.value });
                      }}
                      error={props.errors?.website_url}
                      errorMessage={props.errors?.website_url}
                    />
                    <Select
                      value={get(props, "tags", []).map((e) => {
                        if (e !== null) {
                          return {
                            value: e?.id,
                            label: e?.name,
                          };
                        }
                        return "";
                      })}
                      onChange={(e) =>
                        onEdit({
                          tags: e.map((t) => ({ id: t.value, name: t.label })),
                        })
                      }
                      isMulti
                      name="products"
                      options={get(tags, "data", []).map((e) => ({
                        value: e?.id,
                        label: e?.name,
                      }))}
                      className="multi-select"
                      classNamePrefix="select"
                      placeholder="Choose tags"
                    />
                    <div className="exhibitor-partner">
                      <div
                        className="partner__background"
                        style={{
                          backgroundImage:
                            "url(" +
                            getPartnerLogo(get(props, "partner.logo.uri", "")) +
                            ")",
                        }}
                      />
                      <div className="partner__name">
                        {get(props, "partner.name", "")}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="exhibit-title__buttons">
                    <Button
                      theme="primary"
                      // className="exhibit-title__cancel"
                      onClick={props.onClickCancel}
                    >
                      Cancel modifications
                    </Button>
                    <Button
                      theme="primary"
                      // className="exhibit-title__edit"
                      onClick={props.onClickSave}
                      disabled={
                        props?.errors
                          ? Object.keys(props.errors)?.length !== 0
                          : false
                      }
                    >
                      Save modifications
                    </Button>
                  </div>
                  <div className="exhibitor-title__countries">
                    {props.countries && props.countries.length > 0 && (
                      <div className="exhibitor-title__available-in">
                        Available in:{" "}
                        <div className="exhibitor-title__country">
                          {props.countries.map((country) => {
                            return (
                              <>
                                <span className="country">{country.name}</span>
                                <span className="country-divider">|</span>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="divider" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  ) : (
    <div className="exhibit-title">
      <Container>
        <Row>
          <Col xl={{ span: 10, offset: 1 }}>
            {props.previous && (
              <Link to={props.previous.to} className="exhibit-title__back">
                <span className="icon-arrow-left exhibit-title__back-icon" />
                <span>Back</span>
              </Link>
            )}

            <div
              className="banner-booth"
              style={{
                backgroundImage: `url(${getCategoryBackground(
                  props.background,
                  categories.data
                )})`,
              }}
            ></div>

            <div className="exhibit-title__infos">
              <div>
                <div className="exhibit-title__logo preview">
                  <MediaWrapper
                    square
                    src={`${baseURL}/storage/logo/${props.logo}`}
                  />
                </div>
                <h1 className="exhibit-title__title">
                  {props.title || props.name}
                </h1>

                {props.hideTag !== true && (
                  <ul className="exhibit-title__tags">
                    {props.tags.map((tag, index) => (
                      <li key={index}>
                        {tag?.immutable ? (
                          <div
                            className="organic-tag-container"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.history.push({
                                pathname: "/search",
                                search: "?q=" + tag.name,
                                needUpdate: true,
                              });
                            }}
                          >
                            <img
                              className="organic-icon"
                              src="/assets/images/organic-icon.png"
                              alt="organic"
                            />
                            <div className="organic-tag">{tag?.name}</div>
                          </div>
                        ) : (
                          <div
                            className="tag"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.history.push({
                                pathname: "/search",
                                search: "?q=" + tag.name,
                                needUpdate: true,
                              });
                            }}
                          >
                            {tag?.name}
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                )}

                <p>{props.description}</p>

                {props?.website && (
                  <a
                    href={get(props, "website", "")}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="exhibitor__site"
                  >
                    {props.website.replace(/^https?:\/\//, "")}
                  </a>
                )}

                <div
                  className="exhibitor-partner exhibitor-partner-link"
                  onClick={() => {
                    if (role !== ROLES.exhibitor) {
                      props.history.push({
                        pathname: `/partner/${props?.partner.id}`,
                        previous: props.previous
                          ? props.previous
                          : { to: `/exhibit/${props.boothId}`, name: "Brand" },
                      });
                    }
                  }}
                >
                  <div
                    className="partner__background"
                    style={{
                      backgroundImage:
                        "url(" +
                        getPartnerLogo(get(props, "partner.logo.uri", "")) +
                        ")",
                    }}
                  />
                  <div className="partner__name">
                    {get(props, "partner.name", "")}
                  </div>
                </div>
              </div>
              <div>
                {role !== ROLES.exhibitor && (
                  <div className="exhibit-title__actions">
                    <Button
                      onMouseEnter={() => setHoverFollow(true)}
                      onMouseLeave={() => setHoverFollow(false)}
                      onClick={() =>
                        !props.isFollow
                          ? props.onClickFollow(true)
                          : props.onClickFollow(false)
                      }
                      theme="primary"
                      icon={
                        props.isFollow
                          ? hoverFollow
                            ? "Vector"
                            : "star-fill"
                          : "star"
                      }
                      iconColor={
                        props.isFollow
                          ? hoverFollow
                            ? "#ffffff"
                            : "#FFBF00"
                          : "#ffffff"
                      }
                      style={{ minWidth: 135 }}
                    >
                      {props.isFollow
                        ? hoverFollow
                          ? "Unfollow"
                          : "Following"
                        : "Follow"}
                    </Button>
                    <Button
                      theme="primary"
                      onClick={props.onClickRequestMeeting}
                    >
                      Request a meeting
                    </Button>
                  </div>
                )}
                {role === ROLES.exhibitor && (
                  <div className="exhibit-title__buttons">
                    <Button
                      theme="primary"
                      className="exhibit-title__edit"
                      onClick={props.onClickEdit}
                    >
                      Edit my page
                    </Button>
                  </div>
                )}
                <div className="exhibitor-title__countries">
                  {props.countries && props.countries.length > 0 && (
                    <div className="exhibitor-title__available-in">
                      Available in:{" "}
                      <div className="exhibitor-title__country">
                        {props.countries.map((country) => {
                          return (
                            <>
                              <span className="country">{country.name}</span>
                              <span className="country-divider">|</span>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="divider" />
          </Col>
        </Row>
      </Container>
    </div>
  );
});

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const DEF_H = 500;
const DEF_W = 1159;
const CROP_AREA_ASPECT = DEF_W / DEF_H;

const ModalCrop = ({ onEdit, url }) => {
  // const [imageSrc, setImageSrc] = useState(null);
  // const [crop, setCrop] = useState({ x: 0, y: 0 });
  // // const [rotation, setRotation] = useState(0);
  // const [zoom, setZoom] = useState(1);
  // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  // const [HEIGHT, SET_HEIGHT] = useState(DEF_H / 1.25);
  // const [WIDTH, SET_WIDTH] = useState(DEF_W / 1.25);

  // const [croppedImage, setCroppedImage] = useState(null);
  //

  //   useEffect(() => {
  //   function handleResize() {
  //     if (window.innerWidth < 1035) {
  //       const W = window.innerWidth - 150
  //       const H = (window.innerWidth - (window.innerWidth < 768 ? 134 : 150)) / CROP_AREA_ASPECT
  //       console.log("resize", {
  //         height: H,
  //         width: W
  //       });

  //       SET_HEIGHT(H);
  //       SET_WIDTH(W);
  //     } else if (WIDTH < DEF_W / 1.25) {
  //       SET_HEIGHT(DEF_H / 1.25);
  //       SET_WIDTH(DEF_W / 1.25);
  //     }
  //   }
  //   handleResize();
  //   window.addEventListener('resize', handleResize)
  // })
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const closeBgModal = () => {
    setImageSrc(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);
    }
  };

  const sendCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);

      const formData = new FormData();
      formData.append("files", base64ToFile(croppedImage, "banner.jpeg"));

      onUploadBGFile.mutate(formData);
    } catch (e) {
      closeBgModal();
      console.error(e);
    }
  };

  const onUploadBGFile = useMutation((values) => uploadBackground(values), {
    onSuccess: (data) => {
      onEdit({ background: data });
      closeBgModal();
    },
  });

  if (imageSrc) {
    return (
      <>
        <div className="banner-booth">
          <Cropper
            image={imageSrc}
            crop={crop}
            // rotation={rotation}
            zoom={zoom}
            aspect={CROP_AREA_ASPECT}
            // cropSize={{ width: 300, height: 300 }}
            onCropChange={setCrop}
            // onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          <div className="cropper-buttons">
            <div></div>
            <Button
              tag="label"
              theme="primary"
              // className="exhibit-title__edit-bg"
              onClick={sendCroppedImage}
              disabled={onUploadBGFile.isLoading}
            >
              {onUploadBGFile.isLoading ? "Sending..." : "Save"}
            </Button>
            <Button
              tag="label"
              theme="primary"
              // className="exhibit-title__edit-bg"
              onClick={closeBgModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      className="banner-booth"
      style={{
        backgroundImage: `url(${url})`,
      }}
    >
      <Button
        tag="label"
        htmlFor="uploadBackground"
        theme="primary"
        className="exhibit-title__edit-bg"
      >
        Edit background picture
      </Button>
      <input
        type="file"
        id="uploadBackground"
        onChange={onFileChange}
        // onChange={toggleBgModal}
        formats="jpg, jpeg, png (50mb max)"
        fileSize={50}
        accept=".jpg,.jpeg,.png"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default Title;
