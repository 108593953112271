import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { SmallCenteredDivider } from "../../layout/divider";
import TruncateMarkup from "react-truncate-markup";

const ThumbnailUploader = (props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      props.callback(acceptedFiles, props.type, props.parent);
    },
    [props]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: props.accept,
    multiple: props.multiple,
    maxFiles: props.maxFiles,
  });

  return (
    <span {...getRootProps()} onClick={(e) => e.stopPropagation()}>
      <input {...getInputProps()} />
      <button
        className="blue-button thumbnail-button"
        onClick={() => open()}
        disabled={props.maxFiles === 0 || props.disable}
      >
        {props.text}
      </button>
    </span>
  );
};

const getThumbnailForAsset = (props) => {
  for (const i in props.boothData.video_thumbmnail_links) {
    if (props.text === props.boothData.video_thumbmnail_links[i].video) {
      return props.boothData.video_thumbmnail_links[i].thumbnail;
    }
  }

  return null;
};

export const ImageVideoTagDisplay = (props) => {
  const { type, text, onClick, callback, boothData } = props;

  return (
    <div>
      <div className="tag-display">
        <div className="tag-display__text">
          <>
            <TruncateMarkup lines={1}>
              <div>{props.text}</div>
            </TruncateMarkup>
          </>
        </div>
        <img
          className="tag-display__icon"
          src="/assets/images/icon-trash.png"
          alt="icon-close-white"
          onClick={() => onClick(type, text)}
        />
      </div>

      {(props.text.toLowerCase().endsWith(".mp4") ||
        props.text.toLowerCase().endsWith(".avi") ||
        props.text.toLowerCase().endsWith(".flv")) && (
        <div className="tag-display">
          {getThumbnailForAsset(props) && (
            <>
              <div className="tag-display__text">
                <TruncateMarkup lines={1}>
                  <div>{getThumbnailForAsset(props)}</div>
                </TruncateMarkup>
              </div>
              <img
                className="tag-display__icon"
                src="/assets/images/icon-trash.png"
                alt="icon-close-white"
                onClick={() =>
                  onClick("thumbnails", getThumbnailForAsset(props))
                }
              />
            </>
          )}
          {!getThumbnailForAsset(props) && (
            <ThumbnailUploader
              text="Upload video thumbnail"
              callback={callback}
              type="thumbnails"
              parent={props.text}
              multiple
              accept={["image/jpg", "image/jpeg", "image/png", "image/tiff"]}
              maxFiles={1}
              helpText="(only 1, up to 50MB)"
            />
          )}
        </div>
      )}
      {(props.text.toLowerCase().endsWith(".mp4") ||
        props.text.toLowerCase().endsWith(".avi") ||
        props.text.toLowerCase().endsWith(".flv")) &&
        getThumbnailForAsset(props) && (
          <div className="tag-display">
            <p className="tag-legend help-text">(video thumbnail)</p>
          </div>
        )}
      <SmallCenteredDivider />
    </div>
  );
};
export default ImageVideoTagDisplay;
