import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getPartnersSuggest } from "../../../network/API";
import InputSearchAgolia from "../../ui/inputSearchAgolia";

const SearchHeader = ({
  image,
  name,
  inputValue,
  onInputChange,
  onEnterPressed,
  onClearSearch,
  initialSearchType,
}) => {
  const [searchType, setSearchType] = useState(initialSearchType);
  useEffect(() => {
    if (initialSearchType) {
      setSearchType(initialSearchType);
    }
  }, [initialSearchType])

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onEnterPressed(inputValue);
    }
  };

  const onSearchClicked = (suggestion) => onEnterPressed(suggestion);

  const fetchData = async (value) => {
    const data = await getPartnersSuggest(value);
    let res = [];
    data.forEach((value, index) => {
      res.push({
        label: value,
        value: value
      });
    });
    return res;
}

  return (
    <>
      <div
        className="page-head search-header"
        style={{ backgroundImage: "url(" + image + ")" }}
      >
        <Container>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <h1 className="h1 page-head-title">{name}</h1>

              <InputSearchAgolia
                placeholder="Find the best supplier and products for your hotel"
                size="lg"
                searchType={searchType}
                value={inputValue}
                onChange={onInputChange}
                onSearch={onSearchClicked}
                onKeyDown={handleKeyDown}
                fetchData={fetchData}
                onClear={onClearSearch}
              />
              
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
};

export default SearchHeader;
