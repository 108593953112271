import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useQuery } from "react-query";
import { getBrandsSuggest, getHyattTags } from "../../../network/API";
import InputSearchAgolia from "../../ui/inputSearchAgolia";

const SearchHeader = ({
  image,
  name,
  inputValue,
  onInputChange,
  hyattTags,
  onDeletehyattTag,
  tagList,
  onDeleteTag,
  onEnterPressed,
  toggleFilterMenu,
  isShowingMenu,
  onSearchTypeChange,
  initialSearchType,
  onClearTag,
  onClearSearch,
  onClearSelectedCategory,
}) => {
  const [searchType, setSearchType] = useState(initialSearchType);
  useEffect(() => {
    if (initialSearchType) {
      setSearchType(initialSearchType);
    }
  }, [initialSearchType]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onEnterPressed(inputValue);
    }
  };
  const tags = useQuery(["getHyattTags"], getHyattTags, {
    refetchOnWindowFocus: false,
    enabled: !!hyattTags,
  });

  const onSearchClicked = (suggestion) => onEnterPressed(suggestion);

  const handleFiltersMenuToggle = () => {
    toggleFilterMenu(!isShowingMenu);
  };

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
    onSearchTypeChange(type);
  };

  const handleClearTags = () => {
    onClearTag();
  };

  const fetchData = async (value) => {
    const data = await getBrandsSuggest(value);
    let res = [];
    data.forEach((value, index) => {
      res.push({
        label: value,
        value: value,
      });
    });
    return res;
  };

  return (
    <>
      <div
        className="page-head search-header"
        style={{ backgroundImage: "url(" + image + ")" }}
      >
        <Container>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <h1 className="h1 page-head-title">{name}</h1>

              <InputSearchAgolia
                placeholder="Find the best supplier and products for your hotel"
                size="lg"
                searchType={searchType}
                onFocus={onClearSelectedCategory}
                onSearchTypeChange={handleSearchTypeChange}
                value={inputValue}
                onChange={onInputChange}
                onSearch={onSearchClicked}
                onKeyDown={handleKeyDown}
                fetchData={fetchData}
                onClear={onClearSearch}
              />

              {/* <InputSearch
                placeholder="Find the best supplier and products for your hotel"
                size="lg"
                searchType={searchType}
                onSearchTypeChange={handleSearchTypeChange}
                value={inputValue}
                onChange={onInputChange}
                onSearch={onSearchClicked}
                onKeyDown={handleKeyDown}
              />
              <div className="category-tag-container">
                <ul className="category-taglist">
                  {hyattTags && (
                    <li className="category-tag">
                      <span>
                        {tags?.data?.find((e) => e.id === hyattTags)?.name ||
                          "Tag"}
                      </span>
                      <button
                        onClick={() => onDeletehyattTag()}
                        className="icon-x"
                      />
                    </li>
                  )}
                  {tagList.map((tag) => (
                    <li key={tag} className="category-tag">
                      <span>{tag}</span>
                      <button
                        onClick={() => onDeleteTag(tag)}
                        className="icon-x"
                      />
                    </li>
                  ))}
                </ul>
                {tagList?.length > 0 && (
                  <ul className="clear-tag-container">
                    <li className="clear-tag" onClick={handleClearTags}>
                      <span>Clear all</span>
                    </li>
                  </ul>
                )}
              </div> */}
            </Col>
          </Row>
        </Container>
        <button
          className="category-filter-button"
          onClick={handleFiltersMenuToggle}
        >
          <span>Filters</span>
          <span className="icon-filter" />
        </button>
      </div>
      <Container className="background-muted">
        <div className="category-tag-container">
          <ul className="category-taglist-mobile">
            {hyattTags && (
              <li className="category-tag">
                <span>
                  {tags?.data?.find((e) => e.id === hyattTags)?.name || "Tag"}
                </span>
                <button onClick={() => onDeletehyattTag()} className="icon-x" />
              </li>
            )}
            {tagList.map((tag) => (
              <li key={tag} className="category-tag">
                <span>{tag}</span>
                <button onClick={() => onDeleteTag(tag)} className="icon-x" />
              </li>
            ))}
          </ul>
          {tagList?.length > 0 && (
            <ul className="clear-tag-container-mobile">
              <li className="clear-tag" onClick={handleClearTags}>
                <span>Clear all</span>
              </li>
            </ul>
          )}
        </div>
      </Container>
    </>
  );
};

export default SearchHeader;
