import React from "react";
import "./LogoGroup.scss";

const LogoGroup = () => {
  return (
    <div className="home-logo-list-wrapper">
      <div className="home-logo-list-container">
        <div className="line-1">
          <div className="group group-1">
            <div className="group-name">
              <div className="group-name__text">timeless collection</div>
            </div>
            <div className="group-logos">
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/brands/park-hyatt">
                <img
                  src="/assets/logos/Timeliness-collection/Park Hyatt-logo.png"
                  alt=""
                />
              </a>
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/brands/grand-hyatt">
                <img
                  src="/assets/logos/Timeliness-collection/gh-logo.png"
                  alt=""
                />
              </a>
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/hyatt-regency">
                <img
                  src="/assets/logos/Timeliness-collection/hr-logo.png"
                  alt=""
                />
              </a>
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/brands/hyatt-place">
                <img
                  src="/assets/logos/Timeliness-collection/hyatt-place.png"
                  alt=""
                />
              </a>
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/brands/hyatt-house">
                <img
                  src="/assets/logos/Timeliness-collection/hyatt-house.png"
                  alt=""
                />
              </a>
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/brands/urcove">
                <img
                  src="/assets/logos/Timeliness-collection/UrCove-logo.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="line-2">
          <div className="group group-2">
            <div className="group-name">
              <div className="group-name__text">BOUNDLESS COLLECTION</div>
            </div>
            <div className="group-logos">
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/brands/alila-hotels-and-resorts">
                <img
                  src="/assets/logos/Boundless-collection/Alila-logo.png"
                  alt=""
                />
              </a>
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/andaz">
                <img
                  src="/assets/logos/Boundless-collection/andaz.png"
                  alt=""
                />
              </a>
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/brands/hyatt-centric">
                <img
                  src="/assets/logos/Boundless-collection/Hyatt-Centric-Logo.png"
                  alt=""
                />
              </a>
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/zh-CN/brands/caption-by-hyatt">
                <img
                  src="/assets/logos/Boundless-collection/Caption.svg"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="group group-3">
            <div className="group-name">
              <div className="group-name__text">Independent collection</div>
            </div>
            <div className="group-logos">
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/brands/unbound-collection">
                <img
                  className="group-logos__logo"
                  src="/assets/logos/Independent-collection/Unbound-logo.png"
                  alt=""
                />
              </a>
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/brands/jdv-by-hyatt">
                <img
                  src="/assets/logos/Independent-collection/jvd-by-hyatt.png"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="group group-4">
            <div className="group-name">
              <div className="group-name__text">INCLUSIVE COLLECTION</div>
            </div>
            <div className="group-logos">
              <a target="__blank" className="group-logos__logo" href="https://www.hyatt.com/destinations/dreams?icamp=woh_dreams_brandexplorer">
                <img
                  src="/assets/logos/Inclusive-collection/Dreams Logo.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoGroup;
