import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import config from "./config";
import { getNthItemFromPathname } from "../../../utils";
import Wrapper from "../wrapper";
import Information from "./information";
import Edit from "./edit";
import Button from "../../ui/button";
import Input from "../../ui/input";
import Password from "./password";
import { useDataContext } from "../../../store/data";
import tracking from "../../../utils/tracking";
import { Container, Row, Col } from "react-bootstrap";
import BrandCard from "../../ui/brandCard";
import { ROLES } from "../../../constants/roles";
import { useAuthContext } from "../../../store/auth";
import get from "lodash/get";
import {
  getMyBooths,
  changeInfos,
  changePassword,
  getMyStats,
  getBrands,
} from "../../../network/API";
import { useQuery, useMutation } from "react-query";
import { Formik, Form } from "formik";
import { useModal } from "../../../store/hooks/useModal";
import Modal from "../../ui/popup";

const Account = (props) => {
  const [mode, setMode] = useState(config.information);
  const pathname = props.history.location.pathname;

  useEffect(() => {
    tracking.pageView("/myaccount");
    const menuOption = getNthItemFromPathname(pathname, 2);
    if (menuOption) {
      setMode(config[menuOption]);
    }
  }, [pathname]);

  // useEffect(() => {
  //   setLoading(false);
  //   // getMyBooth()
  //   tracking.pageView("/myaccount");
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 100);
  //   props.history.push(`/myaccount/brands`);
  // }, []);

  return (
    <Wrapper>
      <TopNav />

      <section className="section section-account background-muted">
        <Container>
          <Row>
            {mode.name === "brands" && <BrandTab />}
            {mode.name === "stats" && <StatsTab />}
            {mode.name === "settings" && <SettingsTab />}
            {/* <Col>
              <div className="alert">
                Your subscription will expire in one month. Please contact{" "}
                <a href="mailto:perry.chung@hyatt.com" className="link">
                  Hyatt Asia-Pacific
                </a>{" "}
                to renew it.
              </div>
            </Col> */}
            {/* <Col>
              <div className="alert">
                Your subscription has expired. Your account and virtual booth
                has been temporarily disabled. Please contact{" "}
                <a href="mailto:perry.chung@hyatt.com" className="link">
                  Hyatt Asia-Pacific
                </a>{" "}
                to renew it.
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>

      {/* {mode.name === "editinfo" && <Edit />}
      {mode.name === "password" && <Password />} */}
    </Wrapper>
  );
};

const TopNav = withRouter((props) => {
  const menuHandler = (modeName) => {
    props.history.push(`/myaccount/${modeName}`);
  };

  return (
    <>
      <Information
        name={localStorage.getItem("name") || ROLES.exhibitor}
        email={localStorage.getItem("email") || "email"}
        menuHandler={menuHandler}
      />
      <div className="account-links text-center">
        <NavLink
          to="/myaccount/brands"
          className="link-dot"
          activeClassName="selected"
          onClick={() => menuHandler("brands")}
        >
          My brands
        </NavLink>
        <NavLink
          to="/myaccount/stats"
          className="link-dot"
          activeClassName="selected"
          onClick={() => menuHandler("stats")}
        >
          Statistics
        </NavLink>
        <NavLink
          to="/myaccount/settings"
          className="link-dot"
          activeClassName="selected"
          onClick={() => menuHandler("settings")}
        >
          Settings
        </NavLink>
      </div>
    </>
  );
});

// take a date and return true in the past
const isExpired = (date) => {
  const now = new Date();
  return now > date;
};

// take a date and return true if it's less than 30 days from now
// const isExpiringSoon = (date) => {
//   const now = new Date();
//   const res = date < now && date > now - 30 * 24 * 60 * 60 * 1000;
//   return res;
// };

// get number days between two dates
const getDaysBetween = (date2) => {
  const date1 = new Date();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const BrandTab = () => {
  const { role } = useAuthContext();
  const booths = useQuery(["getMyBooths"], getMyBooths, {
    refetchOnWindowFocus: false,
    enabled: role === ROLES.exhibitor && !!role,
  });

  const isExp = () => {
    if (!booths.data) return false;
    for (const booth of booths.data) {
      if (isExpired(new Date(booth.subscription_end))) return true;
    }
    return false;
  };

  const isExpSoon = () => {
    if (!booths.data) return false;
    for (const booth of booths.data) {
      if (getDaysBetween(new Date(booth.subscription_end)) < 30) return true;
    }
    return false;
  };

  return (
    <div>
      <Col xxl={{ span: 8, offset: 2 }} lg={{ span: 10, offset: 1 }}>
        <h3 className="h3 information-title information-title--brand">
          My brand list
        </h3>
        <ul className="information-brand-list">
          {booths.data?.map((brand, index) => (
            <li key={index}>
              <BrandCard
                key={brand.id}
                id={brand.id}
                brandLogo={brand.logo?.url}
                name={brand.name}
                text={brand.category?.name}
                //Deprecated typeImg={`/assets/images/subscription-${brand.subscription_type}.png`}
                isSoonExpired={
                  getDaysBetween(new Date(brand.subscription_end)) < 30
                }
                isExpired={isExpired(new Date(brand.subscription_end))}
                type={brand.subscription_type}
                date={new Date(brand.subscription_end).toLocaleDateString(
                  "en-US"
                )}
                countries={brand.countries}
              />
            </li>
          ))}
        </ul>
      </Col>
      <Col lg={{ span: 10, offset: 1 }} className="text-center">
        {isExpSoon() && !isExp() && (
          <div className="alert">
            Your subscription will expire in one month. Please contact{" "}
            <a href="mailto:vanessa.ng@hyatt.com">Hyatt-Asia-Pacific</a> to
            renew it.
          </div>
        )}
        {isExp() && (
          <div className="alert">
            Your subscription has expired. Your account and virtual booth has
            been temporarily disabled. Please contact{" "}
            <a href="mailto:vanessa.ng@hyatt.com">Hyatt Asia-Pacific</a> to
            renew it.
          </div>
        )}
      </Col>
    </div>
  );
};

const StatsTab = () => {
  const { role } = useAuthContext();
  const stats = useQuery(["getMyStats"], getMyStats);
  const brands = useQuery(["getBrands"], getBrands, {
    refetchOnWindowFocus: false,
    enabled: role === ROLES.exhibitor,
  });

  return (
    <Col xl={{ span: 11, offset: 1 }}>
      {stats.data?.map((stat) =>
        !stat.latestViews.length ? null : (
          <>
            <h3 className="h4 information-title information-title--statistics">
              {stat.exhibitorName} page views
            </h3>
            <div className="statistics-row">
              {stat.latestViews.map((brand) => (
                <div className="statistics-row-item">
                  <BrandCard
                    key={brand.id}
                    brandLogo={
                      brands.data
                        ? brands.data?.find((e) => e.name === brand.brand)?.logo
                            ?.url || "/assets/images/defaultLogo.png"
                        : "/assets/images/defaultLogo.png"
                    }
                    to={{
                      pathname: "/hotels",
                      contact: brand.user,
                    }}
                    name={`Someone from ${brand.hotel}`}
                    modifier="subscription"
                    title="Click to get in touch"
                  />
                </div>
              ))}
            </div>
            <div className="statistics-row-divider" />
          </>
        )
      )}
      <h3 className="h4 information-title information-title--statistics">
        Geographic repartitions
      </h3>
      <ul className="stats-cards">
        {stats.data?.map((stat) =>
          Object.keys(stat.statsByCountriesPercent).length === 0 ? null : (
            <li className="information-item">
              <h5 className="information-subtitle">{stat.exhibitorName}</h5>
              <BrandCard
                id={"13"}
                names={Object.keys(stat.statsByCountriesPercent).map((key) =>
                  key === "null" ? "Others" : key
                )}
                percentages={Object.keys(stat.statsByCountriesPercent)
                  .sort(
                    (a, b) =>
                      stat.statsByCountriesPercent[b] -
                      stat.statsByCountriesPercent[a]
                  )
                  .map(
                    (key) => Math.round(stat.statsByCountriesPercent[key]) + "%"
                  )}
                modifier={"stats"}
              />
            </li>
          )
        )}
      </ul>
    </Col>
  );
};

const SettingsTab = () => {
  const { updateProfile } = useAuthContext();
  const [isShowing, toggleModal] = useModal();
  const [modalContent, setModalContent] = useState(null);
  const infosMutation = useMutation(
    (infos) => changeInfos(infos.name, infos.email),
    {
      onError: (error, variables, context) => {},
      onSuccess: (data, variables, context) => {
        updateProfile(variables.name, variables.email);
        setModalContent("Your account infos has been updated");
        toggleModal();
      },
    }
  );
  const passwordMutation = useMutation(
    (passwords) => changePassword(passwords.old, passwords.new),
    {
      onError: (error, variables, context) => {},
      onSuccess: (data, variables, context) => {
        setModalContent("Your password has been updated");
        toggleModal();
      },
    }
  );

  return (
    <>
      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        showCloseButton={true}
        width={420}
      >
        <h2 className="h2 title text-center">{modalContent}</h2>
        <div className="text-center">
          <Button theme="primary" onClick={toggleModal}>
            Close
          </Button>
        </div>
      </Modal>
      <Col xl={{ span: 8, offset: 2 }}>
        <h2 className="h3 information-title">Company information</h2>
        <Formik
          initialValues={{
            name: localStorage.getItem("name"),
            email: localStorage.getItem("email"),
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (values.newPassword || values.oldPassword) {
              if (!values.newPassword) errors.newPassword = "Required";
              if (!values.oldPassword) errors.oldPassword = "Required";
            }
            if (values.newPassword !== values.confirmPassword) {
              errors.confirmPassword = "Password don't match";
            }
            return errors;
          }}
          onSubmit={() => {}}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            /* and other goodies */
          }) => (
            <Form>
              <section className="information-section">
                <Row>
                  <Col md="6">
                    <Input
                      name="name"
                      label="Account name"
                      placeholder="Name"
                      fullwidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={errors.name && touched.name}
                      errorMessage={errors.name}
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      name="email"
                      label="Mail address"
                      placeholder="Mail address"
                      fullwidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email}
                      errorMessage={errors.email}
                    />
                  </Col>
                </Row>
              </section>
              <h2 className="h3 information-title">Update your password</h2>
              <section className="information-section">
                <Row>
                  <Col md="6">
                    <Input
                      name="oldPassword"
                      label="Old password"
                      placeholder="*****"
                      fullwidth
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.oldPassword}
                      error={
                        passwordMutation.isError ||
                        (errors.oldPassword && touched.oldPassword)
                      }
                      errorMessage={
                        passwordMutation.isError
                          ? "Wrong password"
                          : errors.oldPassword
                      }
                    />
                  </Col>
                  <Col md="6"></Col>

                  <Col md="6">
                    <Input
                      name="newPassword"
                      label="New password"
                      placeholder="*****"
                      fullwidth
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                      error={errors.newPassword && touched.newPassword}
                      errorMessage={errors.newPassword}
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      name="confirmPassword"
                      label="Confirm new password"
                      placeholder="*****"
                      fullwidth
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      error={errors.confirmPassword && touched.confirmPassword}
                      errorMessage={errors.confirmPassword}
                    />
                  </Col>
                  <Col md="6" className="text-right">
                    <Button
                      type="button"
                      onClick={() => {
                        if (
                          values.name !== localStorage.getItem("name") ||
                          values.email !== localStorage.getItem("email")
                        ) {
                          infosMutation.mutate({
                            name: values.name,
                            email: values.email,
                          });
                        }

                        if (values.oldPassword && values.newPassword) {
                          passwordMutation.mutate({
                            old: values.oldPassword,
                            new: values.newPassword,
                          });
                          setFieldValue("oldPassword", "");
                          setFieldTouched("oldPassword", false);
                          setFieldValue("newPassword", "");
                          setFieldTouched("newPassword", false);
                          setFieldValue("confirmPassword", "");
                          setFieldTouched("confirmPassword", false);
                        }
                      }}
                      theme="primary"
                      className="information-settings-save"
                      disabled={
                        changeInfos.isLoading || changePassword.isLoading
                      }
                    >
                      Save changes
                    </Button>
                  </Col>
                  <Col md="6">
                    <Button
                      theme="secondary-light"
                      className="information-settings-cancel"
                      type="button"
                      onClick={() => {
                        setFieldValue("name", localStorage.getItem("name"));
                        setFieldTouched("name", false);
                        setFieldValue("email", localStorage.getItem("email"));
                        setFieldTouched("email", false);

                        setFieldValue("oldPassword", "");
                        setFieldTouched("oldPassword", false);
                        setFieldValue("newPassword", "");
                        setFieldTouched("newPassword", false);
                        setFieldValue("confirmPassword", "");
                        setFieldTouched("confirmPassword", false);
                      }}
                    >
                      Cancel changes
                    </Button>
                  </Col>
                </Row>
              </section>
            </Form>
          )}
        </Formik>
      </Col>
    </>
  );
};

export default withRouter(Account);
