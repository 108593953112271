const http = (function () {
	const axios = require('axios')
	const instance = axios.create({
		baseURL: process.env.REACT_APP_API_URL || 'https://mansan-api.dev.mediasia.cn',
	})
	// instance.interceptors.response.use((response) => response, (error) => {
	// 	// whatever you want to do with the error
	// 	const message = JSON.stringify(error.toJSON())
	// 	if (window.location.href !== '/auth' && message.indexOf('403')) {
	// 		localStorage.clear()
	// 		window.location.href = '/auth'
	// 	}
	// 	console.error(error);
	// });
  return {
    get: function(url, config = {}) {
      return instance.get(url, config)
    },
    post: function(url, data, config = {}) {
      return instance.post(url, data, config)
    }
  }

}())

export default http