import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { HomeSlide } from './homeslider'

const SliderComponent = React.memo((props) => {
	const [mySwiper, setMySwiper] = useState()
	const [activeIndex, setActiveIndex] = useState(0)
	const [nextIndex, setNextIndex] = useState()
	const [shouldWatchOverflow, setShouldWatchOverflow] = useState(false)
	const prevRef = useRef(null)
	const nextRef = useRef(null)
	let timer1
	let timer2
	
	const slides = props.content.map((item, index) => {
		// console.log('ITEM', item, index)
		const t = item.type.split('/')[0]
		return (
			<SwiperSlide key={item.title}>
				<HomeSlide
					key={item.title}
					title={item.title}
					contentType={t}
					url={item.url}
					blur={props.blur && index === 0}
					controls={props.controls}
					playsInline={props.playsInline}
					autoPlay={props.autoPlay}
					loop={props.content.length === 1}
					mediaIndex={`media${index}`}
					onLoad={props.onLoad}
					onEnd={mySwiper && index === activeIndex ? () => mySwiper.slideTo(nextIndex, 700) : null}
				/>
			</SwiperSlide>
		)
	})

	useEffect(() => {
		const pagination = document.getElementsByClassName('swiper-pagination swiper-pagination-bullets')[0]
		const swiperWrapper = document.getElementsByClassName('swiper-wrapper')[0]
		const onlyOneSlide = props.content.length === 1

		if(onlyOneSlide && mySwiper) {
			setShouldWatchOverflow(true)
			pagination.classList.add('hide')
			swiperWrapper.classList.add('disable')
		} else {
			if (mySwiper && pagination && swiperWrapper) {
				setShouldWatchOverflow(false)
				pagination.classList.remove('hide')
				swiperWrapper.classList.remove('disable')
			}
		}
	}, [mySwiper, props.content])

	const initSwiper = (swiper) => {
		setMySwiper(swiper)
		swiperController(swiper)
		// if (props.onLoad) {
		// 	setTimeout(() => {
		// 		props.onLoad()
		// 	}, 3000)
		// }
	}

	useEffect(() => {
		return (() => {
			window.clearTimeout(timer1)
			window.clearTimeout(timer2)
		})
	}, [])

	// const swiperControl = (s) => {
	// 	console.log('EVENTTYPE', s)
	// }

	const swiperController = (s) => {
		window.clearTimeout(timer2)
		let prevIndex
		let nextIndex
		// if (props.content.length > 2) {
		// 	console.log('MORETHAN2')
			prevIndex = s.isBeginning ? props.content.length - 1 : s.activeIndex - 1
			nextIndex = s.isEnd ? 0 : s.activeIndex + 1
		// }
		// if (props.content.length === 2) {
		// 	console.log('TWO')
		// 	prevIndex = s.activeIndex === 1 ? 0 : 1
		// 	nextIndex = s.activeIndex === 1 ? 0 : 1
		// }
		// console.log('PREVINDEX', prevIndex)
		// console.log('NEXTINDEX', nextIndex)
		const prevItem = document.getElementById(`media${prevIndex}`)
		const nextItem = document.getElementById(`media${nextIndex}`)
		const currentItem = document.getElementById(`media${s.activeIndex}`)
		setActiveIndex(s.activeIndex)
		setNextIndex(nextIndex)
		if (prevItem && prevItem.classList.contains('video')) {
			prevRef.current = prevItem
			prevRef.current.pause()
			if (prevItem.currentTime) {
				prevItem.currentTime = 0
			}
			prevItem.muted = true
		}
		if (nextItem && nextItem.classList.contains('video')) {
			nextRef.current = nextItem
			nextRef.current.pause()
			if (nextItem.currentTime) {
				nextItem.currentTime = 0
			}
			nextItem.muted = true
		}
		if (currentItem && currentItem.classList.contains('video')) {
			// console.log('CURRENTITEM', currentItem)
		}
		if (currentItem && !currentItem.classList.contains('video')) {
			timer2 = setTimeout(() => {
				s.slideTo(nextIndex, 700)
			}, 7000)
		}
	}

	// const pauseAllVideos = () => {
	// 	const vids = Array.from(document.getElementsByTagName('video'))
	// 	vids.forEach(v => {
	// 		if (v) {
	// 			if (!v.classList.contains('hover-effect')) {
	// 				v.pause()
	// 				if (v.currentTime) {
	// 					v.currentTime = 0
	// 				}
	// 				v.muted = true
	// 				v.play()
	// 			}
	// 		}
	// 	})
	// }


	SwiperCore.use([Pagination, Autoplay]);
	// const handleNext = () => {
	// 	if (mySwiper) {
	// 		mySwiper.slideNext()
	// 	}
	// }
	return (
		<div id="swiper" className="slider-component centered-section">
			{ props.content.length > 0 &&
			<Swiper
				spaceBetween={0}
				slidesPerView={1}
				loopedSlides={0}
				initialSlide={0}
				scrollbar={{ draggable: true }}
				onSwiper={(swiper) => initSwiper(swiper)}
				pagination={{ 
					clickable: true
				}}
				onSlideChangeTransitionEnd={(swiper) => swiperController(swiper)}
			>
				{slides}
			</Swiper>
			}
			{props.caption &&
				<p className={`slider-component__caption ${shouldWatchOverflow ? 'neg-top-margin' : ''}`}>{props.caption}</p>
			}
		</div>
	)
})

export default SliderComponent
