import React, { useState, useEffect } from 'react'
import { 
	Select,
	OptGroup,
	TextInput,
	Label,
	TagDisplay,
	TextArea,
	SocialTag
} from '../../ui/form'
import { BigBlackButton } from '../../ui/buttons'
import { CenteredDivider } from '../../layout/divider'
import { useDataContext } from '../../../store/data'
import { withRouter } from "react-router-dom"
import get from 'lodash.get'
import EditBoothControls from './editControls'
import CountryList from './countryList'
import FileUploader from './fileUploader'
import ImageVideoTagDisplay from './imageVideoTagDisplay'
import tracking from '../../../utils/tracking'
import {getNthItemFromPathname} from "../../../utils"

const CAROUSEL_LIMIT = 3
const IMAGE_LIMIT = 5
const CATALOGUE_LIMIT = 15

const EditBooth = (props) => {
	const {
		myBooth,
		getMyBooth,
		categories,
		tags,
		getTags,
		countries,
		getCountries,
		updateMyBooth,
		uploadFiles,
		setPreviewData,
		setUploadedFiles,
		setStashBoothData,
		setLoading,
		previewData,
		usePreviewData,
		setUsePreviewData,
		setHeaderIconOnClick
	} = useDataContext()

	const pathname = props.history.location.pathname
	const id = getNthItemFromPathname(pathname, 2)

	const [boothData, setBoothData] = useState(usePreviewData ? previewData : myBooth)
	const [logoFiles, setLogoFiles] = useState([])
	const [carouselFiles, setCarouselFiles] = useState([])
	const [imageVideoFiles, setImageVideoFiles] = useState([])
	const [thumbnailFiles, setThumbnailFiles] = useState([])
	const [catalogueFiles, setCatalogueFiles ] = useState([])
	const [contactFiles, setContactFiles] = useState([])
	const [weChatFiles, setWeChatFiles] = useState([])
	const [badWebsite, setBadWebstite] = useState(false)
	const [badFacebook, setBadFacebook] = useState(false)
	const [badInstagram, setBadInstagram] = useState(false)
	const [badLinkedin, setBadLinkedin] = useState(false)

	// useEffect(() => {
	// 	if (Object.keys(myBooth).length === 0) {
	// 		getMyBooth(id)
	// 		if (myBooth.subcategory) {
	// 			getTags(get(myBooth, 'subcategory.id', ''), null, 'subcategory')
	// 		} else {
	// 			getTags(get(myBooth, 'category.id', ''))
	// 		}
	// 		getCountries()
	// 	}
	// 	tracking.pageView(`exhibit/${myBooth.id}/edit/`)
	// }, [])

	useEffect(() => {
		setLoading(true)
		getMyBooth(id)
		tracking.pageView(props.history.location.pathname)
		setTimeout(() => {
			setLoading(false)
		}, 2000)
	}, [])

	useEffect(() => {
		if (usePreviewData) {
			setBoothData((prevState) => ({
				...prevState,
				'countries': previewData.countries,
				'category': previewData.category,
				'subcategory': previewData.subcategory,
				'description': previewData.description,
				'facebook_link': previewData.facebook_link,
				'instagram_link': previewData.instagram_link,
				'linkedin_link': previewData.linkedin_link,
				'tags': previewData.tags,
				'website_url': previewData.website,
				'title': previewData.title,
				'images_videos': get(previewData, 'images_videos', []).filter(item => !item.path),
				'thumbnails': get(previewData, 'thumbnails', []).filter(item => !item.path),
				'video_thumbmnail_links': previewData.video_thumbmnail_links,
				'carousel': get(previewData, 'carousel', []).filter(item => !item.path),
				'catalogs': get(previewData, 'catalogs', []).filter(item => !item.path),
			}))
			setLogoFiles(previewData.logo && previewData.logo.path ? [previewData.logo] : [])
			setCarouselFiles(get(previewData, 'carousel', []).filter(item => item.path !== undefined))
			setImageVideoFiles(get(previewData, 'images_videos', []).filter(item => item.path !== undefined))
			setThumbnailFiles(get(previewData, 'thumbnails', []).filter(item => item.path !== undefined))
			setCatalogueFiles(get(previewData, 'catalogs', []).filter(item => item.path !== undefined))
			setContactFiles(previewData.contact && previewData.contact.path ? [previewData.contact] : [])
			setWeChatFiles(previewData.wechat_qr_code && previewData.wechat_qr_code.path ? [previewData.wechat_qr_code] : [])
			// setPreviewData({})
		} else {
			setBoothData(myBooth)
		}
		if (myBooth.subcategory) {
			getTags(get(myBooth, 'subcategory.id', ''), null, 'subcategory')
		} else {
			getTags(get(myBooth, 'category.id', ''))
		}
		getCountries()
		setHeaderIconOnClick(onPreview)
	}, [myBooth])

	const onCancel = () => {
		setUsePreviewData(false)
		setPreviewData({})
		props.history.goBack()
	}

	const onPreview = () => {
		const uploadedFiles = {
			logoFiles,
			carouselFiles,
			imageVideoFiles,
			thumbnailFiles,
			catalogueFiles,
			contactFiles,
			weChatFiles
		}

		const previewData = {
			id: boothData.id,
			category: boothData.category,
			subcategory: boothData.subcategory,
			tags: boothData.tags,
			description: document.getElementById('description').value || '', 
			title: document.getElementById('name').value || '',
			logo: logoFiles[0] || boothData.logo,
			website: document.getElementById('website').value || '',
			carousel: [...get(boothData, 'carousel', []), ...carouselFiles],
			countries: boothData.countries,
			instagram_link: document.getElementById('instagram').value || boothData.instagram_link,
			linkedin_link: document.getElementById('linkedin').value || boothData.linkedin_link,
			facebook_link: document.getElementById('fb').value || boothData.facebook_link ,
			wechat_qr_code: weChatFiles[0] || boothData.wechat_qr_code,
			catalogs:  [...get(boothData, 'catalogs', []), ...catalogueFiles],
			images_videos:  [...get(boothData, 'images_videos', []), ...imageVideoFiles],
			thumbnails:  [...get(boothData, 'thumbnails', []), ...thumbnailFiles],
			video_thumbmnail_links:  boothData.video_thumbmnail_links,
			contact: contactFiles[0] || boothData.contact
		}
		setStashBoothData(boothData)
		setUploadedFiles(uploadedFiles)
		setPreviewData(previewData)
		setUsePreviewData(true)
		props.history.push(`/exhibit/${boothData.id}/preview`)
	}
	// setHeaderIconOnClick(onPreview)
	const removeTag = (name) => {
		const newTagList = get(boothData, 'tags', []).filter(t => t.name !== name)
		setBoothData((prevState) => ({
			...prevState,
			'tags': newTagList
		}))
	}

	const addTag = (e) => {
		const slug = e.target.value
		const newTag = tags.find(t => t.name === slug)
		const newTagList = [...boothData.tags, newTag]

		setBoothData((prevState) => ({
			...prevState,
			'tags': newTagList
		}))
	}

	const changeCategory = (e) => {
		// the option will be either a category or a subcategory,
		// if it is a subcategory we get the parent category id as well
		const [type, id, parentId] = e.target.value.split('-')
		const cat = id
		if (type === 'category') {
			const newCategory = categories.find(c => c.id === cat)
			setBoothData((prevState) => ({
				...prevState,
				'category': newCategory,
				'subcategory': '',
				'tags': []
			}))
			if (newCategory && newCategory.id) {
				getTags(newCategory.id)
			} else {
				setBoothData((prevState) => ({
					...prevState,
					'category': '',
					'subcategory': '',
					'tags': []
				}))
			}
		}
		if (type === 'subcategory') {
			const parentCategory = categories.find(c => c.id === parentId)
			let subcategory
			if (parentCategory) {
				subcategory = parentCategory.subcategories.find(c => c.id === cat)
				setBoothData((prevState) => ({
					...prevState,
					'category': parentCategory,
					'subcategory': subcategory,
					'tags': []
				}))
				if (subcategory && subcategory.id) {
					getTags(subcategory.id, null, 'subcategory')
				} else {
					setBoothData((prevState) => ({
						...prevState,
						'category': '',
						'tags': []
					}))
				}
			}
		}
	}

	const toggleCountry = (name) => {
		const allCountries = boothData.countries.map(c => c.name)
		const newCountryList = boothData.countries.filter(c => c.name !== name && c.name !== 'All ASPAC')
		if (allCountries.indexOf(name) > -1) {
			setBoothData(prevState => ({
				...prevState,
				'countries': newCountryList
			}))
		} else {
			const countryToAdd = countries.find(c => c.name === name)
			const newCountryList = [...boothData.countries, countryToAdd].filter(c => c.name !== 'All ASPAC')
			setBoothData(prevState => ({
				...prevState,
				'countries': newCountryList
			}))
		}
	}

	const removeFile = (type, title) => {
		let typeBoothData = get(boothData, type, [])
		if (typeBoothData) {

			if (!Array.isArray(typeBoothData)) {
				typeBoothData = [typeBoothData]
			}
			if (typeBoothData.find(d => d.title === title)) {
				setBoothData(prevState => ({
					...prevState,
					[type]: typeBoothData.filter(d => d.title !== title)
				}))

				var associatedThumbnail = null
				for (const i in boothData.video_thumbmnail_links) {
					if (boothData.video_thumbmnail_links[i].video === title) {
						associatedThumbnail = boothData.video_thumbmnail_links[i].thumbnail
						break
					}
				}
				if (associatedThumbnail) {
					setThumbnailFiles(thumbnailFiles.filter(d => d.title ? d.title !== associatedThumbnail : d.name !== associatedThumbnail))
					setBoothData((prevState) => ({
						...prevState,
						'video_thumbmnail_links': boothData.video_thumbmnail_links.filter(d => d.thumbnail ? d.thumbnail !== associatedThumbnail : d.thumbnail !== associatedThumbnail),
						'thumbnails': boothData.thumbnails.filter(d => d.title ? d.title !== associatedThumbnail : d.name !== associatedThumbnail)
					}))
				}
				return
			}

		}
		switch (type) {
			case 'logo':
				setLogoFiles([])
				break;
			case 'carousel':
				setCarouselFiles(carouselFiles.filter(d => d.title ? d.title !== title : d.name !== title))
				break;
			case 'images_videos':
				var associatedThumbnail = null
				for (const i in boothData.video_thumbmnail_links) {
					if (boothData.video_thumbmnail_links[i].video === title) {
						associatedThumbnail = boothData.video_thumbmnail_links[i].thumbnail
						break
					}
				}
				setImageVideoFiles(imageVideoFiles.filter(d => d.title ? d.title !== title : d.name !== title))
				if (associatedThumbnail) {
					setThumbnailFiles(thumbnailFiles.filter(d => d.title ? d.title !== associatedThumbnail : d.name !== associatedThumbnail))
					setBoothData((prevState) => ({
						...prevState,
						'video_thumbmnail_links': boothData.video_thumbmnail_links.filter(d => d.thumbnail ? d.thumbnail !== associatedThumbnail : d.thumbnail !== associatedThumbnail),
						'thumbnails': boothData.thumbnails.filter(d => d.title ? d.title !== associatedThumbnail : d.name !== associatedThumbnail)
					}))
				}
				break;
			case 'thumbnails':

				setThumbnailFiles(thumbnailFiles.filter(d => d.title ? d.title !== title : d.name !== title))
				setBoothData((prevState) => ({
					...prevState,
					'video_thumbmnail_links': boothData.video_thumbmnail_links.filter(d => d.thumbnail ? d.thumbnail !== title : d.thumbnail !== title)
				}))
				break;
			case 'catalogs':
				setCatalogueFiles(catalogueFiles.filter(d => d.title ? d.title !== title : d.name !== title))
				break;
			case 'contact':
				setContactFiles([])
				break;				
			case 'wechat_qr_code':
				setWeChatFiles([])
				break;
			default:
				break;
		}
	}

	const fileUpdate = (files, type, parent = null) => {

		switch (type) {
			case 'logo':
				setLogoFiles(files)
				break;
			case 'carousel':
				if (get(boothData, 'carousel', []).length + carouselFiles.length + files.length <= CAROUSEL_LIMIT) {
					setCarouselFiles([...carouselFiles, ...files])
				}
				break;
			case 'imageVideo':
				if (get(boothData, 'images_videos', []).length + imageVideoFiles.length + files.length <= IMAGE_LIMIT) {
					setImageVideoFiles([...imageVideoFiles, ...files])
				}
				break;
			case 'thumbnails':
				if (get(boothData, 'thumbnails', []).length + thumbnailFiles.length + files.length <= IMAGE_LIMIT) {
					setThumbnailFiles([...thumbnailFiles, ...files])
					setBoothData((prevState) => ({
						...prevState,
						'video_thumbmnail_links': [...boothData.video_thumbmnail_links, { video: parent, thumbnail: files[0].name } ]
					}))
				}
				break;
			case 'catalogs':
				if (get(boothData, 'catalogs', []).length + catalogueFiles.length + files.length <= CATALOGUE_LIMIT) {
					setCatalogueFiles([...catalogueFiles, ...files])
				}
				break;
			case 'contact':
				setContactFiles(files)
				break;				
			case 'wechat_qr_code':
				setWeChatFiles(files)
				break;
			default:
				break;
		}
	}

	const Aspac = () => {
		if (boothData.countries.map(c => c.name).indexOf('All ASPAC') === -1) {
			setBoothData((prevState) => ({
				...prevState,
				'countries': countries.filter(c => c.name === 'All ASPAC')
			}))
		} else {
			setBoothData((prevState) => ({
				...prevState,
				'countries': []
			}))
		}
	}

	const prepareFileUpload = (files = [], type) => {
		const formData = new FormData()
		let typeBoothData = get(boothData, `${type}`, null)

		let prevFiles = []
		if (typeBoothData) {
			if (!Array.isArray(typeBoothData)) {
				prevFiles.push(typeBoothData.title)
			} else {
				prevFiles = typeBoothData.map(d => d.title)
			}

			formData.append('previous_files', JSON.stringify(prevFiles))
			// if (type !== 'logo' && type !== 'contact' && type !== 'wechat_qr_code') {
			// 	formData.append('previous_files', JSON.stringify(prevFiles))
			// }
		}
		if (!Array.isArray(files)) files = [files]
		if (files.forEach){
			files.forEach(f => {
				formData.append('files', f, f.name)
			})
		}
		if (type === 'wechat_qr_code') {
			type = 'wechat'
		}
		return uploadFiles(id, formData, type)
	}

	const submitBooth = () => {
		setUsePreviewData(false)
		setPreviewData({})
		setLoading(true)
		Promise.all([
			prepareFileUpload(logoFiles, 'logo'),
			prepareFileUpload(carouselFiles, 'carousel'),
			prepareFileUpload(imageVideoFiles, 'images_videos'),
			prepareFileUpload(thumbnailFiles, 'thumbnails'),
			prepareFileUpload(catalogueFiles, 'catalogs'),
			prepareFileUpload(contactFiles, 'contact'),
			prepareFileUpload(weChatFiles, 'wechat_qr_code')
		]).then(() => {
			const newBooth = {
				name: document.getElementById('name').value,
				website_url: document.getElementById('website').value,
				category: get(boothData, 'category.id', null),
				subcategory: get(boothData, 'subcategory.id', null),
				description: document.getElementById('description').value,
				tags: get(boothData, 'tags', []).map(t => t.id),
				video_thumbmnail_links: get(boothData, 'video_thumbmnail_links', []),
				countries: get(boothData, 'countries', []).map(c => c.id),
				facebook_link: document.getElementById('fb').value.split(' ').join(''),
				instagram_link: document.getElementById('instagram').value.split(' ').join(''),
				linkedin_link: document.getElementById('linkedin').value.split(' ').join('')
			}
			updateMyBooth(id, newBooth).then(() => {
				props.history.push(`/exhibit/${boothData.id}`)
			})
		})
	}

	const validateWebsite = (e) => {
		if (e.target.value) {
			const value = e.target.value
			const hasHttp = value.indexOf('http://')
			const hasHttps = value.indexOf('https://')
			if (hasHttp === 0 || hasHttps === 0) {
				setBadWebstite(false)
			} else {
				setBadWebstite(true)
			}
		} else {
			setBadWebstite(false)
		}
	}

	const validateFacebook = (e) => {
		if (e.target.value) {
			const value = e.target.value
			const hasHttp = value.indexOf('http://')
			const hasHttps = value.indexOf('https://')
			if (hasHttp === 0 || hasHttps === 0) {
				setBadFacebook(false)
			} else {
				setBadFacebook(true)
			}
		} else {
			setBadFacebook(false)
		}
	}

	const validateInstagram = (e) => {
		if (e.target.value) {
			const value = e.target.value
			const hasHttp = value.indexOf('http://')
			const hasHttps = value.indexOf('https://')
			if (hasHttp === 0 || hasHttps === 0) {
				setBadInstagram(false)
			} else {
				setBadInstagram(true)
			}
		} else {
			setBadInstagram(false)
		}
	}

	const validateLinkedin = (e) => {
		if (e.target.value) {
			const value = e.target.value
			const hasHttp = value.indexOf('http://')
			const hasHttps = value.indexOf('https://')
			if (hasHttp === 0 || hasHttps === 0) {
				setBadLinkedin(false)
			} else {
				setBadLinkedin(true)
			}
		} else {
			setBadLinkedin(false)
		}
	}

	// only allow adding tags that are not already on the boothData
	let filteredTags = tags && boothData.tags ? tags.filter(t => boothData.tags.map(tg => tg.name).indexOf(t.name) === -1) : []
	if (!boothData.category) {
		filteredTags = []
	}
	return (
		<>
			<EditBoothControls onCancel={onCancel} onPreview={onPreview} />
			<div className="edit-booth-form">
				<OptGroup
					id="category"
					values={categories}
					onChange={changeCategory}
					defaultValue={get(boothData, 'category.slug', '') ? get(boothData, 'category.slug', '') : ''}
					subcategory={get(boothData, 'subcategory.slug', '')}
					emptyDefaultText="Select a category"
				/>
				<Label text="tags" />
				{get(boothData, 'tags', []).map(t => (
					<TagDisplay key={t.id} text={t.name} onClick={() => removeTag(t.name)} />
				))}
				<Select
					id="tags"
					values={filteredTags.map(t => t.name)}
					texts={filteredTags.map(t => t.name)}
					onChange={(e) => addTag(e)}
					emptyDefaultText="Select a tag"
				/>
				<CenteredDivider />
				<Label text="brand" />
				{boothData.logo && (boothData.logo.url || boothData.logo.title || boothData.logo.name) &&
					<TagDisplay key={boothData.logo.url} text={boothData.logo.title || boothData.logo.name} type="logo" onClick={() => removeFile('logo', boothData.logo.title)} />
				}
				{(!boothData.logo || boothData.logo.length === 0) && logoFiles[0] &&
					<TagDisplay key={logoFiles[0].size} text={logoFiles[0].name} type="logo" onClick={() => removeFile('logo', logoFiles[0].name)} />
				}
				<FileUploader
					text="upload logo"
					callback={fileUpdate}
					accept={['image/jpg','image/jpeg', 'image/png', 'image/tiff']}
					type="logo"
					disable={
						(boothData.logo && boothData.logo.length && boothData.logo.length > 0) ||
						(boothData.logo && boothData.logo.url) ||
						(logoFiles.length > 0)}
				/>
				<div>
					<TextInput id="name" placeholder="brand/company name" defaultValue={usePreviewData ? previewData.title : boothData.name} />
				</div>
				<div>
					<TextInput id="website" placeholder="website url" defaultValue={usePreviewData ? previewData.website : boothData.website_url} onChange={validateWebsite} />
					<div className={`help-text ${badWebsite ? 'red' : ''}`} style={{ marginTop: '4px'}}>Url must start with http:// or https://</div>
				</div>
				<CenteredDivider />
				<Label text="carousel" />
				{[...get(boothData, 'carousel', []), ...carouselFiles].map(t => (
					<TagDisplay 
						key={t.url || t.size}
						text={t.title || t.name}
						type="carousel"
						onClick={() => removeFile('carousel', `${t.title ? t.title : t.name}`)}
					/>
				))}
				<FileUploader
					text="upload photo/video"
					callback={fileUpdate}
					type="carousel"
					multiple
					accept={[ 'image/jpg','image/jpeg', 'image/png', 'image/tiff', 'video/mp4']}
					maxFiles={3 - (get(boothData, 'carousel', []).length + carouselFiles.length)}
					helpText="(up to 3, and up to 50MB each)"
				/>
				<span className="edit-booth-form__download">Need to compress the video? <a href={`${process.env.REACT_APP_API_URL}/assets/HDE%20-%20Video%20Compression%20Guide.pdf`} download target="_blank" rel="noopener noreferrer">Click here</a></span>
				<CenteredDivider />
				<div className="text-area-wrapper">
					<Label text="description" />
					<TextArea id="description" maxLength={'500'} placeholder="description" defaultValue={usePreviewData ? previewData.description : boothData.description} />
					<span className="help-text">(up to 500 characters)</span>
				</div>
				<CenteredDivider />
				<Label text="add images & videos" />
				{[...get(boothData, 'images_videos', []), ...imageVideoFiles].map(t => (
					<ImageVideoTagDisplay
						key={t.url || t.size}
						text={t.title || t.name}
						type="images_videos"
						callback={fileUpdate}
						thumbnailFiles={thumbnailFiles}
						videoThumbmnailLinks={boothData.video_thumbmnail_links}
						boothData={boothData}
						onClick={removeFile}
					/>
				))}
				<FileUploader
					text="upload photo/video"
					callback={fileUpdate}
					type="imageVideo"
					multiple
					accept={[ 'image/jpg','image/jpeg', 'image/png', 'image/tiff', 'video/mp4']}
					maxFiles={5 - (get(boothData, 'images_videos', []).length + imageVideoFiles.length)}
					helpText="(up to 5, and up to 50MB each)"
				/>
				<span className="edit-booth-form__download">Need to compress the video? <a href={`${process.env.REACT_APP_API_URL}/assets/HDE%20-%20Video%20Compression%20Guide.pdf`} download target="_blank" rel="noopener noreferrer">Click here</a></span>
				<CenteredDivider />
				<Label text="catalogue" />
				{[...get(boothData, 'catalogs', []), ...catalogueFiles].map(c => (
					<TagDisplay key={c.url || c.size} text={c.title || c.name} type="catalogs" onClick={() => removeFile('catalogs', `${c.title ? c.title : c.name}`)} />
				))}
				<FileUploader
					text="upload catalogue"
					callback={fileUpdate}
					type="catalogs"
					multiple
					accept={['application/pdf']}
					maxFiles={15 - (get(boothData, 'catalogs', []).length + catalogueFiles.length)}
					helpText="(up to 15)"
				/>
				<CenteredDivider />
				<Label text="country/region availability" />
				<CountryList
					countries={countries}
					checkedCountries={get(boothData, 'countries', []).map(c => c.name)}
					toggleCountry={toggleCountry}
					aspac={Aspac}
				/>
				<CenteredDivider />
				<Label text="contact" />
				{boothData.contact && (boothData.contact.url || boothData.contact.title || boothData.contact.name) &&
					<TagDisplay
						text={boothData.contact.title || boothData.contact.name}
						type="contact"
						onClick={() => removeFile('contact', boothData.contact.title) }
					/>
				}
				{
					(!boothData.contact || boothData.contact.length === 0) &&
					contactFiles && contactFiles[0] &&
					<TagDisplay
						text={contactFiles[0].name}
						type="contact"
						onClick={() => removeFile('contact', contactFiles[0].name)}
					/>
				}
				<FileUploader
					text="upload pdf"
					callback={fileUpdate}
					type="contact"
					accept={['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
					// maxFiles={(boothData.contact || contactFiles.length > 0) ? 0 : 1}
					disable={
						(boothData.contact && boothData.contact.length && boothData.contact.length > 0) ||
						(boothData.contact && boothData.contact.url) ||
						(contactFiles.length > 0)}
				/>
				<SocialTag id="fb" social="fb" placeholder="facebook url" defaultValue={usePreviewData ? previewData.facebook_link : boothData.facebook_link} helpText="Url must start with http:// or https://" bad={badFacebook} validator={validateFacebook} />
				{/* <SocialTag social="wechat" weChat /> */}
				<SocialTag id="instagram" social="instagram" placeholder="instagram url" defaultValue={usePreviewData ? previewData.instagram_link : boothData.instagram_link} helpText="Url must start with http:// or https://" bad={badInstagram} validator={validateInstagram} />
				<SocialTag id="linkedin" social="linkedin" placeholder="linkedin url" defaultValue={usePreviewData ? previewData.linkedin_link : boothData.linkedin_link} helpText="Url must start with http:// or https://" bad={badLinkedin} validator={validateLinkedin} />
				{boothData.wechat_qr_code && (boothData.wechat_qr_code.url || boothData.wechat_qr_code.title || boothData.wechat_qr_code.name) &&
					<TagDisplay
						text={boothData.wechat_qr_code.title || boothData.wechat_qr_code.name}
						type="wechat_qr_code"
						onClick={() => removeFile('wechat_qr_code', boothData.wechat_qr_code.title)}
					/>
				}
				{
					(!boothData.wechat_qr_code || boothData.wechat_qr_code.length === 0) &&
					weChatFiles && weChatFiles[0] &&
					<TagDisplay
						text={weChatFiles[0].name}
						type="wechat_qr_code"
						onClick={() => removeFile('wechat_qr_code', weChatFiles[0].name)}
					/>
				}
				 <div className="wechat-box">
					<img className="social-display__icon" src={`/assets/images/icon-wechat.png`} alt="icon-close-white" />
					<FileUploader
						text="upload wechat qr"
						callback={setWeChatFiles}
						type="wechat_qr_code"
						accept={[ 'image/jpg','image/jpeg', 'image/png', 'image/tiff']}
						// maxFiles={(boothData.wechat_qr_code || weChatFiles.length > 0) ? 0 : 1}
						disable={
							(boothData.wechat_qr_code && boothData.wechat_qr_code.length && boothData.wechat_qr_code.length > 0) ||
							(boothData.wechat_qr_code && boothData.wechat_qr_code.url) ||
							(weChatFiles.length > 0)}
					/>
				</div>
				<CenteredDivider />
				<BigBlackButton text="save the virtual booth" onClick={submitBooth} disabled={badFacebook || badInstagram || badLinkedin || badWebsite} />
			</div>
		</>
	)
}

export default withRouter(EditBooth)