export default {
  brands: {
    name: "brands",
    header: "my account - Brands",
    menu: false,
  },
  stats: {
    name: "stats",
    header: "my account - Statistics",
    menu: false,
  },
  settings: {
    name: "settings",
    header: "my account - Settings",
    menu: false,
  },
  information: {
    name: "information",
    header: "my account",
    menu: false,
  },
  edit: {
    name: "editinfo",
    header: "my account - edit",
    menu: false,
  },
  password: {
    name: "password",
    header: "my account - change password",
    menu: false,
  },
};
