import React, { useState, useEffect } from "react";
import { baseURL } from "../../../network/API";
import TruncateMarkup from "react-truncate-markup";

const ProductCard = ({
  id,
  videoId,
  exhibitor,
  name,
  description,
  uri,
  to,
  handleSlideClick,
  isVideo,
  onImageClick,
  onVideoClick,
}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const [showTruncated, setShowTruncated] = useState(false);
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey((key) => key + 1);
  }, [description]);
  return (
    <div
      className="product-card-discover"
    >
      <div
        className="product-card-discover__image"
        style={{ backgroundImage: `url(${baseURL}/storage/products/${uri})` }}
        onClick={() => {
          if (handleSlideClick) {
            handleSlideClick({
              type: 'product',
              media: {
                type: 'image',
                uri: `${baseURL}/storage/products/${uri}`
              },
              name,
              description
            });
          }
        }}
      />
      <div className="product-display-wrapper">
        <TruncateMarkup lines={3}>
          <div className="product-card-discover__title">{name}</div>
        </TruncateMarkup>
        {isReadMore ? (
          <>
            <TruncateMarkup
              key={key}
              lines={3}
              onTruncate={(wasTruncated) => setShowTruncated(wasTruncated)}
            >
              <p className="product-card-discover__subtitle">{description}</p>
            </TruncateMarkup>
            {showTruncated && (
              <button
                className="read-more-btn"
                onClick={() => setIsReadMore(false)}
              >
                <span className="btn-text">Read more</span>
                <span className="icon-chevron-down btn-icon"></span>
              </button>
            )}
          </>
        ) : (
          <>
            <p className="product-card-discover__subtitle">{description}</p>
            <button
              className="read-more-btn"
              onClick={() => setIsReadMore(true)}
            >
              <span className="btn-text">Shorten</span>
              <span className="icon-chevron-up btn-icon"></span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
