import React, { useEffect, useRef, useReducer, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./notifications.scss";
import { Dropdown } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";
import {
  getMyNotification,
  readAllNotifications,
  getMyBooths,
  readANotifications,
} from "../../../network/API";
import { ROLES } from "../../../constants/roles";
import { useAuthContext } from "../../../store/auth";
import { withRouter } from "react-router-dom";

function sameDay(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

const isDateExpired = (date) => {
  const now = new Date();
  return now > new Date(date);
};

const isDateExpiredSoon = (date) => {
  const now = new Date();
  const diffTime = Math.abs(new Date(date) - now);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays < 30;
};

const Notifications = withRouter((props) => {
  const { userAuthed, role } = useAuthContext();
  // const [isExpired, setIsExpired] = useReducer(
  //   (state, action) => ({ ...state, ...action }),
  //   { read: false, is: false }
  // );
  // const [isExpiredSoon, setIsExpiredSoon] = useReducer(
  //   (state, action) => ({ ...state, ...action }),
  //   { read: false, is: false }
  // );
  const [anyExpired, setAnyExpired] = useState(false);
  const [read, setRead] = useState(false);
  const ref = useRef();
  const queryClient = useQueryClient();
  const notifications = useQuery(["getMyNotification"], getMyNotification, {
    enabled: !!userAuthed,
  });
  const booths = useQuery(["getMyBooths"], getMyBooths, {
    refetchOnWindowFocus: false,
    enabled: role === ROLES.exhibitor,
    onSuccess: (data) => {
      for (const booth of data) {
        if (
          isDateExpired(booth.subscription_end) ||
          isDateExpiredSoon(booth.subscription_end)
        ) {
          setAnyExpired(true);
          break;
        }
      }
    },
  });
  const onOpenNotif = useMutation(readAllNotifications);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let location = useLocation();

  useEffect(() => {
    // trick to hide dropdown menu
    document.body.click();
  }, [location]);

  const notifTypes = {
    new_follower: "New follower",
    update_exhibitor: "Exhibitor updated",
    new_exhibitor: "New exhibitor",
    new_like: "Likes",
  };

  const hasNewNotifs = () => {
    if (!notifications.data?.length) return false;

    for (const notif of notifications.data) {
      if (notif.status === "new") return true;
    }
  };

  const handleClickOutside = (event) => {
    const isOut =
      !ref.current.contains(event.target) ||
      event.target.className === "icon-bell" ||
      event.target.className === "icon-bell-close" ||
      event.target?.className?.baseVal === "svg-bell-close";
    const isOpen = ref.current.className?.includes("show");

    if (ref && isOut && isOpen) {
      queryClient.setQueryData(["getMyNotification"], (oldData) =>
        oldData.map((notif) => {
          let n = { ...notif };
          if (!notif.exhibitor) n.status = "read";

          return n;
        })
      );
      setRead(true);
    }
  };

  return (
    <Dropdown
      ref={ref}
      align="center"
      className="dropdown-icon"
      onClick={(e) => onOpenNotif.mutate()}
    >
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className="section-hero-notifications"
      >
        {hasNewNotifs() || (anyExpired && !read) ? (
          <div className="icon-bell-close">
            <svg
              className="svg-bell-close"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.134 11C18.715 16.375 21 18 21 18H3s3-2.133 3-9.6c0-1.697.632-3.325 1.757-4.525C8.883 2.675 10.41 2 12 2c.337 0 .672.03 1 .09M13.73 21a1.999 1.999 0 0 1-3.46 0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                fill="#EE2E24"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        ) : (
          <div className="icon-bell" />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-arrow">
        <div className="notifications">
          <h3 className="notifications__title">Notifications</h3>
          <ul className="notification-list">
            {notifications.data?.pages?.flat().length === 0 && (
              <li className="notification__item">
                <p className="notification__text">No notifications</p>
              </li>
            )}

            {role === ROLES.exhibitor &&
              booths.data
                ?.filter((booth, index, previous) => {
                  const similar = previous.findIndex((e) =>
                    sameDay(e.subscription_end, booth.subscription_end)
                  );

                  return index === similar;
                })
                ?.sort(
                  (a, b) =>
                    new Date(b.subscription_end) - new Date(a.subscription_end)
                )
                ?.map((booth) =>
                  isDateExpired(booth.subscription_end) ? (
                    <li
                      key={booth.id}
                      className={`notification__item${!read ? " unread" : ""}`}
                    >
                      <div className="notification">
                        <div className="notification__header">
                          <h3 className="notification__type">
                            {!read && <div className="notification-status" />}
                            <span>Subscription alert</span>
                          </h3>
                          <p className="notification__text">
                            Your subscription has expired. Your account and
                            virtual booth has been temporarily disabled. Please
                            contact{" "}
                            <a href="mailto:vanessa.ng@hyatt.com">
                              Hyatt Asia-Pacific
                            </a>{" "}
                            to renew it.
                          </p>
                          <p>{moment(booth.subscription_end).fromNow()}</p>
                        </div>
                      </div>
                    </li>
                  ) : isDateExpiredSoon(booth.subscription_end) ? (
                    <li
                      key={booth.id}
                      className={`notification__item${!read ? " unread" : ""}`}
                    >
                      <div className="notification">
                        <div className="notification__header">
                          <h3 className="notification__type">
                            {!read && <div className="notification-status" />}
                            <span>Subscription alert</span>
                          </h3>
                          <p className="notification__text">
                            Your subscription will expire in one month. Please
                            contact{" "}
                            <a href="mailto:vanessa.ng@hyatt.com">
                              Hyatt-Asia-Pacific
                            </a>{" "}
                            to renew it.
                          </p>
                          <p>
                            {moment(booth.subscription_end).day(-30).fromNow()}
                          </p>
                        </div>
                      </div>
                    </li>
                  ) : null
                )}

            {notifications.data
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((notification, index) => (
                <li
                  key={notification.id}
                  className={`notification__item${
                    notification.status === "new" ? " unread" : ""
                  }`}
                >
                  <div className="notification">
                    <div className="notification__header">
                      <h3 className="notification__type">
                        {notification.status === "new" && (
                          <div className="notification-status" />
                        )}
                        <span>{notifTypes[notification.type]}</span>
                      </h3>
                      <p className="notification__text">{notification.text}</p>
                      <p className="notification__date">
                        {moment(notification.createdAt).fromNow()}
                      </p>
                    </div>
                    {notification.exhibitor && (
                      <div
                        className="notification__link"
                        to={`/exhibit/${notification.exhibitor}`}
                        onClick={() => {
                          readANotifications(notification.id);
                          queryClient.setQueryData(
                            ["getMyNotification"],
                            (oldData) => {
                              const notifIndex = oldData.findIndex(
                                (e) => e.id === notification.id
                              );
                              if (notifIndex !== -1) {
                                oldData[notifIndex].status = "read";
                              }
                              return oldData;
                            }
                          );
                          props.history.push({
                            pathname: `/exhibit/${notification.exhibitor}`,
                            previous: props.previous
                              ? { ...props.previous, scrollTo: "lifestyles" }
                              : {
                                  to: "/",
                                  name: "Home",
                                  scrollTo: "lifestyles",
                                },
                          });
                        }}
                      >
                        Discover
                      </div>
                    )}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default Notifications;
