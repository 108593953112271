import React, { useEffect } from "react";
import { useDataContext } from "../../../store/data";
import Button from "../../ui/button";
import Checkbox from "../../ui/checkbox";
import Input from "../../ui/input";
import Modal from "../../ui/popup";
import Radio from "../../ui/radio";
import TagSelect from "../../ui/select";
import Wrapper from "../wrapper";
import { Container, Tabs, Tab, Col, Row } from "react-bootstrap";
import { useModal } from "../../../store/hooks/useModal";
import AddMore from "../../ui/addMore";

const Ui = () => {
  const { setLoading } = useDataContext();
  const [isShowing, toggleModal] = useModal();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div style={{ background: "#888C9D" }}>
      <Wrapper>
        <Container>
          <h3>Buttons</h3>
          <br />
          <Button
            text="Min size"
            name="Min size"
            theme="secondary"
            size="min"
            margin={["margin-b, margin-r"]}
          />
          <Button
            text="Min size"
            name="Min size"
            theme="secondary"
            size="min"
            margin={["margin-b, margin-r"]}
            isDisabled
          />
          <Button
            text="Full width"
            name="Full width"
            theme="secondary"
            isFullWidth={true}
            margin={["margin-b, margin-r"]}
          />
          <br />
          <Button
            text="Follow"
            name="Follow"
            theme="secondary"
            margin={["margin-b, margin-r"]}
            // icon='star'
          >
            <svg
              viewBox="0 0 17 17"
              stroke="CurrentColor"
              fill="transparent"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.49935 1.41699L10.6881 5.85116L15.5827 6.56658L12.041 10.0162L12.8768 14.8895L8.49935 12.5874L4.12185 14.8895L4.95768 10.0162L1.41602 6.56658L6.3106 5.85116L8.49935 1.41699Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
          <Button
            text="Follow"
            name="Follow"
            theme="secondary"
            margin={["margin-b, margin-r"]}
            isDisabled
            // icon='star'
          >
            <svg
              viewBox="0 0 17 17"
              stroke="CurrentColor"
              fill="transparent"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.49935 1.41699L10.6881 5.85116L15.5827 6.56658L12.041 10.0162L12.8768 14.8895L8.49935 12.5874L4.12185 14.8895L4.95768 10.0162L1.41602 6.56658L6.3106 5.85116L8.49935 1.41699Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
          <br />
          <Button
            text="Discover more"
            name="Discover more"
            margin={["margin-b, margin-r"]}
          />
          <Button
            text="Discover more"
            name="Discover more"
            isDisabled
            margin={["margin-b, margin-r"]}
          />
          <Button
            text="Full width"
            name="Full width"
            isFullWidth={true}
            margin={["margin-b, margin-r"]}
          />
          <br />
          <Button
            text="Cancel"
            name="Cancel"
            theme="secondary-light"
            margin={["margin-b, margin-r"]}
          />
          <Button
            text="Cancel"
            name="Cancel"
            theme="secondary-light"
            isDisabled
            margin={["margin-b, margin-r"]}
          />
          <Button
            text="Cancel"
            name="Cancel"
            theme="secondary-light"
            margin={["margin-b, margin-r"]}
          />
          <br />
          <Button
            text="Full width"
            name="Full width"
            theme="primary-dark"
            isFullWidth={true}
            margin={["margin-b, margin-r"]}
          />
          <Button
            text="Full width"
            name="Full width"
            theme="primary-dark"
            isFullWidth={true}
            isDisabled
            margin={["margin-b, margin-r"]}
          />
          <br />
          <Button
            isLink
            href="#"
            text="Button is Link"
            name="Button is Link"
            theme="secondary"
            size="min"
            margin={["margin-b, margin-r"]}
          />
          <br />
          <h2>Checkboxes</h2>
          <div>
            <Checkbox value="checkboxvalue1" name="checkboxvalue1">
              Default view
            </Checkbox>
          </div>
          <div>
            <Checkbox
              value="checkboxvalue2"
              name="checkboxvalue2"
              color="inverse"
            >
              Inverse view
            </Checkbox>
          </div>
          <div>
            <Checkbox value="checkboxvalue3" name="checkboxvalue3" disabled>
              Disabled view
            </Checkbox>
          </div>
          <h2>Radio</h2>
          <div>
            <Radio value="radiovalue1" name="radiovalue">
              Default view
            </Radio>
          </div>
          <div>
            <Radio value="radiovalue2" name="radiovalue">
              Default view
            </Radio>
          </div>
          <div>
            <Radio value="radiovalue3" name="radiovalue" disabled>
              Disabled view
            </Radio>
          </div>
          <h2>Inputs</h2>
          <Input label="Label" placeholder="Placeholder" />
          <Input label="Label" placeholder="Placeholder" disabled />
          <h2>Selects</h2>
          <TagSelect
            id="uiselect"
            label="Label"
            options={[
              { name: "Option1", value: "Option1" },
              { name: "Option2", value: "Option2" },
            ]}
          />
          <h2>Modals</h2>
          <button onClick={toggleModal}>toggle modal</button>
          <Modal
            isShowing={isShowing}
            onClose={toggleModal}
            title="Séléctionner le compte de destination :"
          >
            <h3>Modal</h3>
          </Modal>
          <h2>Tabs</h2>

          <Tabs defaultActiveKey="brands" className="tabs">
            <Tab eventKey="brands" title="My brands">
              My brands
            </Tab>
            <Tab eventKey="stats" title="Statistics">
              Statistics
            </Tab>
            <Tab eventKey="settings" title="Settings">
              Settings
            </Tab>
          </Tabs>

          <Row>
            <Col lg="3">
              <AddMore id="add1"></AddMore>
            </Col>
            <Col lg="3">
              <AddMore id="add2" disabled></AddMore>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </div>
  );
};

export default Ui;
