import React, { useEffect, useState } from 'react';

const SvgRenderer = ({ svgUrl, width }) => {
  const [svgContent, setSvgContent] = useState('');

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        const response = await fetch(svgUrl);
        const svgString = await response.text();
        setSvgContent(svgString);
      } catch (error) {
        console.error('Failed to fetch SVG:', error);
      }
    };

    fetchSvg();
  }, [svgUrl]);

  return (
    <div className="category-filters-img-wrapper">
      {svgContent && (
        <div
          className={"category-filters-img"}
          dangerouslySetInnerHTML={{ __html: svgContent }}
          style={{ maxWidth: width }}
        >
        </div>
      )}
    </div>
  );
};

export default SvgRenderer;