import React, { useState, useEffect } from "react";
import get from "lodash.get";
import { Link } from "react-router-dom";
import { useDataContext } from "../../../store/data";
// import { LightHeader } from "../../layout/headings";

const CategoryCard = (props) => (
  <Link
    className={`home-category-list-card ${
      props.subcategoryMode ? "subcategories-card" : ""
    }`}
    to={
      // props.subcategoryMode
      //   ? `/category/${props.parentCategory}/${props.slug}`
      //   :
      `/category/${props.slug}`
    }
    style={{ textDecoration: "none", color: "black" }}
  >
    <div className="home-category-list-card__content">
      <div className="home-category-list-card__image">
        <img
          className="home-category-list-card__background"
          src={props.image}
          alt={props.slug}
          onLoad={props.onLoad}
        />
        <div className="home-category-list-card__icon">
          <img
            className="home-category-list-card__icon-image"
            src={props.icon}
            alt={`${props.slug}-icon`}
            onLoad={props.onLoad}
          />
        </div>
      </div>
      <p className="home-category-list-card__name">{props.name}</p>
    </div>
  </Link>
);

const CategoryList = (props) => {
  var { categories, clearDisplayedBrands } = useDataContext();
  const [loadNumber, setLoadNumber] = useState(0);
  const [finishedLoading, setFinishedLoading] = useState(0);
  const [done, setDone] = useState(false);
  const { setLoading } = useDataContext();

  useEffect(() => {
    setDone(false);
    setLoading(true);
    const catsLength = get(props, "subcategories", []).length * 2;
    if (catsLength > 0 && loadNumber !== catsLength) {
      setLoadNumber(catsLength);
      let actualNumberOfAssets = 0;
      props.subcategories.forEach((e) => {
        if (e.icon) {
          actualNumberOfAssets++;
        }
        if (e.background) {
          actualNumberOfAssets++;
        }
      });
      if (actualNumberOfAssets > 0) {
        setLoadNumber(actualNumberOfAssets);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  }, [props.subcategories]);

  const addLoaded = () => {
    setFinishedLoading(finishedLoading + 1);
  };

  useEffect(() => {
    if (!done) {
      if (loadNumber > 0 && finishedLoading >= loadNumber) {
        setDone(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    }
  }, [finishedLoading]);

  useEffect(() => {
    return () => {
      clearDisplayedBrands();
    };
  }, []);

  return (
    <>
      {/* {props.subcategories && <LightHeader text="exhibitors" marginSides />} */}

      <div
        className={`home-category-list ${
          props.subcategories ? "subcategory-list" : ""
        }`}
      >
        {props.subcategories &&
          props.subcategories.map((s) => (
            <CategoryCard
              key={s.slug}
              image={get(s, "background.url", "")}
              icon={get(s, "icon.url", "")}
              name={s.name}
              parentCategory={props.parentCategory}
              slug={s.slug}
              subcategoryMode
              onLoad={addLoaded}
            />
          ))}
        {!props.subcategories &&
          categories.map((c) => (
            <CategoryCard
              key={c.slug}
              image={`assets/images/bg420-${c.slug}.png`}
              icon={`/assets/images/${c.slug}-132.svg`}
              name={c.name}
              slug={c.slug}
            />
          ))}
      </div>
    </>
  );
};

export default CategoryList;
