import React from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "./modal.scss";

// TODO: move new modal to /modal folder after old modals replaced

const Modal = ({
  isShowing,
  toggleModal,
  children,
  icon,
  onClose,
  showCloseButton,
  title,
  video,
  width,
  ...props
}) => {
  return (
    <Rodal
      visible={isShowing}
      onClose={(e) => {
        if(onClose){
          onClose(e)
        }
      }}
      closeOnEsc
      showCloseButton={video ? true : showCloseButton}
      width={video ? 923 : width}
      {...props}
    >
      <header className="modal-header">
        {icon ? <div className={`modal-icon icon-${icon}`} /> : null}
      </header>
      <div className="modal-body">
        {title ? <h3>{title}</h3> : null}
        {children}
      </div>
    </Rodal>
  );
};

export default Modal;
