import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { FullWidthDivider } from "../../layout/divider";
import { BlueButton } from "../../ui/buttons";
import { TextInput } from "../../ui/form";
import { useAuthContext } from "../../../store/auth";
import Wrapper from "../wrapper";
import Subheader from "../exhibit/Subheader";

const Edit = (props) => {
  const { updateMyself, openModal } = useAuthContext();

  const handleUpdateMyself = () => {
    const name = document.getElementById("accountname").value;
    const email = document.getElementById("email").value;
    const data = {};
    if (name) data.name = name;
    if (email) data.email = email;

    updateMyself(data).then((res) => {
      if (res) {
        if (name) {
          localStorage.setItem("name", name);
        }
        if (email) {
          localStorage.setItem("email", email);
        }
        openModal("Account information updated successfully");
        props.history.push("/myaccount");
      }
    });
  };

  return (
    <Wrapper>
      <Subheader
        logo="/assets/images/icon-myaccount.png"
        logoNotClickable
        titleNotClickable
      />
      <div className="edit-profile">
        <div>
          <TextInput
            id="accountname"
            defaultValue={localStorage.getItem("name")}
          />
        </div>
        <div>
          <TextInput id="email" defaultValue={localStorage.getItem("email")} />
        </div>
      </div>
      <FullWidthDivider />
      <div className="edit-profile-controls">
        <BlueButton text="save information" onClick={handleUpdateMyself} />
        <div className="edit-profile__back-button">
          <Link
            to="/myaccount"
            style={{ textDecoration: "none", color: "#151f6d" }}
          >
            {"< back"}
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(Edit);
