import React from "react";
import { Link } from "react-router-dom";
import { ROLES } from "../../constants/roles";
import { Container, Col, Row } from "react-bootstrap";

function FooterList(props) {
  const links = props.links;
  const listItems = links.map((link, index) => (
    <li className="footer__item" key={index}>
      <Link to={link.url} className="footer__link">
        {link.name}
      </Link>
    </li>
  ));
  return <ul className={props.class}>{listItems}</ul>;
}

// hardcoded data
const linksLeft = [
  {
    name: "Food",
    url: "/category/food",
  },
  {
    name: "Drinks",
    url: "/category/drinks",
  },
  {
    name: "Spirits",
    url: "/category/spirits",
  },
  {
    name: "Wine",
    url: "/category/wine",
  },
  {
    name: "Coffee & tea",
    url: "/category/coffee",
  },
];

const linksRight = [
  {
    name: "Meat",
    url: "/category/meat",
  },
  {
    name: "Bakery & confectionary",
    url: "/category/bakery",
  },
  {
    name: "Hygiene, cleaning & supplies",
    url: "/category/hygiene",
  },
  {
    name: "Hospitality technology & consultants",
    url: "/category/hospitality",
  },
  {
    name: "Operating equipment",
    url: "/category/equipment",
  },
];

const exhibitorMenuLeft = [
  {
    name: "Account",
    url: "/myaccount/brands",
  },
  {
    name: "Products",
    url: "/search",
  },
  // {
  //   name: "My brands",
  //   url: "/myaccount/brands",
  // },
  // {
  //   name: "Statistics",
  //   url: "/myaccount/stats",
  // },
  {
    name: "Hotel list",
    url: "/hotels",
  },
  {
    name: "Terms & conditions",
    url: "/terms",
  },
];

const exhibitorMenuRight = [
  // {
  //   name: "Brand list",
  //   url: "/search",
  // },
  // {
  //   name: "Terms & conditions",
  //   url: "/terms",
  // },
];

const adminMenu = [
  {
    name: "Products",
    url: "/search",
  },
  {
    name: "Following",
    url: "/favorites",
  },
  {
    name: "Partners",
    url: "/partners",
  },
  {
    name: "Terms & conditions",
    url: "/terms",
  },
];

const Footer = (props) => {
  return (
    <>
      {!props.hide && (
        <div className="footer" id="footer">
          <Container>
            <div className="footer__inner">
              <Link to="/" className="footer__logo">
                <img
                  src="/assets/images/logo-hde-white.svg"
                  alt="logo HDE."
                  width="58"
                  height="54"
                />
              </Link>

              {props.role === ROLES.exhibitor ? (
                <Row className="footer__main footer__main--row">
                  <FooterList
                    links={exhibitorMenuLeft}
                    className="footer__list"
                  />
                </Row>
              ) : (
                // Admin Menu
                <Col md="7" className="footer__main">
                  <Col md="8" lg="8">
                    <div className="display-when-large">
                      <Row>
                        <Col lg="6">
                          <FooterList
                            links={linksLeft}
                            className="footer__list footer__list--1"
                          />
                        </Col>
                        <Col lg="6">
                          <FooterList
                            links={linksRight}
                            className="footer__list footer__list--2"
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="display-when-small">
                      <Col lg="6">
                        <FooterList
                          links={linksLeft}
                          className="footer__list footer__list--1"
                        />
                      </Col>
                      <Col lg="6">
                        <FooterList
                          links={linksRight}
                          className="footer__list footer__list--2"
                        />
                      </Col>
                    </div>
                  </Col>
                  <Col md={{ span: 8 }} lg={{ span: 4, offset: 1 }}>
                    <FooterList
                      links={adminMenu}
                      className="footer__list footer__list--3"
                    />
                  </Col>
                </Col>
              )}
              <Link to="/" className="footer__logo">
                <img
                  src="/assets/images/logo-hyatt.svg"
                  alt="logo HYATT."
                  width="176"
                  height="43"
                />
              </Link>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default Footer;
