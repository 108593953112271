import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-query";
import { getPartnerDetail, getPopularProducts } from "../../../network/API";
import { useAuthContext } from "../../../store/auth";
import Loader from "../../layout/loader";
import queryString from "query-string";
import ExhibitorList from "../category/exhibitorList";
import tracking from "../../../utils/tracking";
import { ROLES } from "../../../constants/roles";
import { Container, Row, Col } from "react-bootstrap";
import Wrapper from "../wrapper";
import PartnerHeader from "./partnerHeader";
import { getNthItemFromPathname } from "../../../utils";
import ProductCardSlider from "../../ui/productCardSlider";
import ProductCard from "../../ui/productCardSlider/productCard";

const ViewPartnerPage = (props) => {
  // const search = get(query, "q", "");
  // const { searchResults, searchBrands, myBooth } = useDataContext();
  const { role } = useAuthContext();

  const [filterTags, setFilterTags] = useState([]);
  const [filterCategory, setFilterCategory] = useState();
  const [filterSubcategory, setFilterSubcategory] = useState();

  const pathname = props.history.location.pathname;
  const previous = props.history.location.previous;
  const id = getNthItemFromPathname(pathname, 2);


  useEffect(() => {
    const query = queryString.parse(props.history.location.search);
    if (props.history.location.needUpdate) {
      if (query.category) setFilterCategory(query.category);
      if (query.subcategory) setFilterSubcategory(query.subcategory);
      if (query.tags)
        setFilterTags(Array.isArray(query.tags) ? query.tags : [query.tags]);
    }
  }, [props.history.location.search]);

  useEffect(() => {
    const query = queryString.parse(props.history.location.search);
    if (query.category) setFilterCategory(query.category);
    if (query.subcategory) setFilterSubcategory(query.subcategory);
    if (query.tags)
      setFilterTags(Array.isArray(query.tags) ? query.tags : [query.tags]);
    tracking.pageView("/partners");
  }, []);


  const partner = useQuery(["getPartnerDetail", { id }], getPartnerDetail, {
    refetchOnWindowFocus: false,
    enabled: role !== ROLES.exhibitor,
  });

  const popularProducts = useQuery(["getPopularProducts", {user: id}], getPopularProducts, {
    refetchOnWindowFocus: false,
    enabled: role !== ROLES.exhibitor,
  });

  return (
    <>
      <Loader show={partner.isLoading || popularProducts.isLoading} />
      <Wrapper>
        <PartnerHeader
          name={partner.data?.name}
          previous={previous}
          logo={partner.data?.logo}
          description={partner.data?.branhd}
        />
        <section className="section background-white">
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                  <>
                    <div className="exhibitor-list-header">
                      <span className="exhibitor-list-header__title">
                        Associated brands
                      </span>
                      <span className="exhibitor-list-header__brands_count">
                        Total: {partner.data?.exhibitorCount || 0}
                      </span>
                    </div>
                    <ExhibitorList
                      tags={filterTags}
                      partner={partner.data?.id}
                      category={filterCategory}
                      subcategory={filterSubcategory}
                      canView={
                        role !== ROLES.exhibitor
                      }
                      text="Sorry, no results found..."
                      noButton={true}
                    />
                  </>
                
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section popular-products-section background-muted">
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                <h2 className="h2 title popular-products-title">Popular products</h2>
              </Col>
            </Row>
            <Row>
              <Col xxl={{ span: 10, offset: 1 }}>
                <div className="popular-products-container">
                  <ProductCardSlider
                    slidesData={
                      popularProducts?.data
                        ? popularProducts?.data?.map((ex) => {
                            return {
                              name: ex?.name,
                              uri: ex?.product?.uri,
                              id: ex.id,
                              description: ex?.description,
                              isVideo: false,
                              exhibitor: ex.exhibitor
                            };
                          })
                        : []
                    }
                    slidesNumberDesktop={4}
                    SlideComponent={ProductCard}
                    noModalSlide={true}
                    handleSlideClick={(id, videoId) =>{
                      props.history.push({
                        pathname: `/exhibit/${id}`,
                        previous: { to: `/partner/${partner.data?.id}`, name: `Partner ${partner?.data?.name}`, scrollTo: "products" },
                        defaultVideo: videoId,
                      })
                    }
                  }
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Wrapper>
    </>
  );
};

export default withRouter(ViewPartnerPage);
