import React, { useEffect, useState } from "react";
import { useDebounceFn } from "ahooks";
import { AutoComplete as AntdAutoComplete } from "antd";
import lodash from "lodash";
import protoTypes from "prop-types";

function EndAutoComplete(props) {

    const { value: propValue, onChange: propOnChange, defaultOptionsMap, fetchData, onAgoliaSearch, ...otherProps } = props;

    const [value, setValue] = useState(propValue);

    const [options, setOptions] = useState();

    const [optionsMap, setOptionsMap] = useState({});

    useEffect(() => {
        setValue(props.value)
    },[props.value])

    //初始化value
    useEffect(() => {
        if (defaultOptionsMap) {
            setValue(defaultOptionsMap[propValue]);
        }
    }, [defaultOptionsMap])

    //更新options map
    useEffect(() => {
        if (lodash.isArray(options)) {
            let newOptionMap = {};
            options.forEach(item => {
                const { value, label } = item;
                newOptionMap[label] = value;
            })
            setOptionsMap(newOptionMap);
        }
    }, [options])

    const _handleSearch = value => {
        const promise = fetchData(value);
        if (promise && promise.then) {
            promise.then(data => {
                setOptions(data);
            }).catch(e => {
                console.error("AutoComplete Fetch Data Error", e)
                setOptions([]);
            })
        }
    }

    const { run: handleSearch } = useDebounceFn(_handleSearch, { wait: 500 });

    const onSearch = value => {
        if (!value.trim()) {
            setOptions([]);
            return;
        }
        if (lodash.isFunction(fetchData)) {
            handleSearch(value);
        }
    }

    const onChange = (value) => {
        if (lodash.isFunction(propOnChange)) {
            propOnChange(optionsMap[value] || -999999);
        }
        setValue(value);
    }

    const onSelect = (value, option) => {
        if (lodash.isFunction(propOnChange)) {
            propOnChange(value);
        }
        setValue(option.label);
        onAgoliaSearch(value);
    }

    return (
        <AntdAutoComplete
            options={options}
            onSearch={onSearch}
            onSelect={onSelect}
            onChange={onChange}
            value={value}
            {...otherProps}
        >

        </AntdAutoComplete>
    )
}

EndAutoComplete.prototype = {
    fetchData: protoTypes.func,//异步获取数据
    defaultOptionsMap: protoTypes.object,//用于键盘精灵初始化赋值
}

export default EndAutoComplete;