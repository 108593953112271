import React, { useState } from "react";
import cn from "classnames";
import "./inputFile.scss";
import { useMutation } from "react-query";
import { useModal } from "../../../store/hooks/useModal";
import Modal from "../../ui/popup";
import Button from "../../ui/button";

const InputFile = ({
  id,
  placeholder,
  fullwidth,
  large,
  fileSize = 50,
  formats,
  uploaded,
  request,
  onFileUploaded,
  onDelete,
  accept,
  noupload
}) => {
  const [isShowing, toggleModal] = useModal();
  const [invalidFile, setInvalidFile] = useState();
  const onUploadFile = useMutation((values) => request(values), {
    onSuccess: (data) => onFileUploaded(data),
    onError(e) {
      if (e.response?.status === 400) {
        setInvalidFile(
          e?.response?.data?.message ?? 'The file is invalid. Please try again'
        );
        toggleModal();
      }
    },
  });

  const handleFiles = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    if (file.size >= fileSize * 1024 * 1024) {
      toggleModal();
      return;
    }
    const formData = new FormData();
    formData.append("files", file);

    onUploadFile.mutate(formData);
    event.target.value = '';
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <>
      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        showCloseButton={true}
        width={420}
      >
        <h2 className="h2 title text-center">
          {invalidFile || 'The file is too heavy, please compress it and upload again'}
        </h2>
        <div className="text-center">
          <Button theme="primary" onClick={toggleModal}>
            Close
          </Button>
        </div>
      </Modal>

      {uploaded ? (
        <div
          className={cn("input-file", {
            fullwidth: fullwidth,
            large: large,
            uploaded: uploaded,
          })}
        >
          {!noupload ? (
            <input type="file" id={id} onChange={handleFiles} accept={accept} />
          ) : null}
          <div className="input-file-placeholder">
            {onUploadFile.isLoading ? "is sending..." : placeholder}
          </div>
          <button
            onClick={handleDelete}
            className="input-file-icon icon-trash"
          />
          {formats ? <div className="input-file-formats">{formats}</div> : null}
        </div>
      ) : (
        <label
          className={cn("input-file", {
            fullwidth: fullwidth,
            large: large,
          })}
        >
          <input type="file" id={id} onChange={handleFiles} accept={accept} />
          <div className="input-file-placeholder">
            {onUploadFile.isLoading ? "is sending..." : placeholder}
          </div>
          <div className="input-file-icon icon-upload"></div>
          {formats ? <div className="input-file-formats">{formats}</div> : null}
        </label>
      )}
    </>
  );
};

export default InputFile;
