import React, { useState } from "react";
import cn from "classnames";
import "./uploadFile.scss";
import { useMutation } from "react-query";
import { useModal } from "../../../store/hooks/useModal";
import Modal from "../popup";
import Button from "../button";
import TruncateMarkup from "react-truncate-markup";

const UploadFile = ({
  id,
  placeholder,
  fullwidth,
  large,
  fileSize = 50,
  formats,
  uploaded,
  request,
  onFileUploaded,
  onDelete,
  accept,
  noupload,
  ...otherProps
}) => {
  const [isShowing, toggleModal] = useModal();
  const onUploadFile = useMutation((values) => request(values), {
    onSuccess: (data) => onFileUploaded(data),
  });

  // const handleFiles = (event) => {
  //   const formData = new FormData();
  //   formData.append("files", event.target.files[0]);

  //   onUploadFile.mutate(formData);
  // };
  const handleFiles = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    if (file.size >= fileSize * 1024 * 1024) {
      toggleModal();
      return;
    }
    const formData = new FormData();
    formData.append("files", file);

    onUploadFile.mutate(formData);
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <>
      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        showCloseButton={true}
        width={420}
      >
        <h2 className="h2 title text-center">
          The file is too heavy, please compress it and upload again
        </h2>
        <div className="text-center">
          <Button theme="primary" onClick={toggleModal}>
            Close
          </Button>
        </div>
      </Modal>
      {uploaded ? (
        <div
          className={cn("input-file", {
            fullwidth: fullwidth,
            large: large,
            uploaded: uploaded,
          })}
        >
          {!noupload ? (
            <input type="file" id={id} onChange={handleFiles} accept={accept} />
          ) : null}
          <div className="input-file-placeholder">
            <TruncateMarkup lines={1}>
              <div>{onUploadFile.isLoading ? "is sending..." : placeholder}</div>
            </TruncateMarkup>
          </div>
          <button
            onClick={handleDelete}
            className="input-file-icon icon-trash"
          />
          {formats ? <div className="input-file-formats">{formats}</div> : null}
        </div>
      ) : (
        <label
          className={cn("input-file", {
            fullwidth: fullwidth,
            large: large,
          })}
        >
          <input type="file" id={id} onChange={handleFiles} accept={accept} />
          <div className="input-file-placeholder inside-formats">
            <div className="input-file-texts">
              {onUploadFile.isLoading ? "is sending..." : placeholder}
            </div>
            {formats ? <div className="input-file-formats">{formats}</div> : null}
          </div>
          <div className="input-file-icon icon-upload"></div>
        </label>
      )}
    </>
  );
};

export default UploadFile;
