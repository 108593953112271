import Home from "../components/pages/home";
import Account from "../components/pages/account";
import Search from "../components/pages/search";
import Category from "../components/pages/category";
import Exhibit from "../components/pages/exhibit";
import ViewExhibit from "../components/pages/exhibit/view";
import Edit from "../components/pages/edit";
import Preview from "../components/pages/preview";
import Terms from "../components/pages/terms";
import NotFound from "../components/pages/404";
import PublicPage from "../components/pages/public";
import ViewPartnerPage from "../components/pages/partner";
import Ui from "../components/pages/ui";
import FavoritesPage from "../components/pages/favorites";
import HotelListPage from "../components/pages/hotelList";
import PartnerListPage from "../components/pages/partnerList";

export default [
  {
    path: "/",
    exact: true,
    component: Home,
    header: "default",
  },
  // {
  //   path: "/public",
  //   exact: true,
  //   component: PublicPage,
  // },
  {
    path: "/myaccount",
    exact: true,
    component: Account,
  },
  {
    path: "/myaccount/:menu",
    exact: true,
    component: Account,
  },
  {
    path: "/search",
    exact: true,
    component: Search,
  },
  {
    path: "/category/:category",
    exact: true,
    component: Category,
  },
  {
    path: "/category/:category/:subcategory",
    exact: true,
    component: Category,
  },
  {
    path: "/exhibit/:id/all",
    exact: true,
    component: Exhibit,
  },
  {
    path: "/exhibit/:id",
    exact: true,
    component: ViewExhibit,
  },
  {
    path: "/exhibit/:id/edit",
    exact: true,
    component: Edit,
  },
  {
    path: "/exhibit/:id/preview",
    exact: true,
    component: Preview,
  },
  {
    path: "/exhibit/:id/:menu",
    exact: true,
    component: Exhibit,
  },
  {
    path: "/favorites",
    exact: true,
    component: FavoritesPage,
  },
  {
    path: "/hotels",
    exact: true,
    component: HotelListPage,
  },
  {
    path: "/partners",
    exact: true,
    component: PartnerListPage,
  },
  {
    path: "/partner/:id",
    exact: true,
    component: ViewPartnerPage,
  },
  // {
  //   path: "/partner-apply",
  //   exact: true,
  //   component: PartnerApplyPage,
  // },
  {
    path: "/terms",
    exact: true,
    component: Terms,
  },
  {
    path: "/ui",
    exact: true,
    component: Ui,
  },
  {
    component: NotFound,
  },
];
