import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, A11y } from "swiper";
import { baseURL } from "../../../network/API";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "./imageSlider.scss";
import cn from "classnames";

SwiperCore.use([Navigation, A11y]);

const ImageSlider = ({ slidesData, type, selectedIndex, noModalSlide }) => {
  // ugly hack to make swiper work inside modal
  setTimeout(() => {
    if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      navigator.appVersion.indexOf("Trident/") > 0
    ) {
      var evt = document.createEvent("UIEvents");
      evt.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(evt);
    } else {
      window.dispatchEvent(new Event("resize"));
    }
  });

  return (
    <Swiper
      onResize={(swiper) => {
        swiper.slideTo(selectedIndex);
        swiper.setTransition(0);
      }}
      className={
        cn("image-slider", {
          [`no-modal-slide`] : !!noModalSlide
        }
      )}
      modules={[Navigation, A11y]}
      navigation={{
        nextEl: ".image-swiper-button-next",
        prevEl: ".image-swiper-button-prev",
      }}
      allowTouchMove={noModalSlide ? false : true}
    >
      <button className="image-swiper-button-prev" />
      <button className="image-swiper-button-next" />
      {slidesData.map((slide, index) => {
        const imgUri = type === "products" ? slide?.uri : slide?.uri;

        return (
          <SwiperSlide key={index}>
            <div
              className="modal-image"
              style={{
                backgroundImage: `url(${
                  baseURL + "/storage/" + type + "/" + imgUri
                })`,
              }}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ImageSlider;
