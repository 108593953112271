import React from 'react'

const BoothTags = (props) => (
	<div className="booth-tags">
		{props.tags.map(t => (
			<span className="tag booth-tags__tag" key={t.name}>{t.name}</span>
		))}
	</div>
)

export default BoothTags