import React, { useEffect, useState } from "react";
import Wrapper from "../pages/wrapper";

const Loader = ({ show }) => {
  const [atLeastASecond, setAtLeastASecond] = useState(false);

  useEffect(() => {
    if (show || !atLeastASecond) document.body.classList.toggle("noscroll");
    return () => {
      document.body.classList.remove("noscroll");
    };
  });

  useEffect(() => {
    setTimeout(() => {
      setAtLeastASecond(true);
    }, 1000);
  }, []);

  if (!show && atLeastASecond) return null;

  return (
    <Wrapper>
      <div className="loader-container noscroll">
        <div className="loader">
          <img
            className="loader__one"
            src="/assets/images/loader1.png"
            alt=""
          />
          <img
            className="loader__two"
            src="/assets/images/loader2.png"
            alt=""
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Loader;
