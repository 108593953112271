import React, { useState } from "react";
import { useAuthContext } from "../../../store/auth";
import { Button } from "react-bootstrap";
import MediaWrapper from "../../layout/mediaWrapper";
import TruncateMarkup from "react-truncate-markup";
import get from "lodash.get";
import Tags from "../../ui/tags";
import { withRouter } from "react-router-dom";
import { baseURL } from "../../../network/API";


const SimilarBrandCard = withRouter((props) => {
    const [isOwnBooth, setIsOwnBooth] = useState(false);
    const pathname = props.history.location.pathname;
  
    return (
      <div
        onClick={(e) => {
          if (
            (props.canView || isOwnBooth) &&
            e.target.className !== "tag" &&
            !e.target.className.includes("unfollow")
          ) {
            props.history.push({
              pathname: `/exhibit/${props.id}`,
              previous: props.previous
                ? props.previous
                : { to: "/search" + props.searchQuery, name: "Search" },
            });
          }
        }}
        className={`exhibitor-card${
          props.canView || isOwnBooth ? " exhibitor-clickable" : ""
        }`}
        style={{ textDecoration: "none", color: "black" }}
      >
        <div
          className="exhibitor-background"
          style={{
            backgroundImage: `url(${props.background})`,
          }}
        >
          {props.following && (
            <button
            onClick={(e) => {
              // props.onFollow();
              // e.stopPropagation();
              return false;
            }} 
            className="exhibitor-upper-favorite">
              {props.following ? (
                <span className="icon-star-fill" style={{ color: "#FFBF00" }} />
              ) : (
                <span className="icon-star" />
              )}
            </button>
          )}
          {(pathname === "/favorites") ? (
            <Button
              onClick={props.onUnfollow}
              className="exhibitor-card__unfollow"
              theme="default"
              text="Unfollow"
              clickRefClass="unfollow"
            />
          ) : null}
        </div>
        <div className="similar_exhibitor-upper">
          <div className="similar_exhibitor-upper__logo">
            <MediaWrapper
              square
              src={`${baseURL}/storage/logo/${get(props, "logo.uri", "")}`}
              onLoad={props.onLoad}
            />
          </div>
          <div className="similar_exhibitor-upper-info">
            <h3 className="similar_exhibitor-upper-info__title">{props.name}</h3>
            {get(props, "tags", []).length > 0 && (
              <div className="tag-list">
                <Tags tags={get(props, "tags", [])} />
              </div>
            )}
              <div className="exhibitor-upper-info__description">
                {props.countries && props.countries.length > 0 && (
                  <p className="products__available-in">
                    Available in:{" "}
                     <TruncateMarkup lines={1}>
                      <span className="products__country">
                        {props.countries.map((c) => c.name).filter((c) => c !== undefined).join(", ")}
                      </span>
                    </TruncateMarkup>
                  </p>
                )}
              </div>
          </div>
        </div>
        <div className="exhibitor-lower">
          <div>
            <TruncateMarkup lines={3}>
              <p className="exhibitor-text">{props.description}</p>
            </TruncateMarkup>
          </div>
          
          {/* <div
            className="exhibitor-partner"
            onClick={(e) => {
              e.stopPropagation();
              props.history.push({
                pathname: `/partner/${props?.partner._id}`,
                previous: props.previous
                  ? props.previous
                  : { to: "/partners" + props.searchQuery, name: "Search" },
              });
            }}
          >
            <div className="partner__background" style={{ backgroundImage: "url(" + getPartnerLogo(get(props, "partner.logo.uri", "")) + ")" }} />
            <div className="partner__name">{get(props, "partner.name", "")}</div>
          </div> */}
        </div>
      </div>
    );
  });

  export default SimilarBrandCard;