import React from "react";
import { baseURL } from "../../../network/API";
import { withRouter } from "react-router-dom";

const OfferCard = ( props ) => {
  return (
    <div
      onClick={() => {
        if(props.handleSlideClick){
          props.handleSlideClick({
            media: {
              type: 'image',
              uri: `${baseURL}/storage/products/${props.product.product?.uri}`
            }
          })
          return;
        }
        if(!!!props.canView) return;
        props.history.push({
          pathname: `/exhibit/${props.exhibitor}`,
          previous: props.previous
            ? props.previous
            : { to: "/", name: "Home", scrollTo: "products" },
        })
      }}
      className="card-discover"
    >
      <div
        className="card-discover__image"
        style={{
          backgroundImage: `url(${baseURL}/storage/products/${props.product.product?.uri})`,
        }}
      />
      <h3 className="card-discover__title">{props.exhibitorName}</h3>
    </div>
  );
};

export default withRouter(OfferCard);
