import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, A11y } from "swiper";
import cn from "classnames";
import AddMore from "../../ui/addMore";
import ImageSlider from "../../ui/imageSlider";
import Modal from "../../ui/popup";
import { uploadLifestyle } from "../../../network/Upload";
import { baseURL } from "../../../network/API";
import { useModal } from "../../../store/hooks/useModal";
import Input from "../../ui/input";
import Textarea from "../../ui/textarea";
import moment from "moment";
import get from "lodash.get";

SwiperCore.use([Navigation, A11y]);

const LifestyleCard = ( props ) => {
  return (
    <div
      // className="lifestyle__image"
      // style={{
      //   backgroundImage: `url(${baseURL + "/storage/lifestyle/" + props?.lifestyle.uri})`,
      // }}
      onClick={e => {
        if(props.onImageClick){
          props.onImageClick(e)
        }
      }}
    >
      {props.editMode ? (<>

        {props?.lifestyle?.type.includes("video") ? (
          <div
            className="lifestyle__video"
          >
            <video
              src={baseURL + "/storage/lifestyle/" + props?.lifestyle.uri}
            />
            <button
              className="exhibit-lifestyle__video-play icon-play"
              onClick={props.onVideoClick}
            />
            <button onClick={props.onDelete} className="exhibit-delete">
              <div className="exhibit-delete-icon icon-trash" />
            </button>
          </div>
        ) : (
          <div
            className="lifestyle__image"
            style={{
              backgroundImage: `url(${baseURL + "/storage/lifestyle/" + props?.lifestyle.uri})`,
            }}
          >
            <button onClick={props.onDelete} className="exhibit-delete">
              <div className="exhibit-delete-icon icon-trash" />
            </button>
          </div>
        )}
        

        {/* <InputFile
          id="inputFile2"
          placeholder="Drag and drop a new file"
          fullwidth
          request={uploadLifestyle}
          onFileUploaded={(file) => {
              let newLifestyles = [...props.lifestyles];
              newLifestyles[props.index].lifestyle = file;
              props.onBoothEdited({
                lifestyles: newLifestyles,
              });
            }   
          }
        /> */}
        <div className="lifestyle-input-wrapper">
          <div className="lifestyle-left-container">
            <Input
              value={props.name}
              onChange={(e) => {
                let newLifestyles = [...props.lifestyles];
                newLifestyles[props.index].name = e.target.value;
                props.onBoothEdited({
                  lifestyles: newLifestyles,
                });
              }}
              fullwidth
              maxLength={85}
              error={props.errors?.lifestyles && props.errors?.lifestyles[props.index]}
              errorMessage={
                props.errors?.lifestyles ? props.errors?.lifestyles[props.index] : ""
              }
            />
            <Textarea
              value={get(props, "description", "")}
              rows="7"
              fullwidth
              onChange={(e) => {
                let newLifestyles = [...props.lifestyles];
                newLifestyles[props.index].description = e.target.value;
                props.onBoothEdited({
                  lifestyles: newLifestyles,
                });
              }}
              placeholder="Tell us more about your lifestyle"
            />
            <div className="lifestyle-posted">
              { props.uploadedAt ? moment(props.uploadedAt).format("D MMMM, YYYY") : moment().format("D MMMM, YYYY") }
            </div>
          </div>
          <div className="lifestyle-right-container">
            <button
              onClick={(e) => {
                // props.onFollow();
                // e.stopPropagation();
                return false;
              }} 
              className="lifestyle-like">
                {props.like ? (
                  <span className="icon-like-fill" style={{ color: "#E43373" }} />
                ) : (
                  <span className="icon-like" />
                )}
            </button>
            <div className="lifestyle-like-count">
              {props.likeCount ? props.likeCount : 0} likes
            </div>
          </div>
        </div>

      </>) : null}
    </div>
  );
};

const lifestyleCardsPeRow = (lifestyle) => {
  if (lifestyle.length > 6) {
    return 4;
  } else if (lifestyle.length < 7) {
    return 3;
  } else {
    return 2;
  }
};

const Lifestyle = ({ editMode, lifestyles, onBoothEdited }) => {
  const [isShowing, toggleModal] = useModal();
  const [selectedIndex, setSelectedIndex] = useState();

  return (
    <div
      className={cn("exhibit-lifestyle", [`exhibit-lifestyle--${editMode}`])}
    >
      <h2 className="h2 title lifestyle__title">Check out our moments</h2>
      <Swiper
        className="lifestyle"
        modules={[Navigation, A11y]}
        spaceBetween={16}
        slidesPerView={1.5}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        breakpoints={{
          540: {
            slidesPerView: 2.5,
            spaceBetween: 12,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 12,
          },
          1024: {
            slidesPerColumnFill: "row",
            slidesPerView: "auto",
            slidesPerColumn: lifestyleCardsPeRow(lifestyles),
            slidesPerGroup: lifestyleCardsPeRow(lifestyles),
            grid: {
              rows: 2,
            },
            spaceBetween: 16,
            allowTouchMove: false,
            loop: false,
          },
        }}
      >
        <button className="swiper-button-prev" />
        <button className="swiper-button-next" />
        {editMode ? (
          <SwiperSlide className="add-more-desktop">
            <li>
              <AddMore
                id="lifestyle"
                fileSize={200}
                request={uploadLifestyle}
                onFileUploaded={(file) => {
                    onBoothEdited({
                      // lifestyles: [...lifestyles, { lifestyle: file }],
                      lifestyles: [].concat({ title: "", lifestyle: file, tmpId: new Date().toISOString() }, lifestyles),
                    })
                  }
                }
              />
            </li>
          </SwiperSlide>
        ) : null}
        {lifestyles.map((data, index) => (
          <SwiperSlide key={data?.id || data?.tmpId || index}>
            <LifestyleCard
              {...data}
              index={index}
              lifestyles={lifestyles}
              editMode={editMode}
              onDelete={() => {
                let newLifestyles = [...lifestyles];
                newLifestyles.splice(index, 1);
                onBoothEdited({
                  lifestyles: newLifestyles,
                });
              }}
              onImageClick={
                editMode
                  ? null
                  : () => {
                      setSelectedIndex(index);
                      toggleModal(true);
                    }
              }
              onVideoClick={
                editMode
                  ? () => {
                      // setSelectedIndex(index);
                      // toggleModal(true);
                    }
                  : null
              }
              onBoothEdited={onBoothEdited}
            />
          </SwiperSlide>
        ))}
        
      </Swiper>
      {editMode ? (
        <AddMore
          className="add-more add-more-mobile"
          id="lifestyle"
          request={uploadLifestyle}
          fileSize={200}
          onFileUploaded={(file) =>{
            console.log(lifestyles)
            onBoothEdited({
              lifestyles: [{ lifestyle: file, tmpId: new Date().toISOString() }, ...lifestyles],
            })}
          }
        />
      ) : null}

      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        width={656}
        className="modal-image-slider"
      >
        <ImageSlider
          slidesData={lifestyles}
          type="lifestyle"
          selectedIndex={selectedIndex}
        />
      </Modal>
    </div>
  );
};

export default Lifestyle;
