import { useState } from "react";

export const useFilterMenu = () => {
  const [isShowingMenu, setIsShowingMenu] = useState(false);

  function toggleFilterMenu() {
    setIsShowingMenu(!isShowingMenu);
    const body = document.querySelectorAll("body")[0];
    if (isShowingMenu) {
      body.style.overflow = "auto";
    } else {
      body.style.overflow = "hidden";
    }
  }

  return {
    isShowingMenu,
    toggleFilterMenu,
  };
};
