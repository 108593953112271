import React from "react";
import Button from "../../ui/button";

const NotAvailable = () => (
  <div className="not-available">
    <h1>404</h1>
    <h2>The requested page could not be found.</h2>

    <Button isLink to="/">
      Return to homepage
    </Button>
  </div>
);

export default NotAvailable;
