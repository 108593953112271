import React from "react";

const Information = (props) => (
  <>
    <div className="information">
      <div className="information-display">
        <div className="information-display__name">
          {props.name || "account name"}
        </div>
        {/* <div className="information-display__email">
          {props.email || 'email'}
        </div> */}
      </div>
    </div>
    {/* <div className="centered-section">
      <div className="information-controls">
        <BlueButton
          text="edit information"
          onClick={() => props.menuHandler("edit")}
        />
        <BlueButton
          text="change password"
          onClick={() => props.menuHandler("password")}
        />
      </div>
    </div> */}
  </>
);

export default Information;
