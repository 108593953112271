import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { FullWidthDivider } from "../../layout/divider";
import { BlueButton } from "../../ui/buttons";
import { TextInput } from "../../ui/form";
import queryString from "query-string";
import { useAuthContext } from "../../../store/auth";
import { useDataContext } from "../../../store/data";
import Button from "../../ui/button";
import Input from "../../ui/input";
import { Formik, Form } from "formik";

const Reset = (props) => {
  const q = queryString.parse(props.location.search);
  const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const { resetPassword, requestResetPassword, openModal } = useAuthContext(0);

  const handlePasswordReset = (token, pw) => {
    // let pw;
    // let cpw;
    // if (document.getElementById("password")) {
    //   pw = document.getElementById("password").value;
    //   cpw = document.getElementById("confirmpassword").value;
    // }

    // if (pw === cpw) {
    resetPassword(token, pw)
      .then(() => {
        // props.history.push("/auth");
        setIsSent(true);
      })
      .catch((err) => {
        setIsSent(true);
        setIsError(true);
      });
    // }
  };

  // const requestReset = () => {
  //   let email;
  //   if (document.getElementById("email")) {
  //     email = document.getElementById("email").value;
  //   }
  //   requestResetPassword(email).then(() => {
  //     openModal(`A reset password link has been sent to your email account`);
  //     props.history.push("/auth");
  //   });
  // };

  return (
    // <div className="reset-form centered-section">
    //   <div className="reset-form__title">forgoto password</div>
    //   {!q.token && (
    //     <div>
    //       <TextInput id="email" placeholder="account email" />
    //     </div>
    //   )}
    //   {q.token && (
    //     <>
    //       <div>
    //         <TextInput id="password" placeholder="password" password />
    //       </div>
    //       <div>
    //         <TextInput
    //           id="confirmpassword"
    //           placeholder="confirm password"
    //           password
    //         />
    //       </div>
    //     </>
    //   )}
    //   <div>
    //     <BlueButton
    //       text={"request reset" || "reset password"}
    //       onClick={
    //         q.token ? () => handlePasswordReset(q.token) : () => requestReset()
    //       }
    //     />
    //   </div>
    //   <Link to="/auth" style={{ textDecoration: "none", color: "#212A75" }}>
    //     <div className="reset-form__back-button">{"< back"}</div>
    //   </Link>
    //   <FullWidthDivider />
    // </div>

    <>
      <div className="signin-wrap background-muted">
        <div className="signin-form">
          <img
            className="signin-logo-top"
            src="/assets/images/logo-hde-v2.svg"
            alt="logo HDE."
            width="150"
            height="150"
          />
          <div>
            {!isSent ? (
              <>
                <h2 className="signin-title">Reset your password</h2>
                <h3>Please choose a new password</h3>
                <Formik
                  initialValues={{
                    password: "",
                    passwordConfirm: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (values.password !== values.passwordConfirm) {
                      errors.dontMatch = true;
                    }
                    if (!values.password) {
                      errors.password = "Required";
                    }
                    if (!values.passwordConfirm) {
                      errors.passwordConfirm = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={() => {}}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form>
                      <Input
                        id="email"
                        label="New password"
                        placeholder="*********"
                        type="password"
                        fullwidth
                        //
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        error={errors.password && touched.password}
                        errorMessage={errors.password}
                      />
                      <Input
                        id="confirm-password"
                        label="Confirm password"
                        placeholder="*********"
                        type="password"
                        fullwidth
                        //
                        name="passwordConfirm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.passwordConfirm}
                        error={
                          errors.passwordConfirm && touched.passwordConfirm
                        }
                        errorMessage={errors.passwordConfirm}
                      />
                      <Button
                        /*type="submit"*/ theme="primary"
                        isFullWidth
                        onClick={() =>
                          handlePasswordReset(q.token, values.password)
                        }
                        isDisabled={
                          errors.password ||
                          errors.passwordConfirm ||
                          !touched.password ||
                          !touched.passwordConfirm ||
                          errors.dontMatch
                        }
                      >
                        Confirm new password
                      </Button>

                      {errors.dontMatch && (
                        <div className="text-center text-error">
                          Passwords don’t match
                        </div>
                      )}
                      <div className="text-center">
                        <Link className="link" to="/auth">
                          Back to login
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <h2 className="signin-title">
                  {isError
                    ? "An error occurs, please try again later"
                    : "Your password has been reset"}
                </h2>

                <Button theme="primary" isLink to="/auth">
                  {isError ? "Back" : "Login"}
                </Button>
              </>
            )}
          </div>
          <img
            className="signin-logo-bottom"
            src="/assets/images/logo-hyatt.png"
            srcSet="/assets/images/logo-hyatt.png, /assets/images/logo-hyatt@2x.png 2x"
            alt="hyatt logo."
            width="141"
            height="34"
          />
        </div>
        <div
          className="signin-image"
          style={{
            backgroundImage: "url(/assets/images/enter-the-exhibition.jpg)",
          }}
        >
          <h2 className="signin-image-title title">Enter the exhibition</h2>
        </div>
      </div>
    </>
  );
};

export default withRouter(Reset);
