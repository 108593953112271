import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import routes from "./routes";
import Layout from "../components/layout";
import Auth from "../components/pages/auth";
import PartnerApply from "../components/pages/partnerApply";
import NotAvailable from "../components/pages/NoAccess";
import PublicPage from "../components/pages/public";
import Modal from "../components/ui/popup";
import { preloadAll } from "../utils/preload";
import "../styles/index.scss";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import { useAuthContext } from "../store/auth";
import Terms from "../components/pages/terms";
import { useModal } from "../store/hooks/useModal";
import { useQuery } from "react-query";
import { getMyBooths } from "../network/API";
import { ROLES } from "../constants/roles";

import Ui from "../components/pages/ui";

const ProtectedRoute = withRouter(({ history, isAllowed, ...props }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (!history.location.noResetScroll) window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return isAllowed ? <Route {...props} /> : <Redirect to="/public" />;
});

// take a date and return true if it is expired
const isExpired = (date) => {
  const now = new Date();
  const expiry = new Date(date);
  return now > expiry;
};

const assets = [
  "/assets/images/loader1.png",
  "/assets/images/loader2.png",
  "/assets/images/bg420-bakery.png",
  "/assets/images/bg420-beef.png",
  "/assets/images/bg420-coffee.png",
  "/assets/images/bg420-drinks.png",
  "/assets/images/bg420-equipment.png",
  "/assets/images/bg420-food.png",
  "/assets/images/bg420-hospitality.png",
  "/assets/images/bg420-hygiene.png",
  "/assets/images/bg420-meat.png",
  "/assets/images/bg420-spirits.png",
  "/assets/images/bg420-wine.png",
  "/assets/images/hde-logo-font.png",
  "/assets/images/hde-logo-font.png",
  "/assets/images/deadend.png",
];

const AppRouter = () => {
  const {
    role,
    userAuthed,
    checkToken,
    modalOpen,
    ModalContent,
    // canCloseModal,
    // closeModal,
    modalTitle,
  } = useAuthContext();

  const [isShowing, toggleModal] = useModal();
  const booths = useQuery(["getMyBooths"], getMyBooths, {
    refetchOnWindowFocus: false,
    enabled: role === ROLES.exhibitor && !!role,
    onSuccess: (data) => {
      let total = 0;
      for (const booth of data) {
        if (isExpired(booth.subscription_end)) {
          total++;
        }
      }
      if (total >= data.length) {
        toggleModal();
      }
    },
  });

  useEffect(() => {
    checkToken();
    load();
  }, []);

  const load = async () => {
    await preloadAll(assets);
    // if (loading) {
    // 	setLoading(false)
    // }
  };

  return (
    <Router>
      {/* {renderPage && */}
      <Layout>
        <Modal isShowing={isShowing} showCloseButton={false} width={420}>
          <h2 className="h2 title text-center m-auto">
            Your subscription has expired.
          </h2>
        </Modal>
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/terms" component={Terms} />
          <Route path="/notavailable" exact component={NotAvailable} />
          <Route path="/ui" component={Ui} />
          <Route path="/public" component={PublicPage} />
          <Route path="/partner-apply" component={PartnerApply} />

          {/* <Route path="/loader" exact component={Loader} /> */}
          {routes.map((r, i) => (
            <ProtectedRoute
              path={r.path}
              component={r.component}
              exact={r.exact}
              key={r.path || i}
              render={r.render}
              isAllowed={userAuthed}
            />
          ))}
        </Switch>
      </Layout>
      {/* } */}
    </Router>
  );
};

export default AppRouter;
