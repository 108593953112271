import React, { useRef, useState, useEffect } from "react";
import Modal from "../../ui/popup";
import Textarea from "../../ui/textarea";
import InputFile from "../../ui/inputFile";
import Button from "../../ui/button";
import { Row, Col } from "react-bootstrap";
import { useModal } from "../../../store/hooks/useModal";
import { uploadHistory } from "../../../network/Upload";
import { StartVideo, FinishVideo } from "../../../network/Stats";
import { baseURL } from "../../../network/API";
import { ROLES } from "../../../constants/roles";
import { useAuthContext } from "../../../store/auth";
import TruncateMarkup from "react-truncate-markup";

const History = (props) => {
  const { role } = useAuthContext();
  const [isShowing, toggleModal] = useModal(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const vidRef = useRef(null);
  const [showTruncated, setShowTruncated] = useState(false);

  const handleVideoClose = () => {
    if (vidRef?.current) {
      vidRef.current.currentTime = 0;
      vidRef.current.pause();
      toggleModal();
    }
  };

  return (
    <div className="exhibit-history">
      <Row>
        <Col lg={props.editMode || !props.media?.type ? 12 : 6}>
          <div
            className={
              props.editMode || !props.media?.type
                ? "exhibit-history__info edit-mode"
                : "exhibit-history__info"
            }
          >
            <h2 className="exhibit-history__title h2 title">About</h2>

            {props.editMode ? (
              <>
                <Textarea
                  rows="8"
                  fullwidth
                  value={props.text}
                  onChange={(e) =>
                    props.onBoothEdited({ history: e.target.value })
                  }
                  placeholder="Tell us more about your company"
                />
                <InputFile
                  id="history_video"
                  placeholder="Drag and drop a picture or video"
                  formats="mp4, .mov, jpeg, jpg, png (200mb max)"
                  fileSize={200}
                  accept=".mp4,.mov,.jpeg,.jpg,.png"
                  request={uploadHistory}
                  onFileUploaded={(data) =>
                    props.onBoothEdited({
                      history_media: data,
                    })
                  }
                />
                <Button theme="primary">+ Add a picture or video</Button>
              </>
            ) : (
              <>
                
                {isReadMore ? (<>
                  <TruncateMarkup lineHeight={150} onTruncate={(wasTruncated) => setShowTruncated(wasTruncated)}>
                    <p className="exhibit-history__text">{props.text}</p>
                  </TruncateMarkup>
                  {showTruncated && (
                    <button className="read-more-btn" onClick={() => setIsReadMore(false)}>
                      <span className="btn-text">Read more</span>
                      <span className="icon-chevron-down btn-icon"></span>
                    </button>
                  )}
                  </>
                ) : (<>
                  <p className="exhibit-history__text">{props.text}</p>
                  <button className="read-more-btn" onClick={() => setIsReadMore(true)}>
                    <span className="btn-text">Shorten</span>
                    <span className="icon-chevron-up btn-icon"></span>
                  </button>
                  </>
                )

                }
                
              </>
            )}
          </div>
        </Col>
        {props.media && (
          <Col lg="6">
            <div className="exhibit-history__video">
              {props.editMode && (
                <button
                  onClick={() =>
                    props.onBoothEdited({
                      history_media: null,
                    })
                  }
                  className="exhibit-delete"
                >
                  <div className="exhibit-delete-icon icon-trash" />
                </button>
              )}
              {props.media?.type.includes("video") ? (
                <>
                  <video
                    src={baseURL + "/storage/history_media/" + props.media?.uri}
                  />
                  <button
                    className="exhibit-history__video-play icon-play"
                    onClick={() => {
                      toggleModal();
                      vidRef.current.play();

                      if (role !== ROLES.exhibitor) StartVideo(props.id);
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    src={baseURL + "/storage/history_media/" + props.media?.uri}
                  />
                </>
              )}
            </div>
          </Col>
        )}
      </Row>

      <Modal
        isShowing={isShowing}
        onClose={handleVideoClose}
        className="modal-video"
        video
      >
        {props.media?.type.includes("video") ? (
          <video
            ref={vidRef}
            src={baseURL + "/storage/history_media/" + props.media?.uri}
            // autoPlay={isShowing}
            controls
            onEnded={() => FinishVideo(props.id)}
            preload="auto"
          />
        ) : (
          <>
            <img src={baseURL + "/storage/history_media/" + props.media?.uri} />
          </>
        )}
      </Modal>
    </div>
  );
};

export default History;
