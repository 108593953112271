import React, {useRef, useState} from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, A11y, Pagination } from "swiper";
import Modal from "../popup";
import ImageSlider from "../imageSlider";
import { useModal } from "../../../store/hooks/useModal";
import { baseURL } from "../../../network/API";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "./newsFeedCardSlider.scss";

SwiperCore.use([Navigation, A11y, Pagination]);

const NewsFeedCardSlider = ({
  handleSlideClick,
  slidesData,
  slidesNumberDesktop,
  SlideComponent,
  noModalSlide,
  onClickLike,
  onClickUnlike
}) => {
  const [isShowing, toggleModal] = useModal(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [isVideoShowing, toggleVideoModal] = useModal(false);
  const vidRef = useRef(null);
  const [selectedVideoUri, setSelectedVideoUri] = useState();

  const handleVideoClose = () => {
    if (vidRef?.current) {
      vidRef.current.currentTime = 0;
      vidRef.current.pause();
      toggleVideoModal();
    }
  };

  return (<div className="newsfeed-swiper-box">
    <Swiper
      className={cn("newsfeed-card-slider", {
        [`card-slider-centered`]: slidesData.length < 3,
      })}
      modules={[Navigation, A11y]}
      slidesPerView={1}
      spaceBetween={0}
      centeredSlides={false}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      pagination={{
        el: '.swiper-pagination',
        type: 'custom',
        renderCustom: function (swiper, current, total) {
          let customPaginationHtml = "";
          for(let i = 0; i < total; i++) {
              if(i === (current - 1)) {
                  customPaginationHtml += '<span class="pagination-progress active"></span>';
              } else {
                  customPaginationHtml += '<span class="pagination-progress"></span>';
              }
          }
          return customPaginationHtml;
        }
      }}
      breakpoints={{
        576: {
          slidesPerView: 2,
          centeredSlides: false,
          spaceBetween: 0
        },
        1200: {
          slidesPerView: slidesNumberDesktop,
          spaceBetween: 0
        },
        1440: {
          slidesPerView: slidesNumberDesktop,
          allowTouchMove: false,
          spaceBetween: 0
        },
      }}
    >
      <button className="swiper-button-prev" />
      <button className="swiper-button-next" />
      {slidesData.map((slide, index) => (
        <SwiperSlide key={index}>
          <SlideComponent
            {...slide}
            handleSlideClick={(id, videoId) => {
              if (handleSlideClick) handleSlideClick(id, videoId);
              if (setSelectedIndex) setSelectedIndex(index);
            }}
            onImageClick={() => {
              toggleModal(true);
              setSelectedIndex(index);
            }}
            onVideoClick={(uri) => {
              toggleVideoModal(true);
              setSelectedVideoUri(uri);
            }}
            onClickLike={onClickLike}
            onClickUnlike={onClickUnlike}
          />
        </SwiperSlide>
      ))}

      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        width={656}
        className="modal-image-slider"
      >
        <ImageSlider
          slidesData={slidesData}
          noModalSlide={noModalSlide}
          type="lifestyle"
          selectedIndex={selectedIndex}
        />
      </Modal>

      <Modal
        isShowing={isVideoShowing}
        onClose={handleVideoClose}
        className="modal-video"
        video
      >
       <video
          ref={vidRef}
          src={baseURL + "/storage/lifestyle/" + selectedVideoUri + "#t=0.01"}
          // autoPlay={isShowing}
          controls
          preload="auto"
        />
      </Modal>
    </Swiper>
    <div class="swiper-pagination"></div>
</div>
  );
};

export default NewsFeedCardSlider;
