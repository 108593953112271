import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Wrapper from "../../pages/wrapper";
import NotAvailable from "./notAvailable";
import get from "lodash.get";
import tracking from "../../../utils/tracking";
import { useDataContext } from "../../../store/data";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const NotFound = (props) => {
  let location = useLocation();
  const accessDenied = get(props.location, "state.accessDenied", false);
  const accessDeniedMessage = "this page only visible for hyatt associates";
  const notFound = "Page not found";

  useEffect(() => {
    tracking.pageView("/notfound");
    if (location.pathname !== "/notfound") {
      props.history.push("/notfound");
    }
  }, []);

  return (
    <Wrapper>
      <div className="not-available-page background-muted">
        <Container>
          <NotAvailable text={accessDenied ? accessDeniedMessage : notFound} />
        </Container>
      </div>
    </Wrapper>
  );
};

export default withRouter(NotFound);
