import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { baseURL } from "../../../network/API";

const partnerHeader = ({ logo, name, previous, description }) => {

  const getPartnerLogo = (img) => {
    if (img?.url) {
      return img.url;
    }
    return "/assets/images/bg-default-partner.png";
  };

  return <>
    <div
        className="partner-header"
      >
        <Container className="partner-header__back">
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              {previous && (
                <Link
                  to={previous.to}
                >
                  <span className="icon-arrow-left exhibit-title__back-icon" />
                  <span>Back</span>
                </Link>
              )}
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
                <div className="partner-info__container">
                  <div className="partner-background" style={{ backgroundImage: "url(" + getPartnerLogo(logo) + ")" }} />
                  <div className="partner-header__container">
                    <div className="partner-header-title">{name}</div>
                    <div className="partner-header-description">{description}</div>
                  </div>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
  </>
}
  

export default partnerHeader;
