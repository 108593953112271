import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import queryString from "query-string";
import Wrapper from "../wrapper";
import Button from "../../ui/button";
import Checkbox from "../../ui/checkbox";
import Input from "../../ui/input";
import Modal from "../../ui/popup";
import get from "lodash.get";
import TagSelect from "../../ui/select";
import Textarea from "../../ui/textarea";
import { SectionHead } from "../../layout/headings";
import { useModal } from "../../../store/hooks/useModal";
import { Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Loader from "../../layout/loader";
import moment from "moment";
import {
  getContactsByFilter,
  sendMessageToHotel,
  sendMessageToHotelFromStat,
  getMyBoothsCountries,
  getHotelContactsSuggest,
  getBrands,
  getCountries,
} from "../../../network/API";
import { Formik, Form } from "formik";
import { useAuthContext } from "../../../store/auth";
import { ROLES } from "../../../constants/roles";
import InputSearchAgolia from "../../ui/inputSearchAgolia";

const HotelCard = React.memo(({ logo, name, district, untilDate, isUpComing, link, id, onSelected }) => {

  return (
    <div className="card card-hotel">
      {isUpComing && (
        <div className="card-hotel__upcoming">
          <span className="upcoming-text">UPCOMING</span>
        </div>
      )}

      <div
        className="card-hotel__logo"
        style={{
          backgroundImage: `url(${logo})`,
        }}
      />
      <h3 className="card-hotel__name">{name}</h3>

      <div className="card-hotel__district">{district}</div>

      {isUpComing && (
        <div className="card-hotel__untilDate">
        { untilDate && <>
            <span>Until: </span>
            <span>{moment(untilDate).format("MMM, YYYY")}</span>
          </>
        }
        </div>
      )}
      

      <Button theme="primary" onClick={onSelected}>
        Contact
      </Button>
    </div>
  );
});

const HotelListPage = (props) => {
  // const { setLoading } = useDataContext();
  const queryClient = useQueryClient();
  const [isShowing, toggleModal] = useModal();
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [messageIsSent, setMessageIsSent] = useState(false);
  const [isFromStat, setIsFromStat] = useState();
  const [selectedContact, setSelectedContact] = useState();
  const [filterHotelIsUpComing, setFilterHotelIsUpComing] = useState(false);
  const [search, setSearch] = useState("");
  const [queryQ, setQueryQ] = useState(""); // for real search

  const { role } = useAuthContext();
  const myCountries = useQuery(["getMyBoothsCountries"], getMyBoothsCountries, {
    refetchOnWindowFocus: false,
    enabled: role === ROLES.exhibitor && !!role,
  });
  // const countries = useQuery(["getCountries"], getCountries, {
  //   refetchOnWindowFocus: false,
  // });
  const brands = useQuery(["getBrands"], getBrands, {
    refetchOnWindowFocus: false,
    onSettled: () => {
      if (props.location.contact) {
        setIsFromStat(props.location.contact);
        toggleModal();
      }
    },
  });
  const contacts = useQuery(
    ["getContactsByFilter", { brand: selectedBrand, country: selectedCountry, isUpComing: filterHotelIsUpComing, q: queryQ }],
    getContactsByFilter,
    { refetchOnWindowFocus: false }
  );

  const onSendMessage = useMutation(
    (values) =>
      isFromStat
        ? sendMessageToHotelFromStat(values, isFromStat)
        : sendMessageToHotel(values, selectedContact),
    {
      onSettled: () => {
        setMessageIsSent(true);
        setSelectedContact();
      },
    }
  );

  const closeModal = () => {
    toggleModal();
    setIsFromStat();
    setMessageIsSent(false);
  };

  useEffect(() => {
    const query = queryString.parse(props.history.location.search);
    if (query.brand) setSelectedBrand(query.brand);
    if (query.country) setSelectedCountry(query.country);
    if (query.isUpComing) setFilterHotelIsUpComing(query.isUpComing);
  }, []);

  const setQueryParams = (brand, country, isUpComing = false, searchValue) => {
    props.history.push({
      pathname: "/hotels",
      search: `?brand=${brand || ""}&country=${country || ""}&isUpComing=${isUpComing}${searchValue ? `&q=${searchValue}` : ""}`,
    });
  };

  const selectBrand = (e) => {
    setSelectedBrand(e.target.value === "All" ? "" : e.target.value);
    setQueryParams(
      e.target.value === "All" ? "" : e.target.value,
      selectedCountry,
      filterHotelIsUpComing,
      search
    );
  };

  const selectCountry = (e) => {
    // if (e.target.value === "61924182a39e88d832dee83a" || e.target.value === "5f8d014a18f3c00c6153234e") {
    if (e.target.value === "All") {
      setSelectedCountry("");
    } else {
      setSelectedCountry(e.target.value);
    }
    setQueryParams(
      selectedBrand,
      e.target.value === "All" ? "" : e.target.value,
      filterHotelIsUpComing,
      search
    );
  };

  const changeFilterHotelIsUpComing = (isUpComing) => {
    setFilterHotelIsUpComing(isUpComing);
    setQueryParams(
      selectedBrand,
      selectedCountry,
      isUpComing,
      search
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch(search);
    }
  };

  const handleSearch = (v) => {
    setQueryParams(
      selectedBrand,
      selectedCountry,
      filterHotelIsUpComing,
      v
    );
    setQueryQ(v);
    setSearch(v);
  };

  const onSearchClicked = (suggestion) => handleSearch(suggestion);

  const fetchData = async (value) => {
    const data = await getHotelContactsSuggest(value);
    let res = [];
    data.forEach((value, index) => {
      res.push({
        label: value,
        value: value
      });
    });
    return res;
}

const onInputChange = (event) => {
  setSearch(event.target.value);
};

const handelClearSearch = (event) => {
  setSearch("");
  setQueryQ("");
};

const getCountrieOptions = (e) => {
  const data = get(myCountries, "data", []);
  if (data.filter((c) => c.name === 'All ASPAC')) {
    console.log(data)
  }
  return [
    { name: "All", id: "" },
    ...get(myCountries, "data", [])
      .filter(
        (e) =>
          e.id !== "61924182a39e88d832dee83a" &&
          e.id !== "5f8d014a18f3c00c6153234e"
      )
      .map((e) => ({
        name: e.name,
        value: e.id,
      })),
  ]
};

  return (
    <>
      <Loader show={myCountries.isLoading || brands.isLoading} />
      <Wrapper>
        <SectionHead text="Hotel list" />
        {/* <ul className="filters">
          <li>
            <button
              className={`filters__button ${!filterHotelIsUpComing ? " active" : ""}`}
              onClick={() => changeFilterHotelIsUpComing(false)}
            >
              Opened
            </button>
          </li>
            <li>
              <button
                className={`filters__button ${filterHotelIsUpComing ? " active" : ""}`}
                onClick={() => changeFilterHotelIsUpComing(true)}
              >
                Pipeline
              </button>
            </li>
        </ul> */}
        <section className="section-small background-muted">
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                <Row className="card-hotel-list__filters">
                  <div className="card-hotel-list__col agolia-input">
                    <div
                      className="card-hotel-list__filter"
                    >
                      <InputSearchAgolia
                        placeholder="Search hotel's name"
                        size="lg"
                        value={search}
                        onChange={onInputChange}
                        onSearch={onSearchClicked}
                        onKeyDown={handleKeyDown}
                        fetchData={fetchData}
                        onClear={handelClearSearch}
                      />
                    </div>
                  </div>
                  <div className="card-hotel-list__col">
                    <div className="card-hotel-list__filter">
                      <TagSelect
                        label="Filter by brands"
                        options={[
                          { name: "All", id: "" },
                          ...get(brands, "data", []).map((e) => ({
                            name: e.name,
                            value: e.id,
                          })),
                        ]}
                        onChange={selectBrand}
                        value={selectedBrand}
                      />
                    </div>
                  </div>
                  <div className="card-hotel-list__col">
                    <div
                      className="card-hotel-list__filter"
                      style={{ marginBottom: 24 }}
                    >
                      <TagSelect
                        label="Filter by country/region"
                        options={[
                          { name: "All", id: "" },
                          ...get(myCountries, "data", [])
                            .filter(
                              (e) =>
                                e.id !== "61924182a39e88d832dee83a" &&
                                e.id !== "5f8d014a18f3c00c6153234e"
                            )
                            .map((e) => ({
                              name: e.name,
                              value: e.id,
                            })),
                        ]}
                        onChange={selectCountry}
                        value={selectedCountry}
                      />
                    </div>
                  </div>
                </Row>
              </Col>

              <Col lg={{ span: 10, offset: 1 }}>
                <ul className="card-hotel-list">
                  {get(contacts, "data", []).map((hotel, index) => (
                    <li key={index}>
                      <HotelCard
                        key={hotel.id}
                        {...hotel}
                        id={hotel.id}
                        logo={
                          get(brands, "data", []).find(
                            (e) => e.id === hotel.hotel_brand
                          )?.logo?.url
                        }
                        name={get(hotel, "city", "")}
                        district={get(hotel, "district", "")}
                        isUpComing={hotel?.isUpComing}
                        untilDate={hotel?.upComingEnd}
                        onSelected={() => {
                          setSelectedContact(hotel.id);
                          toggleModal();
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        <Modal
          isShowing={isShowing}
          onClose={() => {
            closeModal();
            setSelectedContact();
          }}
          showCloseButton={true}
        >
          {!messageIsSent ? (
            <>
              <h2 className="h2 title text-center">Contact</h2>

              <Formik
                initialValues={{
                  company_position: "",
                  name: "",
                  email: "",
                  message: "",
                  terms: false,
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.company_position) {
                    errors.company_position = "Required";
                  }
                  if (!values.name) {
                    errors.name = "Required";
                  }
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  if (!values.message) {
                    errors.message = "Required";
                  }
                  return errors;
                }}
                onSubmit={() => {}}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form>
                    <Input
                      label="Your company"
                      placeholder="Your company"
                      fullwidth
                      //
                      name="company_position"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_position}
                      error={
                        errors.company_position && touched.company_position
                      }
                      errorMessage={errors.company_position}
                    />
                    <Input
                      label="Your Name"
                      placeholder="Your Name"
                      fullwidth
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={errors.name && touched.name}
                      errorMessage={errors.name}
                    />
                    <Input
                      label="Your email address"
                      placeholder="Your email address"
                      type="email"
                      fullwidth
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email}
                      errorMessage={errors.email}
                    />
                    <Textarea
                      label="Your message"
                      placeholder="Your message"
                      rows="5"
                      fullwidth
                      name="message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      error={errors.message && touched.message}
                      errorMessage={errors.message}
                    />
                    <Checkbox
                      name="terms"
                      value={values.terms}
                      onChange={handleChange}
                      style={{ marginBottom: 24 }}
                    >
                      {values.terms}I agree to the{" "}
                      <Link to="/terms" className="link link-underline">
                        Terms and Conditions
                      </Link>
                    </Checkbox>
                    <div className="text-center">
                      <Button
                        onClick={() => onSendMessage.mutate(values)}
                        theme="primary"
                        isDisabled={
                          onSendMessage.isLoading ||
                          errors.company_position ||
                          !touched.company_position ||
                          errors.name ||
                          !touched.name ||
                          errors.email ||
                          !touched.email ||
                          errors.message ||
                          !touched.message ||
                          !values.terms
                        }
                      >
                        {onSendMessage.isLoading
                          ? "Sending..."
                          : "Send request"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <h2 className="h2 title text-center">
                Your message has been sent
              </h2>
              <div className="text-center">
                <Button theme="primary" onClick={closeModal}>
                  Back to contact list
                </Button>
              </div>
            </>
          )}
        </Modal>
      </Wrapper>
    </>
  );
};

export default withRouter(HotelListPage);
