import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { Image } from "antd";
import { withRouter } from "react-router-dom";
import { baseURL } from "../../../network/API";
import moment from "moment";
import get from "lodash.get";

const MediaDisplay = (props) => {
  const { show, item, onClose, list } = props;
  const ref = useRef(null);
  const [current, setCurrent] = useState();

  const getBrandLogo = (img) => {
    if (img) {
      return `${baseURL}/storage/logo/${img}`;
    }
  };

  useEffect(() => {
    if(item?.id && list && Array.isArray(list)){
      const newItem = list.find(i => i.id === item.id);
      setCurrent(newItem);
    }
  },[item, list, setCurrent])



  useEffect(() => {
    if (show) {
      document.querySelector("body").className = "overflow-hidden";
    } else {
      document.querySelector("body").className = "";
    }
  }, [show, item]);

  console.log(current)

  return (
    <>
      {show && (
        <div ref={ref} className="media-display-wrapper">
          <div onClick={() => onClose()} className="media-display-inner">
            <div className="close-icon-wrapper">
              <Image
                width={"100%"}
                height={"100%"}
                preview={false}
                src="/assets/images/close-icon.svg"
              />
            </div>
            <div
              onClick={(e) => e.stopPropagation()}
              className="media-display-body"
            >
              <div className="media-display-content">
                <div className="content-inner">
                  {item?.media && (
                    <div className="content-media">
                      {item?.media?.type === "video" && (
                        <video autoPlay loop src={item?.media?.uri} />
                      )}
                      {item?.media?.type === "image" && (
                        <img
                          alt={item?.media?.uri || ""}
                          src={item?.media?.uri}
                        />
                      )}
                    </div>
                  )}
                  {item.type === "lifestyle" && current && (
                    <div className="content-info">
                      <div className="info-left">
                        <div className="info-left__title">{current?.name}</div>
                        {current?.description && (
                          <div className="info-left__description">
                            {current?.description}
                          </div>
                        )}
                        <div className="info-left__date">
                          {current?.uploadedAt
                            ? moment(current?.uploadedAt).format("D MMMM, YYYY")
                            : moment().format("D MMMM, YYYY")}
                        </div>
                      </div>
                      <div className="info-right">
                        {current && (
                          current?.exhibitorLogo || current?.exhibitorName
                        ) &&<div 
                        onClick={() => {
                          props.history.push({
                            pathname: `/exhibit/${current.exhibitor}`,
                            previous: props.previous
                              ? props.previous
                              : { to: "/", name: "Home", scrollTo: "lifestyles" },
                          });
                        }}
                        className="info-right__brand">
                          {current && current?.exhibitorLogo &&
                          <div className="info-right__icon">
                            <img
                              className="info-right__icon-image"
                              src={getBrandLogo(get(current, "exhibitorLogo.uri", ""))}
                              alt={`${current?.exhibitor}-icon`}
                            />
                          </div>}
                          {current && current?.exhibitorName &&
                          <div 
                          className="info-right__brand-name">
                            {current?.exhibitorName}
                          </div>}
                        </div>}
                        <div className="lifestyle-likes">
                          <button 
                          onClick={(e) => {
                            console.log(current, item)
                            if (current?.isLiked) {
                              item.onUnlikeLifestyle();
                            } else {
                              item.onLikeLifestyle();
                            }
                            e.stopPropagation();
                          }}
                          className="lifestyle-like">
                            {current?.isLiked ? (
                              <span
                                className="icon-like-fill"
                                style={{ color: "#E43373" }}
                              ></span>
                            ) : (
                              <span className="icon-like" style={{color: "#151f6d"}}></span>
                            )}
                          </button>
                          <div className="lifestyle-like-count">
                            {!!current?.likeCount ? (
                              <span>
                                {current?.likeCount > 1
                                  ? `${current.likeCount} likes`
                                  : `1 like`}
                              </span>
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {item.type === 'product' && (
                    <div className="content-info">
                      <div className="info-left">
                        <div className="info-left__name">
                          <div 
                            onClick={() => {
                              if(item?.id){
                                props.history.push({
                                  pathname: `/exhibit/${item.id}`,
                                  previous: props.previous
                                    ? props.previous
                                    : { to: "/", name: "Home"},
                                });
                              }
                            }}
                          className="info-left__name__text">{item?.name}</div>
                        </div>
                        {item?.description && (
                          <div className="info-left__description">
                            {item?.description}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(MediaDisplay);
