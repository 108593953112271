import React, { useEffect, useState } from "react";
import { baseURL } from "../../../network/API";
import TruncateMarkup from "react-truncate-markup";
import moment from "moment";


const LifestyleCard = ({
  id,
  videoId,
  name,
  description,
  uploadedAt,
  likeCount,
  uri,
  to,
  handleSlideClick,
  isVideo,
  onImageClick,
  onVideoClick,
  isLiked,
  onClickLike,
  onClickUnlike
}) => {

  const [isReadMore, setIsReadMore] = useState(true);
  const [showTruncated, setShowTruncated] = useState(false);
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey(key => key + 1);
  },[description])
  const videoLink = uri;

  return (<>
    <div
      className="lifestyle-card-discover lifestyle-card-discover--video"
      to={to}
    >
      {!isVideo ? (
        <div
          className="lifestyle-card-discover__image"
          style={{
            backgroundImage: `url(${baseURL}/storage/lifestyle/${uri})`,
          }}
          onClick={() => {
            if(handleSlideClick){
              handleSlideClick({
                type: 'lifestyle',
                media: {
                  type: isVideo ? 'video' : 'image',
                  uri: isVideo ? baseURL + "/storage/lifestyle/" + videoLink + "#t=0.01" : `${baseURL}/storage/lifestyle/${uri}`
                }
              })
            }
          }}
        >
          <div onClick={() => onImageClick} className="lifestyle-card-discover__full-screen icon-full-screen button-full-screen" />
        </div>
      ) : (
        <div
          className="lifestyle-card-discover__image"
          onClick={() => {
            if(handleSlideClick){
              handleSlideClick({
                type: 'lifestyle',
                media: {
                  type: isVideo ? 'video' : 'image',
                  uri: isVideo ? baseURL + "/storage/lifestyle/" + videoLink + "#t=0.01" : `${baseURL}/storage/lifestyle/${uri}`
                }
              })
            }
          }}
        >
          <div className="lifestyle-card-discover__play icon-play button-play" />
          <div onClick={() => {if(onVideoClick)onVideoClick(videoLink)}} className="lifestyle-card-discover__full-screen icon-full-screen button-full-screen" />
          <video src={baseURL + "/storage/lifestyle/" + videoLink + "#t=0.01"} />
        </div>
      )}
      <div className="lifestyle-display-wrapper">
        <div className="lifestyle-left-container">
          
          {name && 
          <TruncateMarkup lines={3}>
            <div className="lifestyle-card-discover__title">{name}</div>
          </TruncateMarkup>}
          {isReadMore ? (<>
            <TruncateMarkup key={key} lines={3} onTruncate={(wasTruncated) => 
              setShowTruncated(wasTruncated)}>
              <p className="lifestyle-card-discover__subtitle">{description}</p>
            </TruncateMarkup>
            {showTruncated && (
              <button className="read-more-btn" onClick={() => setIsReadMore(false)}>
                <span className="btn-text">Read more</span>
                <span className="icon-chevron-down btn-icon"></span>
              </button>
            )}
            </>
          ) : (<>
            <p className="lifestyle-card-discover__subtitle">{description}</p>
            <button className="read-more-btn" onClick={() => setIsReadMore(true)}>
              <span className="btn-text">Shorten</span>
              <span className="icon-chevron-up btn-icon"></span>
            </button>
            </>
          )}
          
          <div className="lifestyle-posted">
            { uploadedAt ? moment(uploadedAt).format("D MMMM, YYYY") : moment().format("D MMMM, YYYY") }
          </div>
        </div>
        <div className="lifestyle-right-container">
          <button
            onClick={(e) => {
              if (isLiked) {
                onClickUnlike(id);
              } else {
                onClickLike(id);
              }
              e.stopPropagation();
              return false;
            }} 
            className="lifestyle-like">
              {isLiked ? (
                <span className="icon-like-fill" style={{ color: "#E43373" }} />
              ) : (
                <span className="icon-like" />
              )}
          </button>
          <div className="lifestyle-like-count">
          {!!likeCount ?
              <span>
                {likeCount > 1 ? `${likeCount} likes` : `1 like`} 
              </span>: <span> </span>}
          </div>
        </div>
      </div>
      

     
    </div>
  </>);
};

export default LifestyleCard;
