import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getNthItemFromPathname } from "../../../utils";
import Subheader from "./Subheader";
import { FullWidthDivider } from "../../layout/divider";
import Wrapper from "../wrapper";
import { useDataContext } from "../../../store/data";
import { useAuthContext } from "../../../store/auth";
import tracking from "../../../utils/tracking";
import config from "../exhibit/config";
import Statistics from "./statistics";
import { ROLES } from "../../../constants/roles";
import moment from "moment";

const Exhibit = (props) => {
  const [mode, setMode] = useState(config.yourBoothList);
  const pathname = props.history.location.pathname;
  const id = getNthItemFromPathname(pathname, 2);
  const menuOption = getNthItemFromPathname(pathname, 3);

  const {
    myBoothList,
    getMyBoothList,
    getABooth,
    setLoading,
    clearMyBoothData,
  } = useDataContext();
  const { role } = useAuthContext();

  useEffect(() => {
    if (menuOption && menuOption == "all") {
      setMode(config.yourBoothList);
    } else if (menuOption) {
      setMode(config[menuOption]);
    }
  }, [pathname]);

  useEffect(() => {
    if (role === ROLES.exhibitor) {
      if (myBoothList[0].exhibitor && myBoothList[0].exhibitor !== id) {
        props.history.replace("/notavailable");
      }
    }
    tracking.pageView(props.history.location.pathname);
  }, [myBoothList]);

  useEffect(() => {
    setLoading(true);
    if (role !== ROLES.exhibitor) {
      getABooth(id);
    }
    if (role && role === ROLES.exhibitor) {
      getMyBoothList();
    }
    setLoading(false);
  }, []);

  return (
    <Wrapper>
      <Subheader
        menu={mode ? mode.menu : null}
        mode={mode ? mode.name : null}
        setMode={setMode}
        titleNotClickable
      />

      {mode.name === "my booth" && (
        <Wrapper>
          <div className="centered-section">
            <div className="table-column">
              <div className="table-column__title" style={{ width: "7%" }}>
                Logo
              </div>
              <div className="table-column__title">Brand name</div>
              <div className="table-column__title">Subscription type</div>
              <div className="table-column__title" style={{ width: "20%" }}>
                Subscription ends
              </div>
              <div
                className="table-column__title"
                style={{ width: "41%" }}
              ></div>
            </div>
          </div>
          <div className="table-top-section">
            <br />
            <div className="table-column__title__line-full-width" />
          </div>
        </Wrapper>
      )}

      {mode.name === "my booth" &&
        myBoothList.map((list, key) => (
          <Wrapper>
            <div className="centered-section">
              <div className="table-column">
                <div className="table-column__logo">
                  <img
                    className="table-column__logo__logoSize"
                    src={
                      list.logo
                        ? `${list.logo.url}`
                        : "/assets/images/default-logo.png"
                    }
                  />
                </div>

                <div className="table-column__text">{list.name}</div>
                <div className="table-column__subscriptionType__title">
                  Subscription type
                </div>
                <div className="table-column__subscriptionEnd__title">
                  Subscription ends
                </div>
                <div className="table-column__subscriptionType">
                  <img
                    className="table-column__subscriptionType__img-browser"
                    src={`/assets/images/subscription-${list.subscription_type}.png`}
                  />
                  <div className="table-column__subscriptionType__text">
                    {list.subscription_type}
                  </div>
                  <img
                    className="table-column__subscriptionType__img-mobile"
                    src={`/assets/images/subscription-${list.subscription_type}.png`}
                  />
                </div>
                <div className="table-column__subscriptionEnd">
                  <div className="table-column__subscriptionEnd__text">
                    {moment(list.subscription_end).format("MM/DD/YYYY")}
                  </div>
                </div>
                <div className="table-column__button">
                  <button
                    className="blue-button"
                    // onClick={() => props.history.push(`/exhibit/${list.id}`)}
                    onClick={() => {
                      clearMyBoothData({});
                      props.history.push(`/exhibit/${list.id}`);
                    }}
                  >
                    VIEW
                  </button>
                  <button
                    className="blue-button"
                    // onClick={() => props.history.push(`/exhibit/${list.id}/edit`)}
                    onClick={() => {
                      clearMyBoothData({});
                      props.history.push(`/exhibit/${list.id}/edit`);
                    }}
                    disabled={
                      moment().isAfter(moment(list.subscription_end))
                        ? true
                        : false
                    }
                  >
                    EDIT
                  </button>
                </div>
                {!moment().isAfter(moment(list.subscription_end)) &&
                  moment()
                    .add(30, "days")
                    .isAfter(moment(list.subscription_end)) && (
                    <div className="table-column__reminder">
                      Your subscription will expire in one month. Please contact{" "}
                      <a href="mailto:perry.chung@hyatt.com">
                        Hyatt-Asia-Pacific
                      </a>{" "}
                      to renew it.
                    </div>
                  )}
                {moment().isAfter(moment(list.subscription_end)) && (
                  <div className="table-column__reminder">
                    Your subscription has expired. Your account and virtual
                    booth has been temporarily disabled. Please contact{" "}
                    <a href="mailto:perry.chung@hyatt.com">
                      Hyatt Asia-Pacific
                    </a>{" "}
                    to renew it.
                  </div>
                )}
              </div>
            </div>
            <FullWidthDivider />
          </Wrapper>
        ))}
      {mode.name === "statistics" && (
        <Wrapper>
          <Statistics />
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default withRouter(Exhibit);
