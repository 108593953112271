import React, { useState, useEffect, useCallback, useRef } from "react";
import cn from "classnames";
import throttle from "lodash.throttle";
import debounce from "lodash.debounce";
import "./brandCard.scss";
import { Link } from "react-router-dom";
import { PieChart, Pie, Cell } from "recharts";

const COLORS = [
  "#0A1043",
  "#151F6D",
  "#313B8A",
  "#555EA5",
  "#7F86BB",
  "#A3A8CB",
  "#C4C7DD",
  "#DFE1ED",
  "#ECEEF6",
  "#F3F5FD",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name,
  fill,
  value,
  ...trdy
}) => {
  const radius = (innerRadius + (outerRadius - innerRadius)) * 1.1;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  // const radius2 = innerRadius + (outerRadius - innerRadius) * 0.5;
  // const x2 = cx + radius2 * Math.cos(-midAngle * RADIAN);
  // const y2 = cy + radius2 * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text
        x={x}
        y={y}
        style={{ fontSize: 9 }}
        // fill={fill}
        fill="#131C62"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {name}
        {/* No. Mariana Island */}
      </text>
      {/* <text
        x={x2}
        y={y2}
        style={{ fontSize: "10px" }}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {value}
      </text> */}
    </>
  );
};

// const useIsResizing = () => {
//   const [isResizing, setIsResizing] = useState(false);
//   const [W, setW] = useState(window.width);
//   const [H, setH] = useState(window.height);
//   const debounced = useRef(debounce(() => setIsResizing(false), 1000));
//   const throttled = useRef(throttle(() => setIsResizing(true), 1000));

//   useEffect(() => {
//     window.addEventListener("resize", (e) => {
//       if (
//         (isResizing === false &&
//           W === window.innerWidth &&
//           H === window.innerHeight) ||
//         window.innerWidth < 600
//       ) {
//         setW(window.innerWidth);
//         return;
//       }
//       setW(window.innerWidth);
//       setH(window.innerHeight);
//       if (window.innerWidth < 1450) {
//         throttled.current();
//         debounced.current();
//       }
//     });
//     return () => {
//       window.removeEventListener("resize", () => {
//         // screenSize.current = window.innerWidth;
//         // console.log("resize 0");
//       });
//     };
//   }, []);

//   return isResizing;
// };

export const BrandCard = ({
  id,
  to,
  brandLogo,
  name,
  text,
  typeImg,
  type,
  date,
  names,
  isExpired,
  isSoonExpired,
  percentages,
  modifier,
  title,
  countries,
  ...props
}) => {
  // const test = useIsResizing();

  return modifier === "subscription" ? (
    <Link
      to={to}
      id={id}
      className={cn(`brandCard`) + " " + modifier}
      title={title}
    >
      <div className={cn(`brandCard__name`)}>
        <span>{name}</span>
      </div>
      <div
        className={cn(`brandCard__background`)}
        style={{ backgroundImage: "url(" + brandLogo + ")" }}
      ></div>
    </Link>
  ) : modifier === "stats" ? (
    <div id={id} className={cn(`brandCard`) + " " + modifier} title={title}>
      <div className={cn(`brandCard__container`)}>
        <ul>
          <div className={cn(`brandCard__name`)}>Geographic repartition</div>
          <li className={cn(`brandCard__row`)}>
            <div className={cn(`brandCard__key`)}>{names[0]}</div>
            <div className={cn(`brandCard__percentage`)}>{percentages[0]}</div>
          </li>
          {names[1] && (
            <li className={cn(`brandCard__row`)}>
              <div className={cn(`brandCard__key`)}>{names[1]}</div>
              <div className={cn(`brandCard__percentage`)}>
                {percentages[1]}
              </div>
            </li>
          )}
          {names[2] && (
            <li className={cn(`brandCard__row`)}>
              <div className={cn(`brandCard__key`)}>{names[2]}</div>
              <div className={cn(`brandCard__percentage`)}>
                {percentages[2]}
              </div>
            </li>
          )}
          {names[3] && (
            <li className={cn(`brandCard__row`)}>
              <div className={cn(`brandCard__key`)}>{names[3]}</div>
              <div className={cn(`brandCard__percentage`)}>
                {percentages[3]}
              </div>
            </li>
          )}
          {names[4] && (
            <li className={cn(`brandCard__row`)}>
              <div className={cn(`brandCard__key`)}>{names[4]}</div>
              <div className={cn(`brandCard__percentage`)}>
                {percentages[4]}
              </div>
            </li>
          )}
        </ul>
        <div className="pie-container">
          {/* {test ? null : (
            <ResponsiveContainer width="100%" height={180}> */}
          <PieChart width={320} height={180}>
            <Pie
              isAnimationActive={false}
              data={names?.map((m, i) => ({
                name: m,
                value: parseFloat(percentages[i]),
              }))}
              cx="50%"
              cy="50%"
              labelLine={false}
              // label
              label={renderCustomizedLabel}
              outerRadius={60}
              innerRadius={42}
              fill="#8884d8"
              dataKey="value"
            >
              {names
                ?.map((m, i) => ({
                  name: m,
                  value: parseFloat(percentages[i]),
                }))
                ?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    // fill={COLORS[index % names.length]}
                    fill={
                      COLORS[Math.round(index * (COLORS.length / names.length))]
                    }
                  />
                ))}
            </Pie>
          </PieChart>
          {/* </ResponsiveContainer>
          )} */}
        </div>
      </div>
    </div>
  ) : (
    <Link
      to={
        !isExpired
          ? {
              pathname: `/exhibit/${id}`,
              previous: { to: "/myaccount/brands", name: "My brands" },
            }
          : {}
      }
      id={id}
      className={cn(`brandCard`)}
      title={title}
    >
      <div
        className={cn(`brandCard__background`)}
        style={{ backgroundImage: "url(" + brandLogo + ")" }}
      ></div>
      <div className={cn(`brandCard__container`)}>
        <div className={cn(`brandCard__name`)}>{name}</div>
        <p className={cn(`brandCard__text`)}>{text}</p>
        <div className={cn(`brandCard__content`)}>
          {/* <div
            className={cn(`brandCard__image`)}
            style={{ backgroundImage: "url(" + typeImg + ")" }}
          ></div> */}
          <div className={cn(`brandCard__info`)}>
            {/* <div className={cn(`brandCard__type`)}>{type}</div> */}
            <div className={cn(`brandCard__countries`)}>
             {countries.map((country, index) => {
                return <>
                  <span className={cn(`country`)}>{country.name}</span>
                </>
             })}
            </div>
            <div className={cn(`brandCard__date`)}>
              {isExpired ? (
                <div className="brandCard__date  text-error">
                  Subscription expired
                </div>
              ) : isSoonExpired ? (
                <div className="brandCard__date text-warning">
                  Expired in 1 month
                </div>
              ) : (
                <div className="brandCard__date">{`Subscription ends on ${date}`}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BrandCard;
