import React from "react";
import MediaWrapper from "../../layout/mediaWrapper";

export const HomeSlide = React.forwardRef((props, ref) => {
  return (
    <div
      className={"slide-component-slide" + (props.blur ? " blur-active" : "")}
    >
      {props.blur && <div className="slide-component-slide__blur" />}
      {props.contentType === "video" && (
        <MediaWrapper
          src={props.url}
          sixteenByNine
          contain
          video
          autoPlay={props.autoPlay}
          playsInline={props.playsInline}
          controls={props.controls}
          loop={props.loop}
          muted
          mediaIndex={props.mediaIndex}
          onEnd={props.onEnd}
          onLoad={props.onLoad}
          ref={ref}
        />
      )}
      {
        props.contentType === "image" && (
          // <div className="slide-component-slide__image">
          <MediaWrapper
            src={props.url}
            mediaIndex={props.mediaIndex}
            onLoad={props.onLoad}
            sixteenByNine
            contain
          />
        )
        // </div>
      }
    </div>
  );
});
