import createContextStore from "./hooks/createContextStore";
import http from "../utils/axios";

// const token = localStorage.getItem('token')
// const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI1ZjZkYjAyYjQ5NWE2NjI5ZmZlMjUwYjYiLCJ0eXBlIjoidXNlciIsInBlcm1pc3Npb25zIjoiZXhoaWJpdG9yIiwiaWF0IjoxNjAzMTAzNzYwLCJleHAiOjE2MDMzNjI5NjB9.oNBw-AHSvjUzI9lFySY99nmv1x7oaCQgXq5Ksv_Z8ms'
// const headers = {
// 	'Authorization': `Bearer ${token}`
// }

const initialState = {
  categories: [],
  tags: [],
  countries: [],
  displayedBrands: [],
  searchResults: [],
  myBooth: {},
  myBoothList: [],
  previewData: {},
  uploadedFiles: {},
  stashBoothData: {},
  myStatistics: [],
  myStatisticsList: [],
  usePreviewData: false,
  headerIconOnClick: null,
  loading: true,
  expireModal: false,
};
const [DataProvider, useDataContext] = createContextStore(
  ({ state, setState }) => {
    // Fetch and store API data here, set loading while fetching and unset it when complete
    const getCategories = async (userToken) => {
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        const c = await http.get("categories", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: { range: "[0,9]" },
        });
        setState({
          categories: c.data,
        });
      } catch (e) {
        const message = JSON.stringify(e.toJSON());
        if (message.indexOf("403") > -1) {
          if (window.location.href !== "/auth" && message.indexOf("403")) {
            localStorage.clear();
            window.location.href = "/auth";
          }
        }
        console.error(e);
      }
    };

    const getTags = async (category, userToken, type) => {
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      if (!type) {
        type = "category";
      }
      try {
        const res = await http.get("tags", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            [type]: category,
            range: "[0,1000]",
          },
        });
        setState({
          tags: res.data,
        });
      } catch (e) {
        console.error(e);
      }
    };

    const getCountries = async (userToken) => {
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        const res = await http.get("countries", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            range: "[0, 100]",
          },
        });
        setState({
          countries: res.data,
        });
      } catch (e) {
        console.error(e);
      }
    };

    const getHomeData = async (userToken) => {
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        const res = await http.get("homepages", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setState({
          homeData: res.data[0],
        });
      } catch (e) {
        setLoading(false);
        const message = JSON.stringify(e.toJSON());
        if (message.indexOf("401") > -1) {
          setState({
            expireModal: true,
          });
        }
        if (message.indexOf("403") > -1) {
          if (window.location.href !== "/auth" && message.indexOf("403")) {
            localStorage.clear();
            window.location.href = "/auth";
          }
        }
        console.error(e);
      }
    };

    const getBrandsByCategory = async (categoryId, page, type) => {
      if (!type) type = "category";
      const userToken = localStorage.getItem("token");
      try {
        const res = await http.get("exhibitors/search", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            [type]: categoryId,
            page,
            range: "[0, 1000]",
          },
        });
        setState({
          displayedBrands: res.data,
        });
      } catch (e) {
        const message = JSON.stringify(e.toJSON());
        if (message.indexOf("401") > -1) {
          setState({
            expireModal: true,
          });
        }
        if (message.indexOf("403") > -1) {
          if (window.location.href !== "/auth" && message.indexOf("403")) {
            localStorage.clear();
            window.location.href = "/auth";
          }
        }
        console.error(e);
      }
    };

    const clearDisplayedBrands = () => {
      setState({
        displayedBrands: [],
      });
    };

    const searchBrands = async (q, page) => {
      const userToken = localStorage.getItem("token");
      try {
        const res = await http.get("exhibitors/search", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            q,
            page,
            range: [0, 1000],
          },
        });
        setState({
          searchResults: res.data,
        });
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    };

    const getMyBooth = async (id, userToken) => {
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        const res = await http.get(`exhibitors/${id}/mine`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setState({
          myBooth: res.data,
        });
      } catch (e) {
        setLoading(false);
        const message = JSON.stringify(e.toJSON());
        if (message.indexOf("401") > -1) {
          setState({
            expireModal: true,
          });
        }
        if (message.indexOf("403") > -1) {
          if (window.location.href !== "/auth" && message.indexOf("403")) {
            localStorage.clear();
            window.location.href = "/auth";
          }
        }
        console.error(e);
      }
    };

    const getMyBoothList = async (userToken) => {
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        const res = await http.get("exhibitors/mine", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setState({
          myBoothList: res.data,
        });
      } catch (e) {
        setLoading(false);
        const message = JSON.stringify(e);
        // const message = JSON.stringify(e.toJSON())
        if (message.indexOf("401") > -1) {
          setState({
            expireModal: true,
          });
        }
        if (message.indexOf("403") > -1) {
          if (window.location.href !== "/auth" && message.indexOf("403")) {
            localStorage.clear();
            window.location.href = "/auth";
          }
        }
        console.error(e);
      }
    };

    const updateMyBooth = async (id, data) => {
      const userToken = localStorage.getItem("token");
      try {
        return await http.post(`exhibitors/${id}/mine`, data, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } catch (e) {
        setLoading(false);
        const message = JSON.stringify(e.toJSON());
        if (message.indexOf("401") > -1) {
          setState({
            expireModal: true,
          });
        }
        if (message.indexOf("403") > -1) {
          if (window.location.href !== "/auth" && message.indexOf("403")) {
            localStorage.clear();
            window.location.href = "/auth";
          }
        }
        console.error(e);
      }
    };

    const updateMyLogo = async (id, data) => {
      const userToken = localStorage.getItem("token");
      try {
        await http.post(`exhibitors/${id}/mine/logo`, data, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    };

    const updateMyCarousel = async (id, data) => {
      const userToken = localStorage.getItem("token");
      try {
        await http.post(`exhibitors/${id}/mine/carousel`, data, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    };

    const updateMyImagesVideos = async (id, data) => {
      const userToken = localStorage.getItem("token");
      try {
        await http.post(`exhibitors/${id}/mine/images_videos`, data, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    };

    const updateMyCatalogue = async (id, data) => {
      const userToken = localStorage.getItem("token");
      try {
        await http.post(`exhibitors/${id}/mine/catalogs`, data, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    };

    const uploadFiles = async (id, data, type) => {
      const userToken = localStorage.getItem("token");
      try {
        return await http.post(`exhibitors/${id}/mine/${type}`, data, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    };

    const getMyStatistics = async (id, userToken) => {
      // setLoading(true)
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        const res = await http.get(`exhibitors/${id}/stats/mine`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setState({
          myStatistics: res.data,
        });
      } catch (e) {
        setLoading(false);
        // const message = JSON.stringify(e.toJSON())
        console.error(e);
      }
    };

    const getMyStatisticsList = async (userToken) => {
      // setLoading(true)
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        const res = await http.get(`exhibitors/stats/mine`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setState({
          myStatisticsList: res.data,
        });
      } catch (e) {
        setLoading(false);
        // const message = JSON.stringify(e.toJSON())
      }
    };

    const getABooth = async (id, userToken) => {
      // setLoading(true)
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        const res = await http.get(`exhibitors/${id}/front`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setState({
          myBooth: res.data,
        });
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    };

    const sendPageView = async (pageId, userToken) => {
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        http.get(`exhibitors/${pageId}/stats/pageView`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } catch (e) {
        console.error(e);
      }
    };

    const downloadContact = async (pageId, userToken) => {
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        http.get(`exhibitors/${pageId}/stats/downloadContact`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } catch (e) {
        console.error(e);
      }
    };

    const downloadCatalog = async (pageId, userToken) => {
      if (!userToken) {
        userToken = localStorage.getItem("token");
      }
      try {
        http.get(`exhibitors/${pageId}/stats/downloadCatalog`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      } catch (e) {
        console.error(e);
      }
    };

    const setPreviewData = (data) => {
      setState({
        previewData: data,
      });
    };

    const setUploadedFiles = (data) => {
      setState({
        uploadedFiles: data,
      });
    };

    const setStashBoothData = (data) => {
      setState({
        stashBoothData: data,
      });
    };

    const setLoading = (bool) => {
      setState({
        loading: bool,
      });
    };

    const setUsePreviewData = (bool) => {
      setState({
        usePreviewData: bool,
      });
    };

    const setHeaderIconOnClick = (func) => {
      setState({
        headerIconOnClick: func,
      });
    };

    const clearMyBoothData = (data) => {
      setState({
        myBooth: data,
      });
    };

    return {
      loading: state.loading,
      categories: state.categories,
      getCategories,
      tags: state.tags,
      getTags,
      countries: state.countries,
      getCountries,
      homeData: state.homeData,
      getHomeData,
      displayedBrands: state.displayedBrands,
      getBrandsByCategory,
      searchBrands,
      searchResults: state.searchResults,
      getMyBooth,
      myBooth: state.myBooth,
      getMyBoothList,
      myBoothList: state.myBoothList,
      updateMyBooth,
      updateMyLogo,
      updateMyCarousel,
      updateMyImagesVideos,
      updateMyCatalogue,
      uploadFiles,
      setPreviewData,
      previewData: state.previewData,
      setUploadedFiles,
      uploadedFiles: state.uploadedFiles,
      setStashBoothData,
      stashBoothData: state.stashBoothData,
      getMyStatistics,
      myStatistics: state.myStatistics,
      getMyStatisticsList,
      myStatisticsList: state.myStatisticsList,
      getABooth,
      sendPageView,
      setLoading,
      downloadContact,
      downloadCatalog,
      setUsePreviewData,
      usePreviewData: state.usePreviewData,
      setHeaderIconOnClick,
      headerIconOnClick: state.headerIconOnClick,
      expireModal: state.expireModal,
      clearDisplayedBrands,
      clearMyBoothData,
    };
  },
  initialState
);

export { DataProvider, useDataContext };
