import React from "react";
import cn from "classnames";
import "./checkbox.scss";

const Checkbox = ({
  color,
  disabled,
  name,
  value,
  children,
  checked,
  onChange,
  ...props
}) => {
  return (
    <label
      className={cn(
        "checkbox",
        { [`checkbox-${color}`]: !!color },
        {
          [`checkbox-disabled`]: !!disabled,
        }
      )}
      {...props}
    >
      <input
        {...props}
        className={cn("checkbox-input")}
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
      <span className="checkbox-box" />
      <span className="checkbox-label">{children}</span>
    </label>
  );
};

export default Checkbox;
