import React from "react";
import cn from "classnames";
import "./select.scss";

const TagSelect = ({
  disabled,
  color,
  error,
  errorMessage,
  fullwidth,
  icon,
  id,
  flat,
  label,
  options,
  size,
  ...otherProps
}) => {
  return (
    <div
      className={cn("input-item", {
        "input-icon-left": !!icon,
      })}
    >
      {label ? (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      ) : (
        ""
      )}
      {icon ? <div className={`icon-${icon}`} /> : null}
      <select
        className={cn(
          "input",
          {
            error: error,
            disabled: disabled,
            flat: flat,
            fullwidth: fullwidth,
          },
          { [`input-${color}`]: !!color },
          { [`input-${size}`]: !!size }
        )}
        disabled={disabled}
        id={id}
        {...otherProps}
      >
        {options.map((option) => (
          <option value={option.value}>{option.name}</option>
        ))}
      </select>
      <div className="select-icon icon-chevron-down" />
      {error ? <div className="input-error-message">{errorMessage}</div> : null}
    </div>
  );
};

export default TagSelect;
