import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { BlueButton } from '../../ui/buttons'
import { HelpText } from '../../ui/form'

const FileUploader = (props) => {
	const onDrop = useCallback(acceptedFiles => {
		props.callback(acceptedFiles, props.type)
	}, [props])

	const createHelpText = () => {
		if (typeof props.accept === 'string') {
			return props.accept.split('/')[1]
		}
		if (Array.isArray(props.accept)) {
			const accepts = props.accept.map(a => 
				a.split('/')[1].indexOf('vnd') > -1 ? 'xlsx' : a.split('/')[1]
			)
			return accepts.filter(s => s !== '*').join(',')
		}
	}
	const {
		getRootProps,
		getInputProps,
		open
	} = useDropzone({ 
		onDrop,
		accept: props.accept,
		multiple: props.multiple,
		maxFiles: props.maxFiles
	})
	
  return (
    <span {...getRootProps()} onClick={(e) => e.stopPropagation()}>
      <input {...getInputProps()} />
			<BlueButton onClick={() => open()} text={props.text} disabled={props.maxFiles === 0 || props.disable} />
			<HelpText text={`${createHelpText()} ${props.helpText || ''}` } />
    </span>
  )
}

export default FileUploader