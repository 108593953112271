import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Wrapper from "../wrapper";
import Button from "../../ui/button";
import { useDataContext } from "../../../store/data";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import tracking from "../../../utils/tracking";

const PublicPage = (props) => {
  // const { setLoading } = useDataContext();

  // useEffect(() => {
  //   setLoading(true);

  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);

  useEffect(() => {
    tracking.pageView("/");
  }, []);

  return (
    <Wrapper>
      <section className="section section-hero-public background-primary has-overlay">
        {/* <header className="section-hero-header">
          <img
            src="/assets/images/logo-hde-white.svg"
            alt="logo HDE."
            width="50"
            height="50"
            className="section-hero-header-logo"
          />
          <Button
            className="section-hero-sign-in"
            isLink
            theme="secondary"
            text="Sign In"
          />
        </header> */}
        <Container>
          <Row>
            <Col sm="10" lg={{ offset: 1 }}>
              <img
                className="section-hero-image-text"
                src="/assets/images/hero-text.svg"
                alt="Hyatt Asia-Pacific F&B + Hospitality Digital Exhibition."
              />
              <Button
                className="section-hero-sign-in-mobile"
                isLink
                theme="secondary"
                text="Sign In"
                to="/auth"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <section className="section section-cards background-default">
          <Row>
            <Col sm={12}>
              <h2 className="h2">
                Hyatt F&B + Hospitality Digital Exhibition is the industry's
                first truly digital trade show.
              </h2>
            </Col>
            <Col lg={{ span: 10, offset: 1 }}>
              <div className="section-cards-images">
                <div>
                  <img
                    src="/assets/images/trade-show1.jpg"
                    alt="service."
                    width="272"
                    height="442"
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/trade-show2.jpg"
                    alt="service."
                    width="272"
                    height="442"
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/trade-show3.jpg"
                    alt="service."
                    width="272"
                    height="442"
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/trade-show4.jpg"
                    alt="service."
                    width="272"
                    height="442"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </Container>

      <section className="section-approach background-muted">
        <Container className="section-approach-inner no-padding-horizontal">
          <div className="section-approach-text">
            <h2 className="h2 title">
              Approach your ideal prospects in the “New Normal”
            </h2>
            <p>
              No longer need to travel & exhibit at hotel shows in order to
              reach us in Asia-Pacific
            </p>
          </div>

          <div
            className="section-approach-image"
            style={{ backgroundImage: "url(/assets/images/new-normal.jpg)" }}
          ></div>
        </Container>
      </section>

      <section className="section section-tool background-default">
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <h2 className="h2 text-center">
                The first tool of its kind, offering the most comprehensive
                selection of products and services on one single platform
              </h2>
              <div
                className="section-tool-image"
                style={{ backgroundImage: "url(/assets/images/tool.png)" }}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section section-knowledge background-muted">
        <Container>
          <Row>
            <Col lg={{ span: 6, offset: 1 }}>
              <div
                className="section-knowledge-image"
                style={{ backgroundImage: "url(/assets/images/knowledge.jpg)" }}
              />
            </Col>
            <Col lg="5" className="section-knowledge-text-col">
              <div className="section-knowledge-text">
                <h2 className="h2 title">Knowledge + Advertorial</h2>
                <p>
                  Exhibit your offer in front of qualified audiences.
                  <br /> Boost your sales and visibility across Asia-Pacific.
                  <br /> Drive visibility and awareness for your brand.
                  <br /> Increase your business and competitive advantage at
                  lower cost.
                </p>
                <Button to="/partner-apply" isLink theme="secondary-light">
                  Become an exhibitor now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Wrapper>
  );
};

export default withRouter(PublicPage);
