import React from "react";
import BoothTags from "../../ui/tags/boothTags";
import config from "../../pages/exhibit/config";
import { Link } from "react-router-dom";
import get from "lodash.get";

const Subheader = (props) => {
  const title = props.subcategory
    ? get(props, "subcategory.name", "")
    : get(props, "category.name", "");
  const link = props.subcategory
    ? `/category/${get(props, "category.slug", "")}/${get(
        props,
        "subcategory.slug",
        ""
      )}`
    : `/category/${get(props, "category.slug", "")}`;
  return (
    <div className="subheader">
      {props.logo &&
        !props.logoNotClickable &&
        get(props, "category.slug", "") !== "" && (
          <Link
            to={link}
            style={{
              textDecoration: "none",
              color: "black",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            <img
              className="subheader__logo"
              src={
                props.subcategory
                  ? get(props, "subcategory.icon.url", "")
                  : props.logo
              }
              alt="category-logo"
            />
          </Link>
        )}

      {props.logo && props.logoNotClickable && (
        <img className="subheader__logo" src={props.logo} alt="category-logo" />
      )}

      {!props.titleNotClickable && (
        <div className="subheader__title">
          <Link to={link} style={{ textDecoration: "none", color: "black" }}>
            {title}
          </Link>
        </div>
      )}

      {props.titleNotClickable && (
        <div className="subheader__title subheader__notclickable">
          <Link style={{ textDecoration: "none", color: "black" }}>
            {get(props, "category.name", "Your Booth List")}
          </Link>
        </div>
      )}

      {props.menu && (
        <div className="subheader-menu">
          <div
            className={`subheader-menu__item
						${props.mode === "my booth" ? "subheader-menu__item--selected" : ""}`}
            onClick={() => props.setMode(config.yourBoothList)}
          >
            my booth
          </div>
          <div
            className={`subheader-menu__item
						${props.mode === "statistics" ? "subheader-menu__item--selected" : ""}`}
            onClick={() => props.setMode(config.statistics)}
          >
            statistics
          </div>
        </div>
      )}
      <div className="subheader__tags">
        {props.tags && <BoothTags tags={props.tags} />}
      </div>
    </div>
  );
};

export default Subheader;
