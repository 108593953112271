import React, { useState } from "react";
import "./style.scss";
import { useMutation } from "react-query";
import { useModal } from "../../../store/hooks/useModal";
import Modal from "../../ui/popup";
import Button from "../../ui/button";

import { Modal as AntdModal } from "antd";

const AddMore = ({
  id,
  disabled,
  fileSize = 50,
  request,
  onFileUploaded,
  ...otherProps
}) => {
  const [isShowing, toggleModal] = useModal();
  const [uploading, setUploading] = useState();
  const [error, setError] = useState();
  const [invalidFile, setInvalidFile] = useState();
  const onUploadFile = useMutation((values) => request(values), {
    onSuccess: (data) => {
      setUploading(false);
      onFileUploaded(data);
    },
    onError(e) {
      if (e.response?.status > 400) {
        setError(
          e?.response?.data
            ? `[Server Error]:Please Contact System Admin. report: ${JSON.stringify(
                e?.response?.data?.message
              )}`
            : "unknown error"
        );
      } else {
        setUploading(false);
        setInvalidFile(
          e?.response?.data?.message ?? 'The file is invalid. Please try again'
        );
      }
    },
  });

  const handleFiles = (event) => {
    if (uploading) return;
    const file = event.target.files[0];
    if (!file) return;
    if (file.size >= fileSize * 1024 * 1024) {
      toggleModal();
      return;
    }

    const formData = new FormData();
    formData.append("files", file);
    setUploading(true);
    onUploadFile.mutate(formData);
    event.target.value = "";
  };

  return (
    <>
      <AntdModal
        open={uploading}
        closable={error}
        title={error ? <span style={{ color: "red" }}>Error</span> : ""}
        maskClosable={false}
        onCancel={() => {
          setUploading(false);
          setError(undefined);
        }}
        footer={false}
      >
        {error ? (
          <span style={{ color: "red" }}>Error Message: {error}</span>
        ) : (
          <h2 className="h2 title text-center">uploading file...</h2>
        )}
      </AntdModal>
      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        showCloseButton={true}
        width={420}
      >
        <h2 className="h2 title text-center">
          The file is too heavy, please compress it and upload again. (
          {fileSize}mb max)
        </h2>
        <div className="text-center">
          <Button theme="primary" onClick={toggleModal}>
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        isShowing={invalidFile != null}
        onClose={() => {
          setInvalidFile(undefined);
        }}
        showCloseButton={true}
        width={420}
      >
        <h2 className="h2 title text-center">
          {invalidFile}
        </h2>
        <div className="text-center">
          <Button theme="primary" onClick={() => { setInvalidFile(undefined); }}>
            Close
          </Button>
        </div>
      </Modal>

      <div className="add-more" {...otherProps}>
        {id === "lifestyle" ? (
          <input
            type="file"
            className="add-more__input"
            formats="mp4, .mov, jpeg, jpg, png (50mb max)"
            fileSize={50}
            accept=".mp4,.mov,.jpeg,.jpg,.png"
            id={id}
            name={id}
            disabled={disabled}
            onChange={handleFiles}
          />
        ) : (
          <input
            type="file"
            className="add-more__input"
            formats="jpg, jpeg, png (50mb max)"
            fileSize={50}
            accept=".jpg,.jpeg,.png"
            id={id}
            name={id}
            disabled={disabled}
            onChange={handleFiles}
          />
        )}

        <label htmlFor={id} className="add-more__label">
          <span className="icon-plus" />
          <span>Add more</span>
        </label>
      </div>
    </>
  );
};

export default AddMore;
