import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "./header";
import { useQueryClient } from "react-query";
import HeaderMini from "././headerMini";
import Footer from "./footer";
import FooterSmall from "./footerSmall";
import Menu from "../ui/menu/index";
import Reminder from "./reminder";
import { useAuthContext } from "../../store/auth";
import { useDataContext } from "../../store/data";
import moment from "moment";
import Loader from "./loader";
import { ROLES } from "../../constants/roles";
import { getNthItemFromPathname } from "../../utils";

const Layout = React.memo((props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [reminder, setReminder] = useState(false);
  const queryClient = useQueryClient();
  const {
    categories,
    getCategories,
    myBoothList,
    getMyBoothList,
    loading,
    headerIconOnClick,
    expireModal,
  } = useDataContext();
  const { role, unAuthUser, userAuthed, openModal } = useAuthContext();
  const pathname = props.history.location.pathname;
  const pageName = getNthItemFromPathname(pathname, 1);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      if (categories.length === 0) {
        getCategories(localStorage.getItem("token"));
      }
    }
  }, [role]);

  useEffect(() => {
    if (role === ROLES.exhibitor) {
      getMyBoothList();
    }
  }, [role]);

  // useEffect(() => {
  // 	setInterval(() => {
  // 		getMyBooth()
  // 	}, 5000);
  // }, [])

  const DisabledProfileContent = () => {
    return (
      <div>
        Your account and virtual booth has been temporarily disabled.
        <br /> Please contact{" "}
        <a href="mailto:perry.chung@hyatt.com" className="link-default">
          Hyatt Asia-Pacific
        </a>{" "}
        to renew it.{" "}
      </div>
    );
  };

  useEffect(() => {
    const now = moment();
    // const nowPlus30 = moment().add(30, 'days')
    // const subscriptionEnd = moment(myBoothList.subscription_end)

    // if (!nowPlus30.isBefore(subscriptionEnd) && userAuthed && role === 'exhibitor') {
    // 	setReminder(true)
    // }

    var theFinalSubscriptionEnd = "";
    myBoothList.map((list, key) => {
      if (key === 0) {
        theFinalSubscriptionEnd = moment(list.subscription_end);
      }
      if (
        moment(theFinalSubscriptionEnd).isBefore(moment(list.subscription_end))
      ) {
        theFinalSubscriptionEnd = moment(list.subscription_end);
      }
    });

    if (
      (now.isAfter(theFinalSubscriptionEnd) && role === ROLES.exhibitor) ||
      expireModal
    ) {
      setReminder(false);
      localStorage.clear();
      openModal(
        `Your subscription has expired. `,
        DisabledProfileContent,
        false
      );
    }
  }, [myBoothList]);

  useEffect(() => {
    if (pathname.indexOf("auth") > -1 || pathname === "/partner-apply") {
      setHideMenu(true);
    } else {
      setHideMenu(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (expireModal) {
      setReminder(false);
      localStorage.clear();
      openModal(
        `Your subscription has expired. `,
        DisabledProfileContent,
        false
      );
    }
  }, [expireModal]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    const body = document.querySelectorAll("body")[0];
    if (menuOpen) {
      body.style.overflow = "auto";
    } else {
      body.style.overflow = "hidden";
    }
  };

  const handleLogout = async () => {
    // setLoading(true)
    unAuthUser();
    setMenuOpen(!menuOpen);

    setReminder(false);
    props.history.push("/auth");
  };

  // TODO Refactor
  const inverseHeaderPages = [
    "/myaccount",
    "/myaccount/brands",
    "/myaccount/stats",
    "/myaccount/settings",
    "/hotels",
    "/terms",
    "/favorites",
    "/notavailable",
    "/notfound",
    "/exhibit",
    "/partner",
  ];

  const smallFooterPages = [
    "/public",
    "/terms",
    "/notfound",
    "/myaccount",
    "/myaccount/brands",
    "/myaccount/stats",
    "/myaccount/settings",
  ];

  return (
    <>
      <div className="layout">
        <Menu
          menuOpen={menuOpen}
          toggleMenu={toggleMenu}
          categories={categories}
          hide={hideMenu}
          logout={handleLogout}
          role={role}
        />
        <Reminder open={reminder} />
        {pageName === "partner-apply" ? (
          <HeaderMini />
        ) : (
          <Header
            // TODO Refactor
            view={inverseHeaderPages.includes(pathname) || pathname.match('exhibit') || pathname.match(/partner\//) ? "inverse" : "default"}
            toggleMenu={toggleMenu}
            hide={hideMenu}
            role={role}
            logout={handleLogout}
            moveDown={reminder}
            myBoothList={myBoothList}
            // oothId={myBooth.id ? myBooth.id : null}
            previewFunc={headerIconOnClick}
          />
        )}
        <main className={`page-wrapper ${pathname === '/' ? 'bg-fade-out' : ''}`}>{props.children}</main>
        {/* {loading && <Loader />} */}
        {smallFooterPages.includes(pathname) ? (
          <FooterSmall hide={hideMenu} />
        ) : (
          <Footer hide={hideMenu} role={role} />
        )}
      </div>
    </>
  );
});

export default withRouter(Layout);
