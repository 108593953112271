import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const CategoryHeader = ({ image, name, back }) => (
  <div
    className="category-header"
    style={{ backgroundImage: "url(" + image + ")" }}
  >
    <Container className="category-header__back">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          {back && (
            <Link
              to={"/category/" + back?.slug}
              // className="exhibit-title__back"
            >
              <span className="icon-arrow-left exhibit-title__back-icon" />
              <span>Back</span>
            </Link>
          )}
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <div className="category-background" />
          <h1 className="category-header-title title">{name}</h1>
        </Col>
      </Row>
    </Container>
  </div>
);

export default CategoryHeader;
