import React, { useEffect, useState } from "react";
import Button from "../../ui/button";
import { Link } from "react-router-dom";
import { ROLES } from "../../../constants/roles";
import { Container, Row, Col } from "react-bootstrap";
import { useAuthContext } from "../../../store/auth";
import cn from "classnames";
import TruncateMarkup from "react-truncate-markup";


const ExhibitorNav = (props) => {
  const { role } = useAuthContext();

  const [hoverFollow, setHoverFollow] = useState(false);
  const [fixedHeader, setFixedHeader] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      setFixedHeader(window.pageYOffset > 250);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    
    <div className={cn("exhibitor-navigation-header", {
        [`exhibitor-header-fixed`]: !!fixedHeader,
    })}>
        <Container>
            <Row>
                <Col xxl={{ span: 10, offset: 1 }}>
                    <div className="navigate-header">
                        <div className="navigate-header-back">
                            {props.previous && (
                                <Link to={props.previous.to} className="exhibit-header__back">
                                    <span className="icon-arrow-left exhibit-header__back-icon" />
                                    <TruncateMarkup lines={1}>
                                        <span>Back</span>
                                    </TruncateMarkup>
                                </Link>
                            )}
                        </div>
                        <div className="navigate-header-filter">
                            <ul className="filters">
                                <li>
                                    <button
                                    className={`filters__button nav-title ${props.navFilter === 'lifestyles' ? " active" : ""}`}
                                    onClick={() => props.onNavFilterChange('lifestyles')}
                                    >
                                    NEWS feed
                                    </button>
                                </li>
                                <li>
                                    <button
                                    className={`filters__button nav-title ${props.navFilter === 'products' ? " active" : ""}`}
                                    onClick={() => props.onNavFilterChange('products')}
                                    >
                                    Products
                                    </button>
                                </li>
                                <li>
                                    <button
                                    className={`filters__button nav-title ${props.navFilter === 'catalogue' ? " active" : ""}`}
                                    onClick={() => props.onNavFilterChange('catalogue')}
                                    >
                                    Catalogue
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div>
                            {role !== ROLES.exhibitor && (
                                <div className="exhibit-title__actions">
                                    <Button
                                        onMouseEnter={() => setHoverFollow(true)}
                                        onMouseLeave={() => setHoverFollow(false)}
                                        onClick={() =>
                                        !props.isFollow
                                            ? props.onClickFollow(true)
                                            : props.onClickFollow(false)
                                        }
                                        theme="primary"
                                        icon={
                                        props.isFollow
                                            ? hoverFollow
                                            ? "Vector"
                                            : "star-fill"
                                            : "star"
                                        }
                                        iconColor={
                                        props.isFollow
                                            ? hoverFollow
                                            ? "#ffffff"
                                            : "#FFBF00"
                                            : "#ffffff"
                                        }
                                        style={{ minWidth: 135 }}
                                    >
                                        {props.isFollow
                                        ? hoverFollow
                                            ? "Unfollow"
                                            : "Following"
                                        : "Follow"}
                                    </Button>
                                    <Button theme="primary" onClick={props.onClickRequestMeeting}>
                                        Request a meeting
                                    </Button>
                                </div>
                            )}
                            {role === ROLES.exhibitor && (
                                <div
                                className="exhibit-title__buttons"
                                > 
                                    <Button
                                        theme="primary"
                                        className="exhibit-title__edit"
                                        onClick={props.onClickEdit}
                                    >
                                        Edit my page
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  );
};

export default ExhibitorNav;