import React, { useEffect, useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "../../ui/button";
import { FullWidthDivider } from "../../layout/divider";
import { useAuthContext } from "../../../store/auth";
import Input from "../../ui/input";
import { useDataContext } from "../../../store/data";
import queryString from "query-string";
import get from "lodash.get";
import { Formik, Form } from "formik";
import tracking from "../../../utils/tracking";
import { useMutation, useQueryClient } from "react-query";
import { resetPassword, login } from "../../../network/API";
import { useModal } from "../../../store/hooks/useModal";
import Modal from "../../ui/popup";

const SignIn = React.memo((props) => {
  const [isShowing, toggleModal] = useModal();
  const [modalContent, setModalContent] = useState("An error occurs");
  const queryClient = useQueryClient();
  const [recoverMode, setRecoverMode] = useState(false);
  const [recoverStep, setRecoverStep] = useState(0);
  const [isExhibitor, setIsExhibitor] = useState(false);
  const { loginExhibitor, userAuthed, authUser, exchangeCodeForToken } =
    useAuthContext();
  const onSendMessage = useMutation(resetPassword, {
    onSuccess: () => setRecoverStep(1),
    onError: (err) => {
      alert(err?.response?.data?.message);
      setRecoverMode(false);
      setRecoverStep(0);
    },
  });

  // const { setLoading } = useDataContext();

  const handleLogin = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }

      const email = document.getElementById("email").value;
      const password = document.getElementById("password").value;

      if (!email || !password) return;

      login(email, password)
        .then((res) => {
          console.log(res);
          const { role, name, email } = res.user;
          localStorage.setItem("role", role);
          localStorage.setItem("token", res.token);
          localStorage.setItem("name", name);
          localStorage.setItem("email", email);

          queryClient.invalidateQueries();
          authUser(role, name, email);
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            setModalContent("Email or password incorrect");
            toggleModal();
          } else if (err?.response?.status === 401) {
            setModalContent("Your subscription has expired");
            toggleModal();
          }
        });
    },
    [loginExhibitor]
  );

  useEffect(() => {
    // setLoading(false);
    const code = queryString.parse(
      get(props, "history.location.search", {})
    ).code;
    if (code) {
      // setLoading(true)
      exchangeCodeForToken(code).then((res) => {
        if (res) {
          // setLoading(false);
          const r = get(res, "data.user.role", "");
          const t = get(res, "data.token", "");
          localStorage.setItem("role", r);
          localStorage.setItem("token", t);
          // localStorage.setItem('country', get(res, 'data.user.country', ''))
          // localStorage.setItem('city', get(res, 'data.user.city', ''))
          // localStorage.setItem('brand', get(res, 'data.user.brand', ''))
          // localStorage.setItem('hotel', get(res, 'data.user.hotel', ''))
          tracking.ssoLogin(get(res, "data.user.hotel", ""));
          authUser(
            get(res, "data.user.role", null),
            get(res, "data.user.name", ""),
            get(res, "data.user.email", "")
          );
          props.history.push("/");
        }
      });
    }
    // checkToken()
    const submitOnEnter = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleLogin();
      }
    };

    const form = document.getElementById("signin");
    form.addEventListener("keypress", submitOnEnter);
    return () => {
      form.removeEventListener("keypress", submitOnEnter);
    };
  }, []);

  useEffect(() => {
    if (
      userAuthed &&
      get(props, "history.location.pathname", "").indexOf("reset") === -1
    ) {
      props.history.push("/");
    }
  }, [userAuthed]);

  return (
    <>
      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        showCloseButton={true}
        width={420}
      >
        <h2 className="h2 title text-center">{modalContent}</h2>
        <div className="text-center">
          <Button theme="primary" onClick={toggleModal}>
            Close
          </Button>
        </div>
      </Modal>
      <div className="signin-wrap background-muted">
        <div
          className="signin-form"
          style={{
            backgroundImage: "url(/assets/images/login-bg.png)",
          }}
        >
          <img
            className="signin-logo-top"
            src="/assets/images/logo-hde.svg"
            alt="logo HDE."
            width="150"
            height="150"
          />
          <div>
            {(!recoverMode && isExhibitor) ? (
              <>
                <div className="exhibitor-title-container">
                  <i className="back-to-icon icon-chevron-left-light" onClick={() => setIsExhibitor(false)}></i>
                  <h2 className="signin-title text-center">Exhibitor Login</h2>
                </div>
                <form id="signin">
                  <Input
                    id="email"
                    label="Email address"
                    placeholder="user@address.com"
                    fullwidth
                  />
                  <Input
                    id="password"
                    label="Password"
                    placeholder="*********"
                    type="password"
                    fullwidth
                  />
                  <div className="signin-form__forgot-password">
                    <Link
                      className="link"
                      onClick={() => {
                        setRecoverMode(true);
                        setRecoverStep(0);
                      }}
                    >
                      Forgot password?
                    </Link>
                  </div>
                  {/* <a href={`${process.env.REACT_APP_OAUTH_URL}?\
				client_id=${process.env.REACT_APP_CLIENT_ID}\
				&response_type=code\
				&redirect_uri=${process.send.REACT_APP_REDIRECT_URL}
			`}> */}
                  {/* <div className="default-margin">
          <a
            className="blue-button-link"
            href={`${"http://hyattideas.com"}/o/authorize?\
					client_id=${process.env.REACT_APP_CLIENT_ID}
					&response_type=code\
					&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}
				`}
          >
            log in with ideas
          </a>
          </div> */}
                  <Button onClick={handleLogin} isFullWidth>
                    Login
                  </Button>
                  <div className="text-center signin-form__text">
                    <Link className="link" to="/partner-apply">
                      Don’t have an account? Create one
                    </Link>
                  </div>
                </form>
              </>
            ) : (!recoverMode && !isExhibitor) ? (
              <>
                <h2 className="signin-title text-center">Hyatt Associate</h2>
                <Button
                  isExt
                  href={`${"http://hyattideas.com"}/o/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}`}
                  theme="primary-dark"
                  isFullWidth
                >
                  <span id="signin">Login</span>
                </Button>
                <FullWidthDivider />
                <div className="switch-exhibitor" onClick={() => {setIsExhibitor(true)}}>I am an Exhibitor</div>
              </>
            ) : recoverStep === 0 ? (
              <>
                <h2 className="signin-title">Forgot your password?</h2>
                <h3>
                  Please enter your email address to request a password reset
                </h3>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }
                    return errors;
                  }}
                  onSubmit={() => {}}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form>
                      <Input
                        id="email"
                        label="Email address"
                        placeholder="user@address.com"
                        fullwidth
                        //
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={errors.email && touched.email}
                        errorMessage={errors.email}
                      />
                      <Button
                        /*type="submit"*/ theme="primary"
                        isFullWidth
                        onClick={() => {
                          onSendMessage.mutate(values);
                        }}
                        isDisabled={
                          onSendMessage.isLoading ||
                          errors.email ||
                          !touched.email
                        }
                      >
                        {onSendMessage.isLoading
                          ? "Sending..."
                          : "Reset password"}
                      </Button>
                      <div className="text-center">
                        <Link
                          className="link"
                          onClick={() => {
                            setRecoverMode(false);
                            setRecoverStep(0);
                          }} /*to="/auth/reset"*/
                        >
                          Back to login
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <h2 className="signin-title">Check your mails</h2>
                <h3>
                  A link to reset your password has been sent to your email
                  address.
                </h3>
                <div className="text-center">
                  <Link
                    className="link"
                    onClick={() => {
                      setRecoverMode(false);
                      setRecoverStep(0);
                    }} /*to="/auth/reset"*/
                  >
                    Back to login
                  </Link>
                </div>
              </>
            )}
            {/* Forgot your password? */}
            {/* Check your mails */}

            {/* Check your mails */}
            {/* Reset your password */}
            {/* <h2 className="signin-title">Reset your password</h2>
          <h3>Please choose a new password</h3>
          <form id="resets-password">
            <Input
              id="new-password"
              label="New password"
              placeholder="*********"
              type="password"
              fullwidth
            />
            <Input
              id="confirm-password"
              label="Confirm password"
              placeholder="*********"
              type="password"
              fullwidth
            />
            <Button type="submit" theme="primary" isFullWidth>
              Confirm new password
            </Button>

            <div className="text-center text-error">Passwords don’t match</div>
            <div className="text-center">
              <Link className="link" to="/auth/reset">
                Back to login
              </Link>
            </div>
          </form> */}
            {/* Reset your password */}
            {/* Your password has been reset */}
            {/* <h2 className="signin-title">Your password has been reset</h2>

          <Button theme="primary" isLink to="/auth">
            Login
          </Button> */}

            {/* Your password has been reset */}
          </div>
          <img
            className="signin-logo-bottom"
            src="/assets/images/logo-hyatt.png"
            srcSet="/assets/images/logo-hyatt.png, /assets/images/logo-hyatt@2x.png 2x"
            alt="hyatt logo."
            width="141"
            height="34"
          />
        </div>
        <div
          className="signin-image"
          style={{
            backgroundImage: "url(/assets/images/enter-the-exhibition.jpg)",
          }}
        >
          <h2 className="signin-image-title title">Enter the exhibition</h2>
        </div>
      </div>
    </>
  );
});

export default withRouter(SignIn);
