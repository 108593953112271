import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, A11y, Autoplay } from "swiper";
import MediaDisplay from "../MediaDisplay";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "./offerSlider.scss";

SwiperCore.use([Autoplay, Navigation, A11y]);

const OfferSlider = ({
  handleSlideClick,
  setSelectedIndex,
  slidesData,
  link,
  slidesNumberDesktop,
  SlideComponent,
  video,
  canView,
  autoplay,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();

  if(!slidesData?.length){
    return null
  }

  return (
    <>
      <button className="offers-swiper-button-prev" />
      <button className="offers-swiper-button-next" />
      <Swiper
        className={cn("offer-slider", {
          [`offer-slider-video`]: !!video,
          [`offer-slider-centered`]: slidesData.length < 4,
        })}
        modules={[Navigation, A11y, Autoplay]}
        centeredSlides={false}
        loop={autoplay}
        navigation={{
          nextEl: ".offers-swiper-button-next",
          prevEl: ".offers-swiper-button-prev",
        }}
        autoplay={autoplay ? {
          delay: 3000,
          disableOnInteraction: false
        }: false}
        breakpoints={{
          576: {
            slidesPerView: 1,
            centeredSlides: false,
          },
          768: {
            slidesPerView: 2,
            centeredSlides: false
          },
          1200: {
            slidesPerView: slidesNumberDesktop,
          },
          1440: {
            slidesPerView: slidesNumberDesktop,
          },
        }}
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <SlideComponent
              {...slide}
              canView={canView}
              handleSlideClick={(info) => {
                console.log(slide)
                setSelected({
                  type: 'product',
                  id: slide.exhibitor,
                  name: slide.exhibitorName,
                  ...info,
                })
                setShowModal(true);
              }}
              hasThumbnail={slide.thumbnail ? true : false}
            />
          </SwiperSlide>
        ))}
        {link ? (
          <SwiperSlide key="discover-link">
            <Link to="/search" className="discover-link">
              <span>Discover all our preferred brands</span>
              <div className="icon-big-arrow" />
            </Link>
          </SwiperSlide>
        ) : null}
      </Swiper>
      <MediaDisplay
        show={showModal}
        item={selected}
        list={slidesData}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default OfferSlider;
