import React, { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { withRouter } from "react-router-dom";
import get from "lodash.get";
import Button from "../../ui/button";
import {  Col, Row } from "react-bootstrap";
import { baseURL, getPartnersBySearch } from "../../../network/API";
import MediaWrapper from "../../layout/mediaWrapper";
import { ROLES } from "../../../constants/roles";
import { useAuthContext } from "../../../store/auth";


export const PartnerCard = withRouter((props) => {
  const [isOwnBooth, setIsOwnBooth] = useState(false);

  const getBrandLogo = (img) => {
    if (img) {
      return `${baseURL}/storage/partner_logo/${img}`;
    }
    return "/assets/images/bg-default-partner.png";
  };


  return (
    <div
      onClick={(e) => {
        if (
          (props.canView || isOwnBooth)
        ) {
          props.history.push({
            pathname: `/partner/${props.id}`,
            previous: props.previous
              ? props.previous
              : { to: "/partners" + props.searchQuery, name: "Partner"},
          });
        }
      }}
      className={`partner-card${
        props.canView || isOwnBooth ? " partner-clickable" : ""
      }`}
      style={{ textDecoration: "none", color: "black" }}
    >
      

      <div className="partner__brand-container">
        <div className="brand-container__logo">
          <MediaWrapper
            square
            contain
            src={getBrandLogo(get(props, "logo.uri", ""))}
            onLoad={props.onLoad}
          />
        </div>
        <div className="brand-contianer__info">
          <div className="brand-contianer__info__label">
            <span>{ props.name }</span>
          </div>
          <div className="brand-count">
            <span>{ props.exhibitorCount } </span>
            <span>Brands</span>
          </div>
        </div>
      </div> 
    </div>
  );
});

const PartnerList = React.memo(
  ({
    canView,
    redirectTo,
    onLoad,
    noButton,
    previous,
    text,
    q,
    ...props
  }) => {

    const { role } = useAuthContext();

    const partners = useInfiniteQuery(
      ["getPartnersBySearch", { q }],
      getPartnersBySearch,
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) =>
          lastPage.length < 10 ? false : pages.length + 1,
        onSettled: () => {
          role === ROLES.exhibitor ? props.history.push("/notfound") : console.log('OnLoad partners')
        },
      }
    );

    const getSearchQuery = `?${
      q ? `&q=${q}` : ""
    }`;

    // Auto load next page
    useEffect(() => {
      const handleScroll = () => {
        // Get visiable window height
        var domHight = window.screen.height || window.innerHeight ||
          document.documentElement.clientHeight;
        // console.log('domHight', domHight);
        // Get dom scroll position
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // Get footer
        var footer;
        // Get footer height
        var scrollHeight;
        // Get footer offsetTop
        var offsetTop;
        // Get footer offsetTop - window height 
        var top;
        // Get footer offsetBottom + footer height
        var bottom;
    
        footer = document.getElementById(`footer`);
        if (footer) {
          scrollHeight = footer.scrollHeight;
          offsetTop = footer.offsetTop;
          top = offsetTop - domHight > 0 ? offsetTop - domHight : 0;
          bottom = scrollHeight + offsetTop;
          if (scrollTop >= top && scrollTop <= bottom) {
            if (partners.hasNextPage && !partners.isFetching && !partners.isFetchingNextPage) {
              // console.log('fetch next page')
              partners.fetchNextPage();
            }
            // console.log('footer appears !!!!!');
          } else {
            // console.log('footer not appears');
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, [partners]);


    return (
      <div>
        <div className="product-list">
          {partners.data?.pages?.flat()?.length === 0 && <p>{text}</p>}
          {partners.data?.pages?.flat().map((e, i) => (
            <PartnerCard
              key={e._id}
              name={e.name}
              searchQuery={getSearchQuery}
              exhibitorCount={e.exhibitorCount}
              previous={previous}
              canView={canView}
              id={e.id || e._id}
              onLoad={() => {}}
              logo={e.logo}
            />
          ))}
        </div>
        {!noButton && (
          <Row className="justify-content-center">
            <Col xs="auto">
              {partners.hasNextPage && !redirectTo ? (
                <Button
                  isDisabled={
                    partners.isFetching || partners.isFetchingNextPage
                  }
                  onClick={() => partners.fetchNextPage()}
                  size="min"
                >
                  {partners.isFetchingNextPage ? "Loading..." : "Load more"}
                </Button>
              ) : null}
              {redirectTo && (
                <Button onClick={() => redirectTo()} size="min">
                  {"Discover more"}
                </Button>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
);

export default withRouter(PartnerList);
