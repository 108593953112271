import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-query";
import { getCategories } from "../../../network/API";
import { useAuthContext } from "../../../store/auth";
import Loader from "../../layout/loader";
import queryString from "query-string";
import SearchHeader from "./searchHeader";
import tracking from "../../../utils/tracking";
import { ROLES } from "../../../constants/roles";
import { Container, Row, Col } from "react-bootstrap";
import get from "lodash.get";
import PartnerList from "./partnerList";
import trim from "lodash.trim";

const PartnerListPage = (props) => {
  const query = queryString.parse(props.history.location.search);
  const search = get(query, "q", "");
  const { role } = useAuthContext();

  const [searchValue, setSearchValue] = useState(search);
  const [queryQ, setQueryQ] = useState(search); // for real search

  useEffect(() => {
    const query = queryString.parse(props.history.location.search);
    if (props.history.location.needUpdate) {
      if (query.q)
        setSearchValue(query.q);
    }
  }, [props.history.location.search]);

  useEffect(() => {
    const query = queryString.parse(props.history.location.search);
    if (query.q) setSearchValue(query.q);
    tracking.pageView("/partners");
  }, []);

  const setQueryParams = (
    search
  ) => {
    props.history.push({
      pathname: "/partners",
      search: `?${search ? "&q=" + search : ""}`,
      noResetScroll: true,
    });
  };

  const categories = useQuery(["getCategories"], getCategories, {
    refetchOnWindowFocus: false,
  });

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = (v) => {
    setQueryParams(v);
    setQueryQ(v);
    setSearchValue(v);
  };

  const handelClearSearch = (event) => {
    setSearchValue("");
    setQueryQ("");
  };


  return (
    <>
      <Loader show={categories.isLoading} />
      <SearchHeader
        inputValue={searchValue}
        onInputChange={handleInputChange}
        onEnterPressed={(v) => {
          v = trim(v);
          handleSearch(v);
        }}
        onClearSearch={handelClearSearch}
        image="/assets/images/search-header.jpg"
        name="Our partners"
      />
      <section className="section background-grey">
        <Container>
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
                <>
                  <PartnerList
                    q={queryQ}
                    canView={
                      role !== ROLES.exhibitor
                    }
                    text="Sorry, no results found..."
                    noButton={true}
                  />
                </>
              
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default withRouter(PartnerListPage);
