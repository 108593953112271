import React from 'react'
import { FullWidthDivider } from '../../layout/divider'

const EditControls = (props) => {
	return (
		<>
			<div className="edit-booth-controls centered-section">
				<div className="edit-booth-controls__left" onClick={props.onCancel}>
					{`< cancel`}
				</div>
				<div className="edit-booth-controls__title">
					{props.title || 'edit your booth'}
				</div>
				{props.onPreview &&
					<div id="onprev" className="edit-booth-controls__right" onClick={props.onPreview}>
						{props.onPreview ? `preview >` : ''}
					</div>
				}
				{props.onPublish &&
					<div className="edit-booth-controls__right" onClick={props.onPublish}>
						{props.onPublish ? `publish >` : ''}
					</div>
				}
			</div>
			<FullWidthDivider />
		</>
	)
}

export default EditControls