import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, withRouter } from "react-router-dom";
import cn from "classnames";
import Button from "../ui/button";
// import Menu from "../ui/menu/index";
import DropdownMenu from "../ui/dropdownMenu";
import { Dropdown } from "react-bootstrap";
import Notifications from "../ui/notifications";
import { getNthItemFromPathname } from "../../utils/index";
import { ROLES } from "../../constants/roles";
import { useAuthContext } from "../../store/auth";
import { useQuery } from "react-query";
import { getCategories } from "../../network/API";

const HeaderIcon = (props) => {
  const { userAuthed } = useAuthContext();

  return (
    <div>
      {props.link && !props.fullPath && (
        <Link
          to={props.link}
          style={{ textDecoration: "none", color: "black" }}
        >
          <span
            className={
              "black-bg" + (props.stickyControl ? " sticky-control" : "")
            }
          >
            <img
              className={`
              header-menu__icon
              ${props.mobileOnly ? "mobile-only" : ""}
              ${props.mobileHidden ? "mobile-hidden" : ""}
              ${props.hide ? "invisible" : ""}
              ${props.hideOnlyMobile ? "hideOnlyMobile" : ""}
              ${!userAuthed ? "invisible" : ""}
            `}
              src={props.image}
              alt={props.alt}
              title={props.tooltipText}
            />
          </span>
        </Link>
      )}
      {props.link && props.fullPath && (
        <a href={props.link} style={{ textDecoration: "none", color: "black" }}>
          <span
            className={
              "black-bg" + (props.stickyControl ? " sticky-control" : "")
            }
          >
            <img
              className={`
              header-menu__icon
              ${props.mobileOnly ? "mobile-only" : ""}
              ${props.mobileHidden ? "mobile-hidden" : ""}
              ${props.hide ? "invisible" : ""}
              ${props.hideOnlyMobile ? "hideOnlyMobile" : ""}
              ${!userAuthed ? "invisible" : ""}
            `}
              src={props.image}
              alt={props.alt}
              title={props.tooltipText}
            />
          </span>
        </a>
      )}
      {!props.link && props.action && (
        <button
          className={`
          header-menu__icon icon-menu
          ${props.mobileOnly ? "mobile-only" : ""}
          ${props.stickyControl ? "sticky-control" : ""}
          ${props.mobileHidden ? "mobile-hidden" : ""}
          ${props.hide ? "invisible" : ""}
          ${!userAuthed ? "invisible" : ""}
        `}
          onClick={props.action}
          title={props.tooltipText}
        />
      )}
    </div>
  );
};

const Header = (props) => {
  // const [searchOpen, setSearchOpen] = useState(false);
  const { userAuthed, role } = useAuthContext();
  const [fixedHeader, setFixedHeader] = useState(false);
  const pathname = props.history.location.pathname;
  const pageName = getNthItemFromPathname(pathname, 1);
  const categories = useQuery(["getCategories"], getCategories, {
    enabled: !!userAuthed,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const handleScroll = () => {
      setFixedHeader(window.pageYOffset > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  let location = useLocation();

  useEffect(() => {
    // trick to hide dropdown menu
    document.body.click();
  }, [location]);

  // const myBoothLink = get(props, 'myBooth.category.slug', '') !== '' ? `/category/${get(props, 'myBooth.category.slug', '')}/${get(props, 'myBooth.id', '')}` : '/mybooth'
  return (
    <>
      {!props.hide && (
        <>
          <div
            className={cn("header", [`header-${props.view}`], {
              [`header-fixed`]: !!fixedHeader,
              [`header-public`]: pathname === "/public",
            })}
          >
            <div className="header-inner">
              <div className="header-menu">
                <div className="header-menu-toggle">
                  <HeaderIcon
                    image="/assets/images/menu.svg"
                    alt="icon-burger-white"
                    action={props.toggleMenu}
                    tooltipText="Menu"
                  />
                  {/* <Menu
                    menuOpen={true}
                    // toggleMenu={toggleMenu}
                    categories={categories}
                    hide={false}
                  /> */}
                  {/* {props.role && props.role !== "hyatt" && pageName && (
                    <HeaderIcon
                      image="/assets/images/icon-home.png"
                      alt="icon-burger-white"
                      link="/"
                      fullPath
                      hide=""
                      tooltipText="Home"
                    />
                  )} */}
                  {/* {props.role && props.role === "hyatt" && !pageName && (
                    <HeaderIcon
                      image="/assets/images/icon-ideas.png"
                      alt="icon-burger-white"
                      link="https://hyattideas.com"
                      fullPath
                      hide=""
                      tooltipText="Go back to IDEAS"
                    />
                  )}
                  {props.role && props.role === "hyatt" && pageName && (
                    <HeaderIcon
                      image="/assets/images/icon-home.png"
                      alt="icon-burger-white"
                      link="/"
                      fullPath
                      hide=""
                      tooltipText="Home"
                    />
                  )} */}
                </div>
              </div>

              <div className="section-hero-header-logo">
                <Link to="/">
                  <img
                    src="/assets/images/logo-hde-white.svg"
                    alt="logo HDE."
                    width="50"
                    height="50"
                  />
                </Link>
              </div>

              <Link
                to="/"
                className="section-hero-header-logo section-hero-header-logo-inverse"
              >
                <img
                  src="/assets/images/logo-hde.svg"
                  alt="logo HDE."
                  width="50"
                  height="50"
                />
              </Link>
              {pageName !== "public" &&
              pageName !== "terms" &&
              props.role !== ROLES.exhibitor ? (
                <div className="header-categories-dropdown">
                  <Dropdown align="center">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-categories"
                      align="end"
                    >
                      <span>Categories</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DropdownMenu categories={categories.data} />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : null}
              {pageName !== "public" &&
                props.role &&
                (props.role === ROLES.hyatt ||
                  props.role === ROLES.admin ||
                  props.role === ROLES.superAdmin) && (
                  <>
                    <NavLink to="/search" className="header-menu-link" activeClassName="selected">
                      Products
                    </NavLink>
                    <NavLink to="/favorites" className="header-menu-link" activeClassName="selected">
                      Following
                    </NavLink>
                    <NavLink to="/partners" className="header-menu-link" activeClassName="selected">
                      Partners
                    </NavLink>
                  </>
                )}
              {pageName !== "public" &&
                props.role &&
                props.role === ROLES.exhibitor && (
                  <>
                    <NavLink to="/search" className="header-menu-link" activeClassName="selected">
                      Products
                    </NavLink>
                    {/* <NavLink
                      to="/myaccount/brands"
                      className="header-menu-link"
                      activeClassName="selected"
                    >
                      My brands
                    </NavLink> */}
                    {/* <NavLink
                      to="/myaccount/stats"
                      className="header-menu-link"
                      activeClassName="selected"
                    >
                      Statistics
                    </NavLink> */}
                    <NavLink
                      to="/hotels"
                      className="header-menu-link"
                      activeClassName="selected"
                    >
                      Hotel list
                    </NavLink>
                  </>
                )}
              {pageName === "public" && (
                <Button
                  className="section-hero-sign-in"
                  isLink
                  to="/auth"
                  theme={fixedHeader ? "primary" : "secondary"}
                  text="Sign In"
                />
              )}
              <div className="header-inner-right">
                {props.role &&
                  props.role === ROLES.exhibitor &&
                  pathname !== "/public" && (
                    <NavLink
                      to="/myaccount/brands"
                      className={cn(
                        "header-menu-link header-menu-link-account",
                        {
                          selected:
                            pathname === "/myaccount/stats" ||
                            pathname === "/myaccount/settings",
                        }
                      )}
                      activeClassName="selected"
                    >
                      Account
                    </NavLink>
                  )}
                {pageName !== "public" && userAuthed && (
                  // <Dropdown align="center" className="dropdown-icon">
                  //   <Dropdown.Toggle
                  //     variant="success"
                  //     id="dropdown-basic"
                  //     className="section-hero-notifications"
                  //   >
                  //     <div className="icon-bell" />
                  //     {/* <div className="icon-bell-notification" /> */}
                  //   </Dropdown.Toggle>
                  //   <Dropdown.Menu className="dropdown-arrow">
                  <Notifications />
                  //   </Dropdown.Menu>
                  // </Dropdown>
                )}
                <div className="header-menu">
                  {/* {searchOpen && (
                  <SearchBar setSearchOpen={setSearchOpen} focus={searchOpen} />
                )} */}
                  {/* <HeaderIcon
                  image="/assets/images/icon-search.png"
                  alt="icon-search"
                  action={() => setSearchOpen(true)}
                  tooltipText="Search"
                /> */}
                  {/* {props.role && props.role === "exhibitor" && (
                  <HeaderIcon
                    image="/assets/images/icon-profile.png"
                    alt="icon-profile"
                    link="/myaccount"
                    tooltipText="My account"
                  />
                )} */}
                  {/* {props.role && props.role === "exhibitor" && (
                  <HeaderIcon
                    image="/assets/images/icon-mypage.png"
                    alt="icon-mypage"
                    link={`/exhibit/${get(
                      props,
                      "myBoothList[0].exhibitor",
                      ""
                    )}/all`}
                    mobileHidden
                    tooltipText="My booths list"
                  />
                )} */}
                  {/*{props.role && props.role === 'exhibitor' && pageName !== 'exhibit' &&*/}
                  {/*	<HeaderIcon image="/assets/images/icon-mypage.png" alt="icon-mypage" link={`/exhibit/${get(props, 'myBoothList[0].id', '')}`} mobileHidden tooltipText="My booth"/>*/}
                  {/*}*/}
                  {/*{props.role && props.role === 'exhibitor' && get(props.myBoothList[0], 'id', '') === id && pageName === 'exhibit' && pageMode !== 'edit' &&*/}
                  {/*	<HeaderIcon image="/assets/images/icon-mypage-edit.png" alt="icon-mypage" link={`/exhibit/${get(props, 'myBoothList[0].id', '')}/edit`} mobileHidden tooltipText="Edit booth"/>*/}
                  {/*}*/}
                  {/*{props.role && props.role === 'exhibitor' && pageName === 'exhibit' && pageMode === 'edit' &&*/}
                  {/*	<HeaderIcon image="/assets/images/icon-view.png" alt="icon-view" action={() => document.getElementById('onprev').click()} mobileHidden tooltipText="Preview booth"/>*/}
                  {/*}*/}
                  {pageName !== "public" && userAuthed && (
                    <button
                      className="header-logout icon-logout"
                      onClick={() => props.logout()}
                      title="Sign out"
                    />
                  )}
                </div>
              </div>
            </div>
            {/* {props.role && props.role === ROLES.exhibitor && (
              <HeaderIcon
                image="/assets/images/icon-mypage.png"
                alt="icon-mypage"
                link={`/exhibit/${get(
                  props,
                  "myBoothList[0].exhibitor",
                  ""
                )}/all`}
                mobileOnly
                stickyControl
              />
            )} */}
            {/*{props.role && props.role === 'exhibitor' && get(props.myBoothList[0], 'id', '') === id && pageName === 'exhibit' && pageMode !== 'edit' &&*/}
            {/*	<HeaderIcon image="/assets/images/icon-mypage-edit.png" alt="icon-mypage" link={`/exhibit/${get(props, 'myBoothList[0].id', '')}/edit`} mobileOnly stickyControl/>*/}
            {/*}*/}
            {/*{props.role && props.role === 'exhibitor' && pageName === 'exhibit' && pageMode === 'edit' &&*/}
            {/*	<HeaderIcon image="/assets/images/icon-view.png" alt="icon-mypage" action={() => document.getElementById('onprev').click()} mobileOnly stickyControl />*/}
            {/*}*/}
          </div>
        </>
      )}
    </>
  );
};

export default withRouter(Header);
