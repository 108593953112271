import { INSTANCE } from "./API";

export const uploadBackground = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/files/background`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const uploadCatalog = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/files/catalog`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const uploadContact = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/files/contact`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const uploadHistory = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/files/history_media`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const uploadLifestyle = (data) =>
  INSTANCE({
    method: "post",
    url: `/files/lifestyle`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  })

export const uploadLogo = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/files/logo`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const uploadProduct = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/files/products`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const uploadThumbnails = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/files/thumbnails`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const uploadVideos = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/files/videos`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

export const uploadWechatQrCode = (data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: "post",
      url: `/files/wechat_qr_code`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then(resolve)
      .catch(reject);
  });

//     // Homepage related
//     {
//         routeName: 'homepage',
//         folderName: 'homepage',
//         allowedExtentions: [...images, ...videos]
//     },
//     {
//         routeName: 'annoucement_banner',
//         folderName: 'annoucement_banner',
//         allowedExtentions: [...images]
//     },

//     // Subcategory related
//     {
//         routeName: 'subcategory-background',
//         folderName: 'subcategory-background',
//         allowedExtentions: [...images]
//     },
//     {
//         routeName: 'subcategory-icon',
//         folderName: 'subcategory-icon',
//         allowedExtentions: [...images]
//     }
